import {
  CMSCollection,
  CMSImageTitleAndText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { TextRender, ImageRender } from "../../utils/cms/renderableResources";
import { storybookImageResource } from "stories/helpers/storybookImageResource";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";

const CardWithImageTitleAndText2 = ({
  imageTitleAndText,
}: InjectableComponentProps<CardWithImageTitleAndText2Props>) => {
  return (
    <div
      data-component="CardWithImageTitleAndText2"
      className="flex flex-wrap lg:flex-nowrap justify-center gap-10"
    >
      {imageTitleAndText.resources.map((entry) => {
        return (
          <div
            key={JSON.stringify(entry)}
            className="w-1/2 md:w-1/3 lg:w-1/6 mx-auto md:mx-0"
          >
            <ImageRender
              alt={entry.image?.name || ""}
              resource={entry.image}
              className="w-32 h-32 rounded-full object-cover m-auto"
            />
            <div className="flex flex-col text-center">
              <p className="font-semibold mt-4 mb-2">
                <TextRender resource={entry.title} />
              </p>
              <p className="">
                <TextRender resource={entry.text} />
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

type CardWithImageTitleAndText2Props = {
  imageTitleAndText: CMSCollection<CMSImageTitleAndText>;
};

export const CardWithImageTitleAndText2StorybookProps: CMSComponentExampleProps<CardWithImageTitleAndText2Props> =
  {
    imageTitleAndText: {
      defaultTags: [{ type: "image_title_and_text" }],
      type: "imageTitleAndText",
      retrieveAll: true,
      values: [
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "30-day guarantee",
          text: `Upon enrollment, you'll be able to try the entire method for 30 days and 
        see if it's right for you. If it's not, you can return it and get a 100% refund.`,
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "31-day guarantee",
          text: `Upon enrollment, you'll be able to try the entire method for 30 days and 
        see if it's right for you. If it's not, you can return it and get a 100% refund.`,
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "32-day guarantee",
          text: `Upon enrollment, you'll be able to try the entire method for 30 days and 
        see if it's right for you. If it's not, you can return it and get a 100% refund.`,
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "34-day guarantee",
          text: `Upon enrollment, you'll be able to try the entire method for 30 days and 
        see if it's right for you. If it's not, you can return it and get a 100% refund.`,
        },
      ],
    },
  };

export default CardWithImageTitleAndText2;
