import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { CMSRichText, InjectableComponentProps } from "../cms/types/cmsTypes";
import { RichTextRender } from "../../utils/cms/renderableResources";

const Title1 = ({ title }: InjectableComponentProps<Title1Props>) => {
  return (
    <RichTextRender
      className="text-center font-bold text-2xl md:text-3xl xl:text-4xl my-12"
      resource={title}
      element="h1"
    />
  );
};

type Title1Props = {
  title: CMSRichText;
};

export const Title1StorybookProps: CMSComponentExampleProps<Title1Props> = {
  title: {
    type: "richtext",
    value: "This is an example title",
  },
};

export default Title1;
