import { placeholderImageUrlWithText } from "stories/helpers";
import { ImageRender, TextRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSImageTitleAndText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const ImageWithTitleAndText3 = ({
  images,
}: InjectableComponentProps<ImageWithTitleAndText3Props>) => {
  return (
    <div className="flex justify-evenly pt-8 pb-6 bg-secondary  px-4">
      <div className="flex justify-around flex-wrap flex-col md:flex-row gap-16 md:flex-nowrap">
        {images?.resources.map((image, index) => (
          <div className="flex flex-col items-center" key={index}>
            <ImageRender
              className="flex justify-center items-center text-secondary-darker opacity-80 hover:opacity-100 w-[120px] h-[100px] object-contain"
              key={index}
              resource={image.image}
            />
            {image.title && (
              <TextRender
                className="mt-6 text-bg-secondary text-xl"
                resource={image.title}
              />
            )}
            <></>
            {image.text && (
              <TextRender
                className="text-bg-secondary text-sm text-gray-700"
                resource={image.text}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

type ImageWithTitleAndText3Props = {
  images: CMSCollection<CMSImageTitleAndText>;
};

export const ImageWithTitleAndText3StorybookProps: CMSComponentExampleProps<ImageWithTitleAndText3Props> =
  {
    images: {
      defaultTags: [{ type: "image_title_and_text" }],
      type: "imageTitleAndText",
      retrieveAll: true,
      values: [
        {
          text: "This is a caption",
          title: "This is a title",
          image: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
        {
          text: "This is a caption",
          title: "This is a title",
          image: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
        {
          text: "This is a caption",
          title: "This is a title",
          image: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
        {
          text: "This is a caption",
          title: "This is a title",
          image: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
      ],
    },
  };

export default ImageWithTitleAndText3;
