import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSVideoResource,
  CMSRichText,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  RichTextRender,
  TextRender,
  VideoRender,
} from "../../utils/cms/renderableResources";

const Video2 = ({
  video,
  brandAbbreviation,
  text,
  header,
}: InjectableComponentProps<Video2Props>) => {
  return (
    <div className="relative my-8">
      <TextRender
        className="md:absolute md:right-0 lg:right-32 md:top-2 top-16 p-2 md:p-6 bg-tertiary font-bold text-xl md:text-3xl shadow-lg"
        resource={header}
      />
      <div className="bg-primary px-6 pb-6 pt-8 md:pt-16 lg:mx-36 justify-center text-xl">
        <RichTextRender className="pb-10 text-white" resource={text} />
        <div className={`relative flex w-full m-auto`}>
          <VideoRender resource={video} brandAbbreviation={brandAbbreviation} />
        </div>
      </div>
    </div>
  );
};

type Video2Props = {
  video: CMSVideoResource;
  brandAbbreviation: string;
  header: CMSText;
  text: CMSRichText;
};

export const Video2StorybookProps: CMSComponentExampleProps<Video2Props> = {
  video: {
    defaultTags: [{ type: "video" }],
    type: "video_resource",
    value: {
      id: "59a5e568-cb7d-489e-8bdc-8ebdb83ad041",
      s3_path:
        "/nma/resources/59a5e568-cb7d-489e-8bdc-8ebdb83ad041/filename-to-remove",
      metadata: {
        duration: 1112,
        filename: "",
        subtitle: "",
        original_resolution: "1080p",
        available_resolutions: ["1080p", "720p", "540p"],
      },
    },
  },
  brandAbbreviation: "nma",
  header: {
    type: "text",
    value: "What you'll be painting...",
  },
  text: {
    type: "richtext",
    value:
      "This class is perfect for anyone. <i>Take a look at what you'll be painting below</i>",
  },
};

export default Video2;
