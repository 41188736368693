import { placeholderImageUrlWithText } from "stories/helpers";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSImageResource,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { ImageRender, TextRender } from "../../utils/cms/renderableResources";

const ImageWithTitleAndText1 = ({
  title,
  image,
  text,
}: InjectableComponentProps<ImageWithTitleAndText1Props>) => {
  return (
    <div
      data-component="ImageWithTitleAndText1"
      className="flex flex-row px-20 gap-x-8 justify-center p-12"
    >
      <div className="flex flex-col gap-y-8 my-auto w-4/5 md:w-1/2 xl:w-1/3 text-center md:text-left">
        <h2 className="font-bold text-2xl text-primary">
          <TextRender resource={title} />
        </h2>
        <ImageRender
          resource={image}
          className="inline md:hidden w-4/5 mx-auto"
        />
        <p className="mt-0 md:-mt-6">
          <TextRender resource={text} />
        </p>
      </div>
      <div className="flex flex-col hidden md:block w-1/3 xl:w-1/4 my-auto">
        <ImageRender resource={image} />
      </div>
    </div>
  );
};

type ImageWithTitleAndText1Props = {
  title: CMSText;
  text: CMSText;
  image: CMSImageResource;
};

export const ImageWithTitleAndText1StorybookProps: CMSComponentExampleProps<ImageWithTitleAndText1Props> =
  {
    title: {
      defaultTags: [{ type: "header_title" }],
      type: "text",
      value: "This is an example title",
    },
    text: {
      defaultTags: [{ type: "header_subtitle" }],
      type: "text",
      value: "This is some exmaple text.",
    },
    image: {
      defaultTags: [{ type: "header_image" }],
      type: "image_resource",
      value: placeholderImageUrlWithText(400, 600, "example image"),
    },
  };

export default ImageWithTitleAndText1;
