import Requires from "../../internal_components/Requires";
import OnlyOnClient from "../OnlyOnClient/OnlyOnClient";
import CountdownTimer from "../SubComponents/CountdownTimer";

const WaitingRoom = ({
  startsAt,
  waitingRoomImage,
}: {
  startsAt?: Date;
  waitingRoomImage: string;
}) => {
  return (
    <div className="relative">
      <img src={waitingRoomImage} className="w-full" alt="waiting room image" />
      <div className="bg-gradient-to-r from-primary to-transparent z-10 h-full w-full absolute top-0"></div>

      <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center text-white z-10">
        <div className="text-white text-5xl ">Starting soon...</div>
        <OnlyOnClient>
          <Requires value={startsAt}>
            <div className="mt-4">
              {startsAt && (
                <CountdownTimer toDate={[startsAt]} styleType="condensed" />
              )}
            </div>
          </Requires>
        </OnlyOnClient>
      </div>
    </div>
  );
};

export default WaitingRoom;
