import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";

const Spacer3 = () => {
  return <div className="h-12 sm:h-20"></div>;
};

type Spacer3Props = {};

export const Spacer3StorybookProps: CMSComponentExampleProps<Spacer3Props> = {};

export default Spacer3;
