import React, { useEffect, useState } from "react";
import Link from "next/link";
import trackEvent from "./trackEvent";

const TrunkLink = ({
  brandAbbreviation,
  children,
  href = "",
}: {
  brandAbbreviation: string;
  children: React.ReactNode;
  href: string;
}) => {
  let normalizedHref = href;

  if (href[0] !== "/") {
    normalizedHref = `/${href}`;
  }

  const [goToUrl, setGoToUrl] = useState(normalizedHref);

  useEffect(() => {
    const windowIsDefined = typeof window !== "undefined";
    const isLocal =
      windowIsDefined &&
      ["lvh.me", "localhost"].includes(window.location.host.split(":")[0]);

    if (isLocal) {
      setGoToUrl(`/marketing/${brandAbbreviation}${normalizedHref}`);
    }
  }, [brandAbbreviation, normalizedHref]);

  const trackLinkClick = (): void => {
    trackEvent(
      {
        action: "click",
        meta: {
          destination: goToUrl,
          type: "link",
        },
      },
      "beacon"
    );
  };

  if (href.startsWith("http")) {
    return (
      <a href={href} onClick={() => trackLinkClick()}>
        {children}{" "}
      </a>
    );
  }

  return (
    <Link href={goToUrl} onClick={() => trackLinkClick()}>
      {children}
    </Link>
  );
};

export default TrunkLink;
