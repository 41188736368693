import { useClickHandler } from "../cms/PageContext";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import CountdownTimer from "../SubComponents/CountdownTimer";
import CMSButton from "../SubComponents/CMSButton";
import {
  CMSSchedule,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  getRawSchedule,
  getRawText,
  TextRender,
} from "../../utils/cms/renderableResources";
import calculateSchedule, {
  ScheduleType,
} from "../../utils/schedule/calculateSchedule";
import OnlyOnClient from "../OnlyOnClient/OnlyOnClient";
import { useState } from "react";

// count down to a recurring date and time
const CountdownTimer3 = ({
  countdownLabel,
  countdownLabelOnceCompleted,
  countdownToRecurringDateTime,
  ctaText,
  component,
}: InjectableComponentProps<CountdownTimer3Props>) => {
  const clickHandler = useClickHandler(component, "ctaText");

  const [formState] = useState({
    timezone: "EST",
  });

  return (
    <div className="bg-tertiary flex flex-col gap-6 md:flex-row justify-center bg-grey-800 p-8 text-3xl text-grey">
      <p className="flex justify-center my-auto text-black text-2xl md:text-3xl md:w-1/3">
        <TextRender resource={countdownLabel} />
      </p>
      <OnlyOnClient>
        <CountdownTimer
          countdownLabelOnceCompleted={getRawText(countdownLabelOnceCompleted)}
          toDate={calculateSchedule(
            new Date(),
            getRawSchedule(countdownToRecurringDateTime),
            formState.timezone
          )}
        />
      </OnlyOnClient>
      <CMSButton
        buttonClass="min-w-fit"
        buttonText={getRawText(ctaText)}
        onClick={clickHandler}
        tracking={{
          expectedResult: "countdown cta button clicked",
          innerText: getRawText(ctaText),
          resourceId: ctaText.id,
          type: "button",
        }}
      />
    </div>
  );
};

type CountdownTimer3Props = {
  countdownLabel: CMSText;
  countdownLabelOnceCompleted: CMSText;
  countdownToRecurringDateTime: CMSSchedule;
  ctaText: CMSText;
};

export const CountdownTimer3StorybookProps: CMSComponentExampleProps<CountdownTimer3Props> =
  {
    countdownLabel: {
      type: "text",
      value: "Countdown to the thing!",
    },
    countdownLabelOnceCompleted: {
      defaultTags: [{ type: "countdown_label" }],
      type: "text",
      value: "Webinar is live!",
    },
    countdownToRecurringDateTime: {
      defaultTags: [{ type: "countdown_date" }],
      type: "schedule",
      value: {
        recurringSchedule: {
          weekly: {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [
              { start: "15:00", status: "countdown end" },
              { start: "15:01", status: "countdown start" },
            ],
            saturday: [],
            sunday: [],
          },
        },
        options: {
          totalDatesToDisplay: 1,
          inTimezone: "EST",
          mode: "countdown",
        },
      } as ScheduleType,
    },
    ctaText: {
      type: "text",
      value: "Click me!",
    },
    component: {
      required_data: {
        ctaText: {
          metadata: {
            onClick: "consoleLog",
          },
        },
      },
    },
  };

export default CountdownTimer3;
