import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { Parallax, Background } from "react-parallax";
import {
  ImageRender,
  RichTextRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import {
  CMSCollection,
  CMSImageResource,
  CMSRichText,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const CardWithHeaderAndBackgroundImage1 = ({
  backgroundImage,
  bullets,
  header,
  postBullets,
  preBullets,
  subtitle,
}: InjectableComponentProps<CardWithHeaderAndBackgroundImage1Props>) => {
  return (
    <Parallax blur={0} strength={400} className="py-24 px-8">
      <Background className="custom-bg object-cover h-full w-full">
        <ImageRender
          resource={backgroundImage}
          imgClassName="bg-cover w-full h-full z-10"
        />
      </Background>
      <div
        className="relative"
        data-component="CardWithHeaderAndBackgroundImage1"
      >
        <h2 className="flex justify-center text-2xl md:text-6xl font-extralight -mb-1 italic text-white">
          <TextRender resource={header} />
        </h2>
        <div className="flex justify-center flex-col md:w-1/2 bg-primary m-auto p-2 md:p-8 border-double border-8 border-tertiary text-bg-primary">
          <h2 className="text-xl font-bold py-4">
            <TextRender resource={subtitle} />
          </h2>
          <h3 className="text-md py-2">
            <TextRender resource={preBullets} />
          </h3>
          <ul className="list-disc list-inside mx-6 py-2">
            {bullets &&
              bullets.resources.map((bullet) => (
                <li key={JSON.stringify(bullet)}>
                  <RichTextRender resource={bullet} element="span" />
                </li>
              ))}
          </ul>
          <h3 className="text-md py-2">
            <TextRender resource={postBullets} />
          </h3>
        </div>
      </div>
    </Parallax>
  );
};

type CardWithHeaderAndBackgroundImage1Props = {
  backgroundImage: CMSImageResource;
  header: CMSText;
  subtitle: CMSText;
  preBullets: CMSText;
  bullets: CMSCollection<CMSRichText>;
  postBullets: CMSText;
};

export const CardWithHeaderAndBackgroundImage1StorybookProps: CMSComponentExampleProps<CardWithHeaderAndBackgroundImage1Props> =
  {
    backgroundImage: {
      type: "image_resource",
      value: "https://www.fillmurray.com/500/320",
    },
    header: {
      type: "text",
      value: "are you longing for...",
    },
    subtitle: {
      type: "text",
      value: "...the ability to finall play piano and enjoy music?",
    },
    preBullets: {
      type: "text",
      value: "Maybe you've tried piano before, but they:",
    },
    bullets: {
      type: "richtext",
      value: "Negative about hobby",
      retrieveAll: true,
      values: [
        { metadata: { value: "Made you feel stupid" } },
        { metadata: { value: "Made you feel like a failure" } },
        {
          metadata: { value: "Made you feel like you'll never be good enough" },
        },
        {
          metadata: {
            value: "Made you feel like you'll never be able to play",
          },
        },
      ],
    },
    postBullets: {
      type: "text",
      value: "You're not the only one!",
    },
  };

export default CardWithHeaderAndBackgroundImage1;
