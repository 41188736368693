import {
  CMSCollection,
  CMSImageTitleAndText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { TextRender, ImageRender } from "../../utils/cms/renderableResources";
import { storybookImageResource } from "stories/helpers/storybookImageResource";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";

const CardWithImageTitleAndText3 = ({
  imageTitleAndText,
}: InjectableComponentProps<CardWithImageTitleAndText3Props>) => {
  return (
    <div
      data-component="CardWithImageTitleAndText2"
      className="flex flex-wrap justify-center gap-10"
    >
      {imageTitleAndText.resources.map((entry) => {
        return (
          <div
            key={JSON.stringify(entry)}
            className="w-3/4 md:w-1/3 lg:w-1/6 mx-auto md:mx-0 flex flex-col"
          >
            <div className="flex flex-col justify-center items-center">
              <ImageRender
                alt={entry.image?.name || ""}
                resource={entry.image}
                className="h-full w-full"
                imgClassName="w-20 h-20 object-contain"
              />
            </div>
            <div className="bg-secondary p-2 rounded-lg mt-2 text-center h-full flex-1">
              <p className="font-semibold my-2">
                <TextRender resource={entry.title} />
              </p>
              <TextRender resource={entry.text} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

type CardWithImageTitleAndText3Props = {
  imageTitleAndText: CMSCollection<CMSImageTitleAndText>;
};

export const CardWithImageTitleAndText3StorybookProps: CMSComponentExampleProps<CardWithImageTitleAndText3Props> =
  {
    imageTitleAndText: {
      defaultTags: [{ type: "image_title_and_text" }],
      type: "imageTitleAndText",
      retrieveAll: true,
      values: [
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "30-day guarantee",
          text: "Upon enrollment, you'll be able to try the entire method for 30 days and see if it's right for you.",
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "31-day guarantee",
          text: `Upon enrollment, you'll be able to try the entire method for 30 days and see if it's right for you. 
          If it's not, you can return it and get a 100% refund.Upon enrollment, you'll be able to try the entire method 
          for 30 days and see if it's right for you. If it's not, you can return it and get a 100% refund.`,
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "32-day guarantee",
          text: `Upon enrollment, you'll be able to try the entire method for 30 days and see if it's right for you. 
          If it's not, you can return it and get a 100% refund.`,
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "34-day guarantee",
          text: "You can return it and get a 100% refund.",
        },
      ],
    },
  };

export default CardWithImageTitleAndText3;
