import { placeholderImageUrlWithText } from "stories/helpers";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { useClickHandler } from "../cms/PageContext";
import CMSButton from "../SubComponents/CMSButton";
import {
  CMSCollection,
  CMSImageResource,
  CMSRichText,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  ImageRender,
  RichTextRender,
  TextRender,
  getRawText,
} from "../../utils/cms/renderableResources";

const HeaderLeftTextWithPictureAndCTA1 = ({
  backgroundImage,
  bulletListTitle,
  bullets,
  component,
  ctaText,
  description,
  title,
}: InjectableComponentProps<HeaderLeftTextWithPictureAndCTA1Props>) => {
  const clickHandler = useClickHandler(component, "ctaText");

  return (
    <div
      className="flex relative justify-center overflow-hidden"
      data-component="HeaderLeftTextWithPictureAndCTA1"
    >
      <div className="max-w-lg space-y-4 flex flex-col justify-center m-6 p-4 bg-white lg:bg-transparent lg:-ml-96 my-8 ">
        <h2 className="text-2xl">
          <TextRender resource={title} />
        </h2>
        <p>
          <TextRender resource={description} />
        </p>

        <h2 className="font-bold">
          <RichTextRender resource={bulletListTitle} />
        </h2>
        <ul className="list-disc ml-8">
          {bullets.resources.map((bullet, index) => {
            return (
              <li key={index}>
                <RichTextRender resource={bullet} />
              </li>
            );
          })}
        </ul>

        <CMSButton
          onClick={clickHandler}
          buttonText={<TextRender resource={ctaText} />}
          tracking={{
            expectedResult: "header cta button clicked",
            innerText: getRawText(ctaText),
            resourceId: ctaText.id,
            type: "button",
          }}
        />
      </div>

      <ImageRender
        className="absolute inset-0 object-cover w-full h-full -z-10"
        imgClassName="h-full object-cover"
        resource={backgroundImage}
      />
    </div>
  );
};

type HeaderLeftTextWithPictureAndCTA1Props = {
  backgroundImage: CMSImageResource;
  title: CMSText;
  description: CMSText;
  bulletListTitle: CMSText;
  bullets: CMSCollection<CMSRichText>;
  ctaText: CMSText;
};

export const HeaderLeftTextWithPictureAndCTA1StorybookProps: CMSComponentExampleProps<HeaderLeftTextWithPictureAndCTA1Props> =
  {
    backgroundImage: {
      type: "image_resource",
      value: placeholderImageUrlWithText(1900, 1200, "backgroundImage"),
    },
    title: {
      type: "text",
      value: "Example Title",
    },
    description: {
      type: "text",
      value: "This is an example description of what you are buying.",
    },
    bulletListTitle: {
      type: "text",
      value: "Bullet list title:",
    },
    bullets: {
      type: "richtext",
      value: "So many things you can do",
      retrieveAll: true,
    },
    ctaText: {
      type: "text",
      value: "Click here!",
    },
  };

export default HeaderLeftTextWithPictureAndCTA1;
