import { placeholderImageUrlWithText } from "stories/helpers";
import {
  ImageRender,
  RichTextRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSImageResource,
  CMSRichText,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const ImageNextToText2 = ({
  image,
  title,
  text,
}: InjectableComponentProps<ImageNextToText2Props>) => {
  return (
    <div className="flex flex-col md:flex-row m-auto gap-x-12 justify-center p-8">
      <div className="flex flex-col gap-y-2 my-2 md:my-auto w-4/5 md:w-1/2 xl:w-1/3 order-2 md:order-1 mx-8 text-center md:mx-1 md:text-left pt-4">
        <h2 className="flex font-bold text-gray-700 text-xl md:text-2xl flex-wrap">
          <TextRender resource={title} />
        </h2>
        <RichTextRender resource={text} />
      </div>
      <div className="flex flex-col md:block w-full h-1/2 xl:w-1/4 my-auto order-1 md:order-2">
        <ImageRender resource={image} />
      </div>
    </div>
  );
};

type ImageNextToText2Props = {
  image: CMSImageResource;
  title: CMSText;
  text: CMSRichText;
};

export const ImageNextToText2StorybookProps: CMSComponentExampleProps<ImageNextToText2Props> =
  {
    image: {
      defaultTags: [{ type: "value_prop_image" }],
      type: "image_resource",
      value: placeholderImageUrlWithText(100, 80, "logo"),
      metadata: {
        alt: "teacher profile",
      },
    },
    title: {
      defaultTags: [{ type: "section_title" }],
      type: "text",
      value: "Meet your teacher, Scott Houston",
    },
    text: {
      defaultTags: [{ type: "value_prop_content" }],
      type: "richtext",
      value: "lorem ipsum ~300 characters",
    },
  };

export default ImageNextToText2;
