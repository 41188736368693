import {
  RichTextRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSQuestionAndAnswer,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const FAQ1 = ({ questionAndAnswer }: InjectableComponentProps<FAQ1Props>) => {
  return (
    <div className="text-center">
      <h3 className="text-2xl">Frequently Asked Questions</h3>

      <div className="space-y-4 mt-16 flex flex-col">
        {questionAndAnswer.resources.map((entry, index) => {
          return (
            <div
              key={index}
              className="text-left p-4 odd:bg-tertiary odd:bg-opacity-50"
            >
              <p className="font-bold my-2 italic max-w-prose m-auto">
                <i>
                  <TextRender resource={entry.question} />
                </i>
              </p>
              <div className="max-w-prose m-auto">
                <RichTextRender resource={entry.answer} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

type FAQ1Props = {
  questionAndAnswer: CMSCollection<CMSQuestionAndAnswer>;
};

export const FAQ1StorybookProps: CMSComponentExampleProps<FAQ1Props> = {
  questionAndAnswer: {
    defaultTags: [{ type: "frequently_asked_questions" }],
    retrieveAll: true,
    exampleQuantity: 3,
    type: "questionAndAnswer",
    values: [
      {
        value: {
          question: "What is this?",
          answer: "It is a thing.",
        },
      },
      {
        value: {
          question: "What is this?",
          answer: "It is a thing.",
        },
      },
      {
        value: {
          question: "What is this?",
          answer: "It is a thing.",
        },
      },
    ],
  },
};

export default FAQ1;
