import { placeholderImageUrlWithText } from "stories/helpers";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import EmailSubscriptionInputAndButton from "../SubComponents/EmailSubscriptionInputAndButton";
import {
  CMSImageResource,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { ImageRender, TextRender } from "../../utils/cms/renderableResources";

const BrandHeaderBackgroundImage1 = ({
  apiUrl,
  brand,
  clientSideId,
  ctaDescriptiveText,
  image,
  title,
  text,
}: InjectableComponentProps<BrandHeaderBackgroundImage1Props>) => {
  return (
    <div className="relative h-full">
      <ImageRender
        resource={image}
        className="absolute inset-0 object-cover w-full h-full -z-10"
        imgClassName="h-full object-cover brightness-50 object-top"
      />
      <div className="flex flex-col gap-y-8 m-auto w-4/5 md:w-1/2 xl:w-1/3 h-full justify-center pb-4 sm:py-8 sm:pt-16">
        <h1 className="font-bold text-4xl text-white text-center pt-8">
          <TextRender resource={title} />
        </h1>
        <TextRender
          resource={text}
          className="flex text-white justify-center mx-auto text-xl text-justify order-3 sm:order-2"
        />

        <div className="pb-16 pt-8 order-2 sm:order-3">
          <div className="flex flex-col bg-primary justify-center pt-4 w-full order-4 rounded-lg shadow-2xl">
            <div className="self-center mb-4 px-4 text-center ">
              <TextRender
                resource={ctaDescriptiveText}
                className="text-white font-bold"
              />
            </div>
            <EmailSubscriptionInputAndButton
              apiUrl={apiUrl}
              brandId={brand?.id}
              clientSideId={clientSideId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

type BrandHeaderBackgroundImage1Props = {
  ctaDescriptiveText: CMSText;
  image: CMSImageResource;
  title: CMSText;
  text: CMSText;
};

export const BrandHeaderBackgroundImage1StorybookProps: CMSComponentExampleProps<BrandHeaderBackgroundImage1Props> =
  {
    image: {
      defaultTags: [{ type: "header_image" }],
      type: "image_resource",
      value: placeholderImageUrlWithText(400, 600, "image of content"),
    },
    title: {
      defaultTags: [{ type: "header_title" }],
      type: "text",
      value: "Buy this thing and you'll get this benefit!",
    },
    text: {
      defaultTags: [{ type: "header_subtitle" }],
      type: "text",
      value: "These are all of the reasons you should buy this thing...",
    },
    ctaDescriptiveText: {
      defaultTags: [{ type: "descriptive_text" }],
      type: "text",
      value: "Please sign up for our intro course.",
    },
  };

export default BrandHeaderBackgroundImage1;
