import {
  WebinarInteractionTypes,
  Event,
} from "@combined-curiosity/collector-js";
import { useEffect, useReducer, useState, useCallback } from "react";
import { definitions } from "../../types";
import {
  getRawText,
  getImageUrl,
  VideoRender,
} from "../../utils/cms/renderableResources";
import { generateClientSideUUID } from "../../utils/uuid/uuid";
import xmsSupabase, { clientSideSupabase } from "../../utils/xmsSupabase";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSWebinarResource,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  HandleVideoPlaybackEvent,
  VideoTrackingEvent,
} from "../SubComponents/Video";
import WebinarChat from "../SubComponents/WebinarChat/WebinarChat";
import { useTracking } from "../trackEvent";
import PostWebinarRoom from "./PostWebinarRoom";
import BuyButton from "./BuyButton";
import WaitingRoom from "./WaitingRoom";
import { webinarReducer } from "./webinarReducer";
import WelcomeScreen from "./WelcomeScreen";
import useMatchMedia from "../../utils/pageLayout/useMatchMedia";
import { getKeys } from "../../utils/types/getKeys";
import SideBarBuyButton from "./SideBarBuyButton";
import VideoBuyButton from "./VideoBuyButton";
import { useFeatureFlag } from "components/useFeatureFlag";

class Chapter {
  name: string;
  start: number;
  end?: number;

  constructor(name: string, start: number, end?: number) {
    this.name = name;
    this.start = start;
    this.end = end;
  }

  active(currentTime: number) {
    if (this.end) {
      return currentTime >= this.start && currentTime < this.end;
    } else {
      return currentTime >= this.start;
    }
  }
}

const Webinar1 = ({
  webinar,
  brandAbbreviation,
  brand,
  featureFlagHash,
}: InjectableComponentProps<Webinar1Props>) => {
  const videoBuyButtonType = useFeatureFlag("webinarBuyButton", {
    fallback: "original_video_overlay",
  });
  const [chatHidden, setChatHidden] = useState<boolean>(false);
  const [initialized, setInitialized] = useState(false); // used to make sure we have all necessary data to render
  const isOneColumn = useMatchMedia("(max-width: 1023px)", false); // where tailwind breaks smaller than 'lg' into one column
  const isLandscape = useMatchMedia("(orientation: landscape)", false);
  const [contentSeenEventsTracked, setContentSeenEventsTracked] = useState<
    Record<string, boolean>
  >({});
  const [videoFullScreen, setVideoFullScreen] = useState(false);

  const chapters = webinar.chapters_seen?.metadata?.data;
  const interactiveContent = webinar.interactive_content?.metadata?.data;

  if (!chapters) {
    // eslint-disable-next-line no-console
    console.error("No chapters found for webinar", webinar);
  }

  if (!interactiveContent) {
    // eslint-disable-next-line no-console
    console.error("No interactive content found for webinar", webinar);
  }

  const { setUserAttribute, track, trackWebinarEvent } = useTracking();

  // reset the zoom on the page if a user zooms in and then rotates their device
  useEffect(() => {
    const viewport: HTMLElement | null = document.querySelector(
      'meta[name="viewport"]'
    );
    if (viewport) {
      // zoom out a little extra just for a second so that the page can detect a change and apply it
      viewport.setAttribute("content", "initial-scale=0.9");
      viewport.setAttribute("content", "initial-scale=1");
    }
  }, [isLandscape]);

  // TODO: normalize webinar: { video } so that { video } are normalized to have { value: } and { values: } like all other resources
  // should be somewhere in `getOptionsForPageRendering`
  const video = webinar?.video;
  const tagline =
    getRawText(webinar?.tagline || {}) ||
    "Learn the 3 secrets to creating rich, beautiful paintings";
  const waitingRoomImage = getImageUrl(webinar?.waitingRoomImage);

  const [messageIdsToFetch, setMessageIdsToFetch] = useState<Array<string>>([]);
  const [email, setEmail] = useState<string | undefined>();
  const [leadId, setLeadId] = useState<string | undefined>();
  const [joinTracked, setJoinTracked] = useState(false);
  const [viewTracked, setViewTracked] = useState(false);
  const [webinarInstanceId, setWebinarInstanceId] = useState<
    string | undefined
  >();
  const [webinarId, setWebinarId] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      const messages = xmsSupabase.getData<definitions["messages"]>(
        await clientSideSupabase.supabase
          .from("messages")
          .select("*")
          .eq("room_type", "webinar_instance")
          .eq("room_id", webinar?.id)
      );
      setMessageIdsToFetch(messages.map((m) => m.id));
    })();
  }, [webinar?.id]);

  const [state, dispatch] = useReducer(webinarReducer, {
    startsAt: undefined,
    currentTimeInSeconds: 0,
    viewType: "live",
    pastWelcomeScreen: false,
  });

  const handleBeforeUnload = useCallback(() => {
    if (webinarInstanceId && webinarId && leadId && email) {
      const uniqueId = generateClientSideUUID();
      const event: Event = {
        action: "webinar_interaction",
        email,
        meta: {
          interaction: WebinarInteractionTypes.left.name,
          viewType: state.viewType,
          webinarInstanceId,
          webinarId,
          anonymousWebinarViewer: false,
          webinarRegistrationId: state.webinarRegistrationId || "",
        },
        clientSubmissionUniqueId: uniqueId,
        leadId: leadId,
      };
      if (featureFlagHash) {
        event.cohortId = featureFlagHash;
      }
      track(event, "beacon");
    }
  }, [
    email,
    state.viewType,
    webinarInstanceId,
    webinarId,
    leadId,
    featureFlagHash,
    track,
  ]);

  // fire event when user leaves page
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // putting trackWebinarEvent in the dependency array causes an infinite loop, even though it is wrapped in useCallback
  }, [handleBeforeUnload]);

  const trackView = useCallback(() => {
    if (
      viewTracked === false &&
      email &&
      webinarInstanceId &&
      webinarId &&
      leadId
    ) {
      const uniqueId = generateClientSideUUID();
      track({
        action: "webinar_interaction",
        email,
        meta: {
          interaction: "view",
          viewType: state.viewType,
          webinarInstanceId,
          webinarId,
          currentVideoOffset: state.currentTimeInSeconds,
          anonymousWebinarViewer: false,
          webinarRegistrationId: state.webinarRegistrationId || "",
        },
        clientSubmissionUniqueId: uniqueId,
        leadId,
      });
      setViewTracked(true);
    }
  }, [
    email,
    state.viewType,
    webinarInstanceId,
    track,
    leadId,
    viewTracked,
    webinarId,
    state.currentTimeInSeconds,
  ]);

  const trackJoined = useCallback(() => {
    if (
      joinTracked === false &&
      email &&
      webinarInstanceId &&
      webinarId &&
      leadId
    ) {
      const uniqueId = generateClientSideUUID();
      track({
        action: "webinar_interaction",
        email: email,
        meta: {
          viewType: state.viewType,
          interaction: "joined",
          currentVideoOffset: state.currentTimeInSeconds,
          webinarInstanceId,
          webinarId,
          anonymousWebinarViewer: false,
          webinarRegistrationId: state.webinarRegistrationId || "",
        },
        clientSubmissionUniqueId: uniqueId,
        leadId,
      });
      setJoinTracked(true);
    }
  }, [
    email,
    state.viewType,
    state.currentTimeInSeconds,
    webinarInstanceId,
    webinarId,
    track,
    leadId,
    joinTracked,
  ]);

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      // webinarRegistrationId should be a url slug component or passed as a prop here
      // then the rest of these can be retrieved when we make the call to the db anyway
      const webinarInstanceId = urlParams.get("webinarInstanceId") || "";
      setWebinarInstanceId(webinarInstanceId);
      const startTime = urlParams.get("startTime");
      const leadId = urlParams.get("leadId") || "";
      const webinarRegistrationId =
        urlParams.get("webinarRegistrationId") || "";
      const isReplay = urlParams.get("replay");

      const webinarInstance = xmsSupabase.getData<
        definitions["webinar_instances"]
      >(
        await clientSideSupabase.supabase
          .from("webinar_instances")
          .select("*")
          .eq("id", webinarInstanceId)
      )[0];

      setWebinarId(webinarInstance.webinar_id);

      if (webinarInstance?.end_time) {
        const endTime = new Date(webinarInstance.end_time);

        if (endTime < new Date()) {
          // automatically set a replay if after the time the webinar ended
          dispatch({ type: "SET_WEBINAR_REPLAY", payload: { value: true } });
        }
      }

      setUserAttribute("leadId", leadId);
      setLeadId(leadId);

      const lead = await (
        await fetch(`/api/leads/${leadId}/`, { method: "GET" })
      ).json();

      setUserAttribute("email", lead?.email);
      setEmail(lead?.email);

      dispatch({ type: "SET_LEAD_ID", payload: { value: leadId } });
      dispatch({
        type: "SET_WEBINAR_REGISTRATION_ID",
        payload: { value: webinarRegistrationId },
      });
      dispatch({
        type: "SET_WEBINAR_INSTANCE_ID",
        payload: { value: webinarInstanceId },
      });

      // allow manually setting a replay if we don't have a start time or end time set for some reason
      if (isReplay) {
        dispatch({ type: "SET_WEBINAR_REPLAY", payload: { value: true } });
      }

      if (startTime) {
        dispatch({
          type: "WEBINAR_STARTS_AT",
          payload: { value: new Date(startTime) },
        });
      }

      const checkBeforeStartTime = setInterval(() => {
        if (state.beforeStartTime) {
          dispatch({ type: "COMPUTE_BEFORE_START_TIME", payload: {} });
        } else {
          clearInterval(checkBeforeStartTime);
        }
      }, 1000);

      setInitialized(true);

      return () => {
        clearInterval(checkBeforeStartTime);
      };
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.beforeStartTime]);

  useEffect(() => {
    trackJoined();
  }, [trackJoined]);

  useEffect(() => {
    if (!state.webinarInstanceId) {
      return;
    }
    if (!state.leadId) {
      return;
    }

    const channel = clientSideSupabase.supabase.channel(
      state.webinarInstanceId
    );

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        await channel.track({
          leadId: state.leadId,
          online_at: new Date().toISOString(),
        });
      }
    });
  }, [state.leadId, state.webinarInstanceId]);

  const chatDisplayInnerText = useCallback(() => {
    return chatHidden ? "Show Chat" : "Hide Chat";
  }, [chatHidden]);

  const handleChatViewButtonClick = useCallback(() => {
    if (email && leadId) {
      const uniqueId = generateClientSideUUID();
      track({
        action: "click",
        email,
        meta: {
          type: "button",
          expectedResult: chatDisplayInnerText(),
          innerText: chatDisplayInnerText(),
        },
        clientSubmissionUniqueId: uniqueId,
        leadId,
      });
    }
    setChatHidden(!chatHidden);
  }, [chatDisplayInnerText, chatHidden, email, leadId, track]);

  const handleVideoPlaybackEvent: HandleVideoPlaybackEvent = (event) => {
    if (state.currentTimeInSeconds === event.currentTime) {
      return;
    }

    dispatch({
      type: "SET_CURRENT_TIME",
      payload: { value: event.currentTime },
    });
    dispatch({
      type: "SET_VIDEO_DURATION",
      payload: { value: event.totalTime },
    });

    let currentChapterName = "";

    const chapterNames = getKeys(chapters);

    const currentChapter = chapterNames
      .map((chapterName) => {
        return new Chapter(
          chapterName.toString(),
          chapters[chapterName].starts_at,
          chapters[chapterName].ends_at
        );
      })
      .find((chapter) => chapter.active(event.currentTime));

    if (currentChapter) {
      currentChapterName = currentChapter.name;
    }

    if (
      webinarInstanceId &&
      webinarId &&
      email &&
      leadId &&
      !contentSeenEventsTracked[currentChapterName] &&
      currentChapterName
    ) {
      const uniqueId = generateClientSideUUID();
      track({
        action: "webinar_interaction",
        meta: {
          interaction: WebinarInteractionTypes.content_seen.name,
          breakpointSeen: currentChapterName,
          viewType: state.isReplay ? "replay" : "live",
          currentVideoOffset: event.currentTime,
          webinarInstanceId,
          webinarId,
          anonymousWebinarViewer: false,
          webinarRegistrationId: state.webinarRegistrationId || "",
        },
        clientSubmissionUniqueId: uniqueId,
        email,
        leadId,
      });

      setContentSeenEventsTracked((prev) => {
        return {
          ...prev,
          [currentChapterName]: true,
        };
      });
    }

    if (
      event.currentTime > 0 &&
      event.currentTime % 60 === 0 &&
      webinarInstanceId &&
      webinarId &&
      email &&
      leadId
    ) {
      const uniqueId = generateClientSideUUID();
      track({
        action: "webinar_interaction",
        meta: {
          interaction: WebinarInteractionTypes.still_watching.name,
          viewType: state.isReplay ? "replay" : "live",
          currentVideoOffset: event.currentTime,
          webinarInstanceId,
          currentChapter: currentChapterName,
          webinarId,
          anonymousWebinarViewer: false,
          webinarRegistrationId: state.webinarRegistrationId || "",
        },
        clientSubmissionUniqueId: uniqueId,
        email,
        leadId,
      });
    }
  };

  const handleVideoTrackingEvent = (opts: VideoTrackingEvent) => {
    if (!leadId) {
      return;
    }

    let interaction: "video_error" | "video_play";
    switch (opts.status) {
      case "video_playback_error":
        interaction = "video_error";
        break;
      case "video_playback_started_successfully":
        interaction = "video_play";
        break;
      // todo: refactor this to handle all cases (fullscreen, not fullscreen, pause, play, mute, scrub, etc)
      case "fullscreen":
        interaction = "video_play";
        setVideoFullScreen(true);
        break;
      case "inline":
        interaction = "video_play";
        setVideoFullScreen(false);
        break;
    }

    if (interaction === "video_play" && state.beforeStartTime === false) {
      trackView();
    }

    if (
      opts.status === "video_playback_error" ||
      opts.status === "video_playback_started_successfully"
    ) {
      track(
        {
          action: "video_interaction",
          meta: {
            interaction,
            currentVideoOffset: opts.currentTime,
            webinarInstanceId,
            actionInitiatedBy: "system", // autoplay, video error, etc.
            videoId: webinar.video.id,
          },
          clientSubmissionUniqueId: generateClientSideUUID(),
          email,
          leadId,
        },
        "xhr"
      );
    }
  };

  if (!initialized) {
    return null;
  }

  if (!webinarInstanceId || !webinarId) {
    if (typeof window !== "undefined") {
      window.Rollbar?.error(
        Error("Attempted webinar view without known webinar")
      );
      window.location.href = "/";
    }
    return <></>;
  }

  const primaryDisplay = () => {
    if (!state.pastWelcomeScreen) {
      // Force people to interact with the page so we can play with audio
      return (
        <WelcomeScreen
          onClick={() =>
            dispatch({
              type: "SET_PAST_WELCOME_SCREEN",
              payload: { value: true },
            })
          }
          waitingRoomImage={waitingRoomImage}
        />
      );
    }

    if (state.beforeStartTime && !state.isReplay) {
      // waiting room, allow users to chat
      return (
        <WaitingRoom
          startsAt={state.startsAt}
          waitingRoomImage={waitingRoomImage}
        />
      );
    }

    if (state.videoCompleted) {
      return (
        <PostWebinarRoom
          viewType={state.isReplay ? "replay" : "live"}
          waitingRoomImage={waitingRoomImage}
          email={email ? email : ""}
          leadId={leadId ? leadId : ""}
          brandAbbreviation={brandAbbreviation}
          currentVideoOffset={state.currentTimeInSeconds}
          webinarInstanceId={webinarInstanceId}
          webinarId={webinarId}
        />
      );
    }

    if (video) {
      // get time difference between now and start time
      const now = new Date();
      let timeDifference = 0;
      if (!state.startsAt) {
        return null;
      }
      if (state.startsAt) {
        timeDifference = Math.floor(
          (now.getTime() - state.startsAt.getTime()) / 1000
        );
      }

      if (state.isReplay) {
        timeDifference = 0; // start back at 0 if replay
      }

      return (
        <VideoRender
          autoplay
          startAt={timeDifference}
          resource={video}
          brandAbbreviation={brandAbbreviation}
          handleVideoPlaybackEvent={handleVideoPlaybackEvent}
          options={{
            lockedPlayback: state.isReplay ? false : true,
            canAutoPlayWithSound: true,
            onVideoEvent: handleVideoTrackingEvent,
          }}
        />
      );
    }

    return null;
  };

  return (
    <div className="flex flex-col h-screen p-2">
      <h1 className="text-sm font-bold">{brand.name}</h1>
      <h2 className="text-sm font-normal">{tagline}</h2>
      <div
        className={`flex flex-col lg:flex-row lg:justify-between relative pt-2`}
      >
        <div
          className={`rounded-lg relative max-h-[95vh] mx-auto w-full mr-0 lg:mr-2 ${
            chatHidden ? "w-full lg:w-[80vw]" : "lg:w-3/5"
          }`}
        >
          {primaryDisplay()}
          {interactiveContent &&
            interactiveContent.buyButton &&
            videoBuyButtonType == "original_video_overlay" && (
              <BuyButton
                viewType={state.viewType}
                trackWebinarEvent={trackWebinarEvent}
                config={interactiveContent.buyButton}
                currentTime={state.currentTimeInSeconds}
                webinarInstanceId={webinarInstanceId}
                webinarId={webinarId}
                className="absolute top-0 left-0 m-4 flex flex-col justify-center items-center"
                isVisible={!videoFullScreen}
              />
            )}
          <div className="z-50 absolute top-2 right-2 w-2/5">
            {interactiveContent &&
              interactiveContent.buyButton &&
              videoBuyButtonType == "popup" && (
                <VideoBuyButton
                  viewType={state.viewType}
                  trackWebinarEvent={trackWebinarEvent}
                  duration={webinar.video.metadata.duration}
                  config={interactiveContent.buyButton}
                  currentTime={state.currentTimeInSeconds}
                  webinarInstanceId={webinarInstanceId}
                  webinarId={webinarId}
                  title="ZERO RISK 30 DAY GUARANTEE"
                  startsAt={state.isReplay ? new Date() : state.startsAt}
                  brandAbbreviation={brandAbbreviation}
                  isVisible={!videoFullScreen}
                />
              )}
          </div>
        </div>

        {/* min height needed so button has margin at bottom of screen when chat is hidden */}
        <div
          className={`relative flex flex-col lg:flex-1 min-h-12 pt-1 lg:pt-0`}
          style={{
            height:
              typeof window !== "undefined"
                ? `${window?.innerHeight}px`
                : "100vh",
          }}
        >
          <button
            className={`top-2 lg:-top-12 right-0 absolute bg-blue-400 hover:bg-blue-500 rounded text-white px-4 py-2 whitespace-nowrap`}
            onClick={handleChatViewButtonClick}
          >
            {chatDisplayInnerText()}
          </button>
          <div
            className={`${isOneColumn && isLandscape ? "h-[90vh]" : "h-3/4"} 
            ${chatHidden ? "hidden" : ""}`}
          >
            <WebinarChat
              viewType={state.viewType}
              allowSend={!state.isReplay}
              messagesToFetch={messageIdsToFetch}
              leadId={state.leadId}
              webinarRegistrationId={state.webinarRegistrationId}
              webinarInstanceId={webinarInstanceId}
              webinarId={webinarId}
              userCurrentTime={state.currentTimeInSeconds}
            >
              {interactiveContent &&
                interactiveContent.buyButton &&
                videoBuyButtonType == "sidebar" && (
                  <SideBarBuyButton
                    viewType={state.viewType}
                    trackWebinarEvent={trackWebinarEvent}
                    duration={webinar.video.metadata.duration}
                    config={interactiveContent.buyButton}
                    currentTime={state.currentTimeInSeconds}
                    webinarInstanceId={webinarInstanceId}
                    webinarId={webinarId}
                    title="ZERO RISK 30 DAY GUARANTEE"
                    startsAt={state.isReplay ? new Date() : state.startsAt}
                    setChatHidden={setChatHidden}
                    brandAbbreviation={brandAbbreviation}
                    isVisible={!chatHidden && !videoFullScreen}
                  />
                )}
            </WebinarChat>
          </div>
        </div>
      </div>
    </div>
  );
};

type Webinar1Props = {
  webinar: CMSWebinarResource;
};

export const Video1StorybookProps: CMSComponentExampleProps<Webinar1Props> = {
  video: {
    defaultTags: [{ type: "webinar" }],
    type: "video_resource",
    value: {
      id: "59a5e568-cb7d-489e-8bdc-8ebdb83ad041",
      s3_path:
        "/nma/resources/59a5e568-cb7d-489e-8bdc-8ebdb83ad041/filename-to-remove",
      metadata: {
        duration: 1112,
        filename: "",
        subtitle: "",
        original_resolution: "1080p",
        available_resolutions: ["1080p", "720p", "540p"],
      },
    },
  },
  brandAbbreviation: "nma",
};
export default Webinar1;
