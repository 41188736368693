import { TextRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { CMSText, InjectableComponentProps } from "../cms/types/cmsTypes";

const TextBlockWithTitle1 = ({
  title,
  textBlock,
}: InjectableComponentProps<TextBlockWithTitle1Props>) => {
  return (
    <div className="flex flex-col items-center space-y-8 mt-8 max-w-[80vw] m-auto mb-10">
      <h3 className="text-2xl">
        <TextRender resource={title} />
      </h3>
      <p className="max-w-prose text-left">
        <TextRender resource={textBlock} />
      </p>
    </div>
  );
};

type TextBlockWithTitle1Props = {
  title: CMSText;
  textBlock: CMSText;
};

export const TextBlockWithTitle1StorybookProps: CMSComponentExampleProps<TextBlockWithTitle1Props> =
  {
    title: { type: "text", value: "I am a title! " },
    textBlock: {
      type: "text",
      value:
        "Here is some additional information that you can read and learn more about our offerings.",
    },
  };

export default TextBlockWithTitle1;
