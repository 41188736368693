import { CustomCssVars } from "../../CustomCssVars";
import {
  convertHexToRGB,
  convertToHex,
} from "../../../utils/styling/colorManipulation";
import { useEffect, useState } from "react";

export const useColorOverrides = (colorOverrides: any, brandConfig: any) => {
  const [primary, setPrimary] = useState(
    colorOverrides?.value?.primaryRgbColor ||
      brandConfig?.primary_rgb_color ||
      brandConfig?.primaryRgbColor
  );
  const [secondary, setSecondary] = useState(
    colorOverrides?.value?.secondaryRgbColor ||
      brandConfig?.secondary_rgb_color ||
      brandConfig?.secondaryRgbColor
  );
  const [tertiary, setTertiary] = useState(
    colorOverrides?.value?.tertiaryRgbColor ||
      brandConfig?.tertiary_rgb_color ||
      brandConfig?.tertiaryRgbColor
  );
  const [quaternary, setQuaternary] = useState(
    colorOverrides?.value?.quaternaryRgbColor ||
      brandConfig?.quaternary_rgb_color ||
      brandConfig?.quaternaryRgbColor
  );

  useEffect(() => {
    if (brandConfig) {
      setPrimary(
        colorOverrides?.value?.primaryRgbColor ||
          brandConfig?.primary_rgb_color ||
          brandConfig?.primaryRgbColor
      );
      setSecondary(
        colorOverrides?.value?.secondaryRgbColor ||
          brandConfig?.secondary_rgb_color ||
          brandConfig?.secondaryRgbColor
      );
      setTertiary(
        colorOverrides?.value?.tertiaryRgbColor ||
          brandConfig?.tertiary_rgb_color ||
          brandConfig?.tertiaryRgbColor
      );
      setQuaternary(
        colorOverrides?.value?.quaternaryRgbColor ||
          brandConfig?.quaternary_rgb_color ||
          brandConfig?.quaternaryRgbColor
      );
    }
  }, [brandConfig, colorOverrides]);

  const renderColors = (
    <CustomCssVars
      brandConfig={{
        primary_rgb_color: primary,
        secondary_rgb_color: secondary,
        tertiary_rgb_color: tertiary,
        quaternary_rgb_color: quaternary,
      }}
    />
  );

  const developmentModeColor = (
    <div className="w-80">
      <div>
        <label htmlFor="primary" className="flex justify-between">
          Primary Color
          <input
            id="primary"
            type="color"
            value={convertToHex(primary)}
            onChange={(e) => setPrimary(convertHexToRGB(e.target.value))}
          />
        </label>
      </div>

      <div>
        <label htmlFor="secondary" className="flex justify-between">
          Secondary Color
          <input
            id="secondary"
            type="color"
            value={convertToHex(secondary)}
            onChange={(e) => setSecondary(convertHexToRGB(e.target.value))}
          />
        </label>
      </div>

      <div>
        <label htmlFor="tertiary" className="flex justify-between">
          Tertiary Color
          <input
            id="tertiary"
            type="color"
            value={convertToHex(tertiary)}
            onChange={(e) => setTertiary(convertHexToRGB(e.target.value))}
          />
        </label>
      </div>

      <div>
        <label htmlFor="quaternary" className="flex justify-between">
          Quaternary Color
          <input
            id="quaternary"
            type="color"
            value={convertToHex(quaternary)}
            onChange={(e) => setQuaternary(convertHexToRGB(e.target.value))}
          />
        </label>
      </div>

      {renderColors}
    </div>
  );

  const productionModeColor = renderColors;

  return [developmentModeColor, productionModeColor];
};
