import { useState } from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import Requires from "../../internal_components/Requires";
import { useEffect } from "react";
import { useClickHandler } from "../cms/PageContext";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSRichText,
  CMSSchedule,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  getRawSchedule,
  getRawText,
  RichTextRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import CountdownTimer from "../SubComponents/CountdownTimer";
import calculateSchedule, {
  ScheduleType,
} from "../../utils/schedule/calculateSchedule";
import CMSButton from "../SubComponents/CMSButton";
import OnlyOnClient from "../OnlyOnClient/OnlyOnClient";

const StickyFooter2 = ({
  text,
  countdownLabelOnceCompleted,
  countdownToRecurringDateTime,
  component,
  mobileTitle,
  ctaButtonText,
}: InjectableComponentProps<StickyFooter2Props>) => {
  const [collapsed, setCollapsed] = useState(false);
  const clickHandler = useClickHandler(component, "ctaButtonText");
  const [startTime, setStartTime] = useState<Date | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const startTimeUrl = urlParams.get("startTime");

    if (!startTimeUrl) {
      return;
    }

    setStartTime(new Date(startTimeUrl));
  }, []);

  const [formState] = useState({
    timezone: "EST",
  });

  function Collapse() {
    return (
      <button
        onClick={() => setCollapsed(!collapsed)}
        className="absolute -top-9 right-0 p-2 bg-tertiary-darker hover:bg-tertiary text-xl"
      >
        {collapsed ? <AiFillCaretUp /> : <AiFillCaretDown />}
      </button>
    );
  }

  if (!text || !ctaButtonText) {
    return null;
  }

  return (
    <div className="flex w-full text-sm items-center bg-white fixed z-50 bottom-0">
      <Collapse />
      <div
        className={`w-full bg-tertiary bg-opacity-60 ${
          collapsed ? "p-1" : "p-4"
        }`}
      >
        <Requires value={!collapsed}>
          <div className="flex flex-col sm:flex-row gap-5 justify-between sm:text-left w-full sm:px-12">
            <div className="sm:flex hidden flex-col">
              <Requires value={startTime || countdownToRecurringDateTime}>
                <RichTextRender resource={text} className="my-auto text-md" />
              </Requires>
              {/* Once we have a page to view dates we will link it to the button */}
              {/* <Requires value={underlinedLinkText}>
                <button
                  className="underline whitespace-nowrap hover:text-secondary-darker self-start"
                  onClick={clickHandler}
                >
                  <TextRender resource={underlinedLinkText} />
                </button>
              </Requires> */}
            </div>
            <div className="flex flex-col items-center sm:flex-row gap-5 sm:gap-12 w-full sm:w-fit">
              <TextRender
                resource={mobileTitle}
                className="flex text-md sm:hidden font-semibold "
              />
              <OnlyOnClient>
                <Requires value={startTime || countdownToRecurringDateTime}>
                  <CountdownTimer
                    styleType="condensed"
                    countdownLabelOnceCompleted={getRawText(
                      countdownLabelOnceCompleted
                    )}
                    toDate={
                      startTime
                        ? [startTime]
                        : calculateSchedule(
                            new Date(),
                            getRawSchedule(countdownToRecurringDateTime),
                            formState.timezone
                          )
                    }
                  />
                </Requires>
              </OnlyOnClient>
              <div className="flex flex-col w-full">
                <CMSButton
                  buttonBgClass="bg-tertiary-darker hover:brightness-90"
                  buttonClass="px-8 py-2 w-full rounded text-white text-xl font-semibold"
                  buttonText={getRawText(ctaButtonText)}
                  onClick={clickHandler}
                  tracking={{
                    expectedResult: "footer button clicked",
                    innerText: getRawText(ctaButtonText),
                    resourceId: ctaButtonText.id,
                    type: "button",
                  }}
                />
                {/* Once we have a page to view dates we will link it to the button */}
                {/* <Requires value={underlinedLinkText}>
                  <button
                    className="block sm:hidden px-6 py-3 w-full underline whitespace-nowrap hover:text-secondary-darker -mb-4"
                    onClick={clickHandler}
                  >
                    <TextRender resource={underlinedLinkText} />
                  </button>
                </Requires> */}
              </div>
            </div>
          </div>
        </Requires>
      </div>
    </div>
  );
};

type StickyFooter2Props = {
  text: CMSRichText;
  countdownLabelOnceCompleted: CMSText;
  countdownToRecurringDateTime: CMSSchedule;
  mobileTitle: CMSText;
  ctaButtonText: CMSText;
  underlinedLinkText: CMSText;
};

export const StickyFooter2StorybookProps: CMSComponentExampleProps<StickyFooter2Props> =
  {
    text: {
      type: "richtext",
      metadata: {
        value: `Next Webinar:<br/><b>Tuesday, February 28 2023, 1:30 PM</b><br/>Eastern Time (US and Canada)`,
      },
    },
    countdownLabelOnceCompleted: {
      defaultTags: [{ type: "countdown_label" }],
      type: "text",
      value: "Webinar is live!",
    },
    countdownToRecurringDateTime: {
      defaultTags: [{ type: "countdown_date" }],
      type: "schedule",
      value: {
        recurringSchedule: {
          weekly: {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [
              { start: "15:00", status: "countdown end" },
              { start: "15:01", status: "countdown start" },
            ],
            saturday: [],
            sunday: [],
          },
        },
        options: {
          totalDatesToDisplay: 1,
          inTimezone: "EST",
          mode: "countdown",
        },
      } as ScheduleType,
    },
    mobileTitle: {
      type: "text",
      value: "Next Webinar In:",
    },
    ctaButtonText: {
      type: "text",
      value: "Register",
    },
    underlinedLinkText: {
      type: "text",
      value: "View More Dates",
    },
    component: {
      required_data: {
        ctaButtonText: {
          metadata: {
            onClick: "consoleLog",
          },
        },
      },
    },
  };

export default StickyFooter2;
