import { TextRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { CMSText, InjectableComponentProps } from "../cms/types/cmsTypes";

const Caption1 = ({
  captionLeft,
  captionRight,
}: InjectableComponentProps<Caption1Props>) => {
  return (
    <div className="flex flex-row w-3/5 justify-between italic text-sm font-light text-gray-500 mx-auto">
      <TextRender resource={captionLeft} />
      <TextRender resource={captionRight} />
    </div>
  );
};

type Caption1Props = {
  captionLeft: CMSText;
  captionRight: CMSText;
};

export const Caption1StorybookProps: CMSComponentExampleProps<Caption1Props> = {
  captionLeft: {
    type: "text",
    value: "*Student pictures were changed to protect their privacy.",
  },
  captionRight: {
    type: "text",
    value: "Photo credit: Unsplash",
  },
};

export default Caption1;
