import { TextRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  InjectableComponentProps,
  CMSText,
  CMSTestimonial,
} from "../cms/types/cmsTypes";
const BrandClientTestimonialsNoImages1 = ({
  testimonials,
  title,
}: InjectableComponentProps<BrandClientTestimonialsNoImages1Props>) => {
  return (
    <div className="flex flex-col m-auto gap-x-12 items-center text-center">
      <h2>
        <TextRender
          resource={title}
          className="text-primary text-4xl font-bold"
        />
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-3 justify-items-center">
        {testimonials?.resources.map((entry) => {
          return (
            <div key={`${entry.name} index`} className="mx-auto pt-8">
              <div className="flex flex-col text-center -mt-16 px-4 pt-16 h-full max-w-xs">
                <TextRender
                  resource={entry.name}
                  className="font-bold mt-4 mb-2 text-gray-700"
                />
                <TextRender resource={entry.quote} className="italic" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

type BrandClientTestimonialsNoImages1Props = {
  testimonials: CMSCollection<CMSTestimonial>;
  title: CMSText;
};

export const BrandClientTestimonialsNoImages1StorybookProps: CMSComponentExampleProps<BrandClientTestimonialsNoImages1Props> =
  {
    title: {
      type: "text",
      value: "Hear What Our Customers Say",
      defaultTags: [{ type: "section_title" }],
    },
    testimonials: {
      defaultTags: [{ type: "testimonial" }],
      type: "testimonial",
      retrieveAll: true,
      values: [
        {
          name: "Adam West",
          quote: `You will start to build a fine art language that permeates all the lessons. 
            Nancy is always sweeping up prior info, reinforcing and building new info in a humorous, 
            poetic and dependable style. She has powerful consistency!`,
        },
        {
          name: "Betty White",
          quote: `I'm hooked on these lessons with Nancy. I find her use of color to make every painting 
            come alive fascinating. I'm learning a lot and can see myself improving after every lesson.`,
        },
        {
          name: "Cardi B",
          quote: `Until Nancy, I was basically a failure to launch, always studying but rarely painting, 
            so basically a dreamer. Thanks to Nancy, I paint now. Vivid color is something totally new to me, 
            little did I understand at this phase of my life how much I needed it. The colors permeate my soul`,
        },
      ],
    },
  };

export default BrandClientTestimonialsNoImages1;
