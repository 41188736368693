import { placeholderImageUrlWithText } from "stories/helpers";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSImageResource,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { ImageRender, TextRender } from "../../utils/cms/renderableResources";

const BrandHeader2 = ({
  image,
  title,
  text,
}: InjectableComponentProps<BrandHeader2Props>) => {
  return (
    <div
      data-component="HeaderWithSideImageAndCTA1"
      className="flex flex-row mx-auto md:mx-20 gap-x-20 justify-center"
    >
      <div className="flex flex-col gap-y-8 my-auto w-4/5 md:w-1/2 xl:w-1/3">
        <h1 className="font-bold text-4xl text-primary">
          <TextRender resource={title} />
        </h1>
        <ImageRender resource={image} className=" md:hidden w-full" />
        <TextRender resource={text} className="flex justify-center order-3" />
      </div>
      <div className="flex flex-col hidden md:block w-1/3 md:w-2/3 xl:w-1/4 my-auto">
        <ImageRender resource={image} className="" />
      </div>
    </div>
  );
};

type BrandHeader2Props = {
  image: CMSImageResource;
  title: CMSText;
  text: CMSText;
};

export const BrandHeader2StorybookProps: CMSComponentExampleProps<BrandHeader2Props> =
  {
    image: {
      defaultTags: [{ type: "header_image" }],
      type: "image_resource",
      value: placeholderImageUrlWithText(400, 600, "image of content"),
    },
    title: {
      defaultTags: [{ type: "header_title" }],
      type: "text",
      value: "Buy this thing and you'll get this benefit!",
    },
    text: {
      defaultTags: [{ type: "header_subtitle" }],
      type: "text",
      value: "These are all of the reasons you should buy this thing...",
    },
  };

export default BrandHeader2;
