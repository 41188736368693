import { useCallback, useEffect, useState } from "react";
import {
  MessageWithReactionsAndWebinarRegistrations,
  WithReplies,
} from "@/types/index";
import { WebinarChatMessage } from "components/Webinar/WebinarViper";

type useChatNewMessagesAlertProps = {
  messageCount: number;
  messages:
    | WithReplies<MessageWithReactionsAndWebinarRegistrations>[]
    | Array<WebinarChatMessage>;
  options: {
    scrollableContainerSelector: string;
    individualMessageSelector: string;
  };
};

const useChatNewMessagesAlert = ({
  messageCount,
  messages,
  options,
}: useChatNewMessagesAlertProps) => {
  const [seenMessages, setSeenMessages] = useState<Array<string>>([]);
  const [seenMessagesLastUpdated, setSeenMessagesLastUpdated] =
    useState<number>(0);
  const [atBottom, setAtBottom] = useState(false);

  const scrollToBottomOfMessages = useCallback(() => {
    const messagesContainer = document.querySelector(
      options.scrollableContainerSelector
    );
    if (messagesContainer) {
      messagesContainer.scrollTo(0, messagesContainer.scrollHeight);
    }
  }, [options.scrollableContainerSelector]);

  const atBottomOfMessages = () => {
    if (typeof document === "undefined") {
      return false;
    }
    const messagesContainer =
      document.querySelector(options.scrollableContainerSelector) || "";
    if (messagesContainer) {
      return (
        messagesContainer.scrollHeight - messagesContainer.scrollTop ===
        messagesContainer.clientHeight
      );
    }
    return false;
  };

  useEffect(() => {
    // scroll to bottom on page load
    setTimeout(scrollToBottomOfMessages, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setSeenMessages((messages) => [
          ...new Set([
            ...messages,
            entry.target.getAttribute("data-message-id") || "",
          ]),
        ]);
        setSeenMessagesLastUpdated(Date.now());
      }
    });
  };

  const stringifiedMessages = JSON.stringify(messages);
  const stringifiedSeenMessages = JSON.stringify(seenMessages);

  useEffect(() => {
    const messages = JSON.parse(
      stringifiedMessages
    ) as WithReplies<MessageWithReactionsAndWebinarRegistrations>[];
    const seenMessages = JSON.parse(stringifiedSeenMessages) as Array<string>;

    if (seenMessages.length == 0) {
      // if we don't have any seen messages, we need to set them all to seen
      setSeenMessages(messages.map((message) => message.id));
    }

    const messagesContainer = document.querySelector(
      options.scrollableContainerSelector
    );
    let observerOptions = {
      root: messagesContainer,
      rootMargin: "0px",
      threshold: 0.5,
    };

    let observer = new IntersectionObserver(callback, observerOptions);
    const chatMessage = document.querySelectorAll(
      options.individualMessageSelector
    );
    if (chatMessage) {
      setTimeout(() => {
        chatMessage.forEach((message) => {
          if (
            !seenMessages.includes(
              message.getAttribute("data-message-id") || ""
            )
          ) {
            // we don't need to re-observe ones we've already seen
            observer.observe(message);
          }
        });
      }, 1000);
    }

    return () => {
      observer.disconnect();
    };
  }, [
    stringifiedSeenMessages,
    stringifiedMessages,
    options.scrollableContainerSelector,
    options.individualMessageSelector,
  ]);

  // check if we're at the bottom of the messages container
  useEffect(() => {
    const interval = setInterval(() => {
      const messagesContainer = document.querySelector(
        options.scrollableContainerSelector
      );
      if (!messagesContainer) {
        return;
      }

      if (
        Math.abs(
          messagesContainer.scrollHeight -
            messagesContainer.scrollTop -
            messagesContainer.clientHeight
        ) < 1
      ) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // every time there is a new message, if we're at the bottom, scroll to the bottom for "live feed" view
  useEffect(() => {
    if (atBottom) {
      scrollToBottomOfMessages();
    }
  }, [atBottom, messageCount, scrollToBottomOfMessages]);

  // wait 3 seconds after the last message was seen before showing the alert
  const hasUnseenMessages =
    Date.now() - seenMessagesLastUpdated > 3000 &&
    !(messages as any).every(
      (
        message:
          | WebinarChatMessage
          | MessageWithReactionsAndWebinarRegistrations
      ) => {
        return seenMessages.includes(message.id);
      }
    );
  return { atBottomOfMessages, hasUnseenMessages, scrollToBottomOfMessages };
};

export default useChatNewMessagesAlert;
