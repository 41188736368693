/* eslint-disable @next/next/no-img-element */
import { placeholderImageUrlWithText } from "stories/helpers";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { useClickHandler } from "../cms/PageContext";
import {
  CMSImageResource,
  CMSText,
  CMSRichText,
  InjectableComponentProps,
  CMSCollection,
} from "../cms/types/cmsTypes";
import {
  ImageRender,
  TextRender,
  RichTextRender,
} from "../../utils/cms/renderableResources";

const HeaderWithSideImageAndCTA1 = ({
  bullets,
  component,
  ctaText,
  image,
  subtitle,
  title,
  text,
}: InjectableComponentProps<HeaderWithSideImageAndCTA1Props>) => {
  const clickHandler = useClickHandler(component, "ctaText");

  return (
    <div
      data-component="HeaderWithSideImageAndCTA1"
      className="flex flex-row mx-auto md:mx-20 gap-x-20 justify-center"
    >
      <div className="flex flex-col hidden md:block w-1/3 xl:w-1/4 my-auto">
        <ImageRender resource={image} />
      </div>
      <div className="flex flex-col gap-y-8 my-auto w-4/5 md:w-1/2 xl:w-1/3">
        <h2 className="font-bold text-4xl text-primary">
          <TextRender resource={title} />
        </h2>
        <ImageRender
          resource={image}
          className="inline md:hidden w-4/5 mx-auto"
        />
        <RichTextRender resource={text} />
        <h4 className="font-bold text-xl text-primary -mb-6">
          <RichTextRender resource={subtitle} />
        </h4>
        <ul className="list-disc px-8">
          {bullets.resources.map((bullets, i) => (
            <li key={`bullet_${i}`}>
              <RichTextRender resource={bullets} />
            </li>
          ))}
        </ul>
        <button
          className="font-bold text-lg text-white bg-quaternary hover:bg-secondary p-6 rounded"
          onClick={clickHandler}
        >
          <TextRender resource={ctaText} />
        </button>
      </div>
    </div>
  );
};

type HeaderWithSideImageAndCTA1Props = {
  image: CMSImageResource;
  title: CMSText;
  text: CMSText;
  subtitle: CMSText;
  bullets: CMSCollection<CMSRichText>;
  ctaText: CMSText;
};

export const HeaderWithSideImageAndCTA1StorybookProps: CMSComponentExampleProps<HeaderWithSideImageAndCTA1Props> =
  {
    image: {
      type: "image_resource",
      value: placeholderImageUrlWithText(400, 600, "image of content"),
    },
    title: {
      type: "richtext",
      value: "Buy this thing and you'll get this benefit!",
    },
    text: {
      type: "richtext",
      value: "These are all of the reasons you should buy this thing...",
    },
    subtitle: {
      type: "text",
      value: "But wait, there's more!",
    },
    bullets: {
      type: "richtext",
      retrieveAll: true,
      values: [
        {
          value: "You get this",
        },
        {
          value: "You get that",
        },
        {
          value: "You also get this",
        },
      ],
    },
    ctaText: {
      type: "text",
      value: "Click here now!",
      metadata: {
        onClick: "consoleLog",
      },
    },
  };

export default HeaderWithSideImageAndCTA1;
