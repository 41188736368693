import React, { useState } from "react";
import { PageContext } from "./PageContext";

const RenderedComponentsList = ({
  components,
}: {
  components: Array<React.ReactNode>;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageContextState, setPageContextState] = useState<{ event: any }>({
    event: {},
  });
  const [eventListeners, setEventListeners] = useState<{
    [eventName: string]: Array<Function>;
  }>({});

  const emitEvent = (event: string) => {
    eventListeners[event]?.forEach((listener: any) => {
      listener(event);
    });
  };

  const addEventListener = (eventName: string, callback: any) => {
    setEventListeners((el) => {
      return { ...el, [eventName]: [...(el[eventName] || []), callback] };
    });
  };

  return (
    <PageContext.Provider
      value={{ ...pageContextState, emitEvent, addEventListener }}
    >
      <>{components}</>
    </PageContext.Provider>
  );
};

export default RenderedComponentsList;
