import React, { ReactElement, useEffect, useRef, useState } from "react";

const ToggleContent = ({
  toggle,
  content,
  onlyCloseOnToggleClick,
}: {
  toggle: ReactElement;
  content: ReactElement;
  onlyCloseOnToggleClick?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const toggleTrigger = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!onlyCloseOnToggleClick) {
      const closeContent = (event: KeyboardEvent | MouseEvent) => {
        if (event.target !== toggleTrigger.current?.children[0]) {
          setOpen(false);
        }
      };

      document.addEventListener("click", closeContent);
      return () => document.removeEventListener("click", closeContent);
    }
  }, [onlyCloseOnToggleClick]);

  return (
    <div className="relative z-40 ">
      <div
        onClick={() => {
          setOpen(!open);
        }}
        ref={toggleTrigger}
      >
        {toggle}
      </div>
      {open && content}
    </div>
  );
};

export default ToggleContent;
