import AboutInstallmentPlan1, {
  AboutInstallmentPlan1StorybookProps,
} from "../AboutInstallmentPlan/AboutInstallmentPlan1";
import AccoladesBanner1, {
  AccoladesBanner1StorybookProps,
} from "../AccoladesBanner/AccoladesBanner1";
import Banner1, { Banner1StorybookProps } from "../Banner/Banner1";
import Banner2, { Banner2StorybookProps } from "../Banner/Banner2";
import Banner3, { Banner3StorybookProps } from "../Banner/Banner3";
import BlackFridayHeader1, {
  BlackFridayHeader1StorybookProps,
} from "../BlackFridayHeader/BlackFridayHeader1";
import BlackFridayHeader2, {
  BlackFridayHeader2StorybookProps,
} from "../BlackFridayHeader/BlackFridayHeader2";
import BrandClientTestimonials1, {
  BrandClientTestimonials1StorybookProps,
} from "../BrandClientTestimonials/BrandClientTestimonials1";
import BrandClientTestimonialsNoImages1, {
  BrandClientTestimonialsNoImages1StorybookProps,
} from "../BrandClientTestimonialsNoImages/BrandClientTestimonialsNoImages1";
import BrandHeader1, {
  BrandHeader1StorybookProps,
} from "../BrandHeader/BrandHeader1";
import BrandHeader2, {
  BrandHeader2StorybookProps,
} from "../BrandHeader/BrandHeader2";
import BrandHeader3, {
  BrandHeader3StorybookProps,
} from "../BrandHeader/BrandHeader3";
import BrandHeaderBackgroundImage1, {
  BrandHeaderBackgroundImage1StorybookProps,
} from "../BrandHeaderBackgroundImage/BrandHeaderBackgroundImage1";
import BrandHeaderBackgroundImage2, {
  BrandHeaderBackgroundImage2StorybookProps,
} from "../BrandHeaderBackgroundImage/BrandHeaderBackgroundImage2";
import BrandHeaderBackgroundImage3, {
  BrandHeaderBackgroundImage3StorybookProps,
} from "../BrandHeaderBackgroundImage/BrandHeaderBackgroundImage3";
import BrandHeaderBackgroundImage4, {
  BrandHeaderBackgroundImage4StorybookProps,
} from "../BrandHeaderBackgroundImage/BrandHeaderBackgroundImage4";
import BrandHeaderRichTextAndImage1, {
  BrandHeaderRichTextAndImage1StorybookProps,
} from "../BrandHeaderRichTextAndImage/BrandHeaderRichTextAndImage1";
import BrandHeaderRichTextAndImage2, {
  BrandHeaderRichTextAndImage2StorybookProps,
} from "../BrandHeaderRichTextAndImage/BrandHeaderRichTextAndImage2";
import BrandNavigation1, {
  BrandNavigation1StorybookProps,
} from "../BrandNavigation/BrandNavigation1";
import BrandNavigation2, {
  BrandNavigation2StorybookProps,
} from "../BrandNavigation/BrandNavigation2";
import BrandNavigation3, {
  BrandNavigation3StorybookProps,
} from "../BrandNavigation/BrandNavigation3";
import CancelWithin30Days1, {
  CancelWithin30Days1StorybookProps,
} from "../CancelWithin30Days/CancelWithin30Days1";
import Caption1, { Caption1StorybookProps } from "../Caption/Caption1";
import CardWithHeaderAndBackgroundImage1, {
  CardWithHeaderAndBackgroundImage1StorybookProps,
} from "../CardWithHeaderAndBackgroundImage/CardWithHeaderAndBackgroundImage1";
import CardWithImageTitleAndText1, {
  CardWithImageTitleAndText1StorybookProps,
} from "../CardWithImageTitleAndText/CardWithImageTitleAndText1";
import CardWithImageTitleAndText2, {
  CardWithImageTitleAndText2StorybookProps,
} from "../CardWithImageTitleAndText/CardWithImageTitleAndText2";
import CardWithImageTitleAndText3, {
  CardWithImageTitleAndText3StorybookProps,
} from "../CardWithImageTitleAndText/CardWithImageTitleAndText3";
import CardWithImageTitleAndText4, {
  CardWithImageTitleAndText4StorybookProps,
} from "../CardWithImageTitleAndText/CardWithImageTitleAndText4";
import CCThankYouPage1, {
  CCThankYouPage1StorybookProps,
} from "../CCThankYouPage/CCThankYouPage1";
import ContentHighlight1, {
  ContentHighlight1StorybookProps,
} from "../ContentHighlight/ContentHighlight1";
import CountdownTimer1, {
  CountdownTimer1StorybookProps,
} from "../CountdownTimer/CountdownTimer1";
import CountdownTimer2, {
  CountdownTimer2StorybookProps,
} from "../CountdownTimer/CountdownTimer2";
import CountdownTimer3, {
  CountdownTimer3StorybookProps,
} from "../CountdownTimer/CountdownTimer3";
import CTA1, { CTA1StorybookProps } from "../CTA/CTA1";
import CTA2, { CTA2StorybookProps } from "../CTA/CTA2";
import CTABanner1, { CTABanner1StorybookProps } from "../CTABanner/CTABanner1";
import EndorserLogos1, {
  EndorserLogos1StorybookProps,
} from "../EndorserLogos/EndorserLogos1";
import EndorserLogos2, {
  EndorserLogos2StorybookProps,
} from "../EndorserLogos/EndorserLogos2";
import EndorserLogos3, {
  EndorserLogos3StorybookProps,
} from "../EndorserLogos/EndorserLogos3";
import EnrollOfferingChart1, {
  EnrollOfferingChart1StorybookProps,
} from "../EnrollOfferingChart/EnrollOfferingChart1";
import FAQ1, { FAQ1StorybookProps } from "../FAQ/FAQ1";
import FAQ2, { FAQ2StorybookProps } from "../FAQ/FAQ2";
import FAQ3, { FAQ3StorybookProps } from "../FAQ/FAQ3";
import Footer1, { Footer1StorybookProps } from "../Footer/Footer1";
import Footer2, { Footer2StorybookProps } from "../Footer/Footer2";
import HeaderLeftTextWithPictureAndCTA1, {
  HeaderLeftTextWithPictureAndCTA1StorybookProps,
} from "../HeaderLeftTextWithPictureAndCTA/HeaderLeftTextWithPictureAndCTA1";
import HeaderWithSideImageAndCTA1, {
  HeaderWithSideImageAndCTA1StorybookProps,
} from "../HeaderWithSideImageAndCTA/HeaderWithSideImageAndCTA1";
import HorizontalBullets1, {
  HorizontalBullets1StorybookProps,
} from "../HorizontalBullets/HorizontalBullets1";
import HorizontalRule1, {
  HorizontalRule1StorybookProps,
} from "../HorizontalRule/HorizontalRule1";
import HorizontalRule2, {
  HorizontalRule2StorybookProps,
} from "../HorizontalRule/HorizontalRule2";
import ImageNextToText1, {
  ImageNextToText1StorybookProps,
} from "../ImageNextToText/ImageNextToText1";
import ImageNextToText2, {
  ImageNextToText2StorybookProps,
} from "../ImageNextToText/ImageNextToText2";
import ImageWithTitleAndText1, {
  ImageWithTitleAndText1StorybookProps,
} from "../ImageWithTitleAndText/ImageWithTitleAndText1";
import ImageWithTitleAndText2, {
  ImageWithTitleAndText2StorybookProps,
} from "../ImageWithTitleAndText/ImageWithTitleAndText2";
import ImageWithTitleAndText3, {
  ImageWithTitleAndText3StorybookProps,
} from "../ImageWithTitleAndText/ImageWithTitleAndText3";
import ImageWithTitleAndText4, {
  ImageWithTitleAndText4StorybookProps,
} from "../ImageWithTitleAndText/ImageWithTitleAndText4";
import ImageWithTitleAndTextAndCTA1, {
  ImageWithTitleAndTextAndCTA1StorybookProps,
} from "../ImageWithTitleAndTextAndCTA/ImageWithTitleAndTextAndCTA1";
import LeftImageRightText1, {
  LeftImageRightText1StorybookProps,
} from "../LeftImageRightText/LeftImageRightText1";
import MarketingNavBar1, {
  MarketingNavBar1StorybookProps,
} from "../MarketingNavBar/MarketingNavBar1";
import NavbarCTA1, { NavbarCTA1StorybookProps } from "../NavbarCTA/NavbarCTA1";
import NavbarOnlyLogo1 from "../NavbarOnlyLogo/NavbarOnlyLogo1";
import OfferPreview1, {
  OfferPreview1StorybookProps,
} from "../OfferPreview/OfferPreview1";
import PageConfiguration1, {
  PageConfiguration1StorybookProps,
} from "./PageConfiguration/PageConfiguration1";
import Quote1, { Quote1StorybookProps } from "../Quote/Quote1";
import RichTextBlock1, {
  RichTextBlock1StorybookProps,
} from "../RichTextBlock/RichTextBlock1";
import SmallImageWithTitleAndSubtitle1, {
  SmallImageWithTitleAndSubtitle1StorybookProps,
} from "../SmallImageWithTitleAndSubtitle/SmallImageWithTitleAndSubtitle1";
import SmallImageWithTitleAndSubtitle2, {
  SmallImageWithTitleAndSubtitle2StorybookProps,
} from "../SmallImageWithTitleAndSubtitle/SmallImageWithTitleAndSubtitle2";
import Spacer1, { Spacer1StorybookProps } from "../Spacer/Spacer1";
import Spacer2, { Spacer2StorybookProps } from "../Spacer/Spacer2";
import Spacer3, { Spacer3StorybookProps } from "../Spacer/Spacer3";
import SplitPurchaseHeader1, {
  SplitPurchaseHeader1StorybookProps,
} from "../SplitPurchaseHeader/SplitPurchaseHeader1";
import SplitPurchaseHeader2, {
  SplitPurchaseHeader2StorybookProps,
} from "../SplitPurchaseHeader/SplitPurchaseHeader2";
import StatsWithImageAndCTA1, {
  StatsWithImageAndCTA1StorybookProps,
} from "../StatsWithImageAndCTA/StatsWithImageAndCTA1";
import SubscribeToEmails1, {
  SubscribeToEmails1StorybookProps,
} from "../SubscribeToEmails/SubscribeToEmails1";
import StickyFooter1, {
  StickyFooter1StorybookProps,
} from "../StickyFooter/StickyFooter1";
import StickyFooter2, {
  StickyFooter2StorybookProps,
} from "../StickyFooter/StickyFooter2";
import Testimonial1, {
  Testimonial1StorybookProps,
} from "../Testimonial/Testimonial1";
import Testimonial2, {
  Testimonial2StorybookProps,
} from "../Testimonial/Testimonial2";
import TextBlockWithTitle1, {
  TextBlockWithTitle1StorybookProps,
} from "../TextBlockWithTitle/TextBlockWithTitle1";
import Title1, { Title1StorybookProps } from "../Title/Title1";
import Title2, { Title2StorybookProps } from "../Title/Title2";
import TitleWithImages1, {
  TitleWithImages1StorybookProps,
} from "../TitleWithImages/TitleWithImages1";
import TitleWithSmallImagesAndText1, {
  TitleWithSmallImagesAndText1StorybookProps,
} from "../TitleWithSmallImagesAndText/TitleWithSmallImagesAndText1";
import TitleWithSmallImagesAndText2, {
  TitleWithSmallImagesAndText2StorybookProps,
} from "../TitleWithSmallImagesAndText/TitleWithSmallImagesAndText2";
import TitleWithSupportingPoints1, {
  TitleWithSupportingPoints1StorybookProps,
} from "../TitleWithSupportingPoints/TitleWithSupportingPoints1";
import Video1, { Video1StorybookProps } from "../Video/Video1";
import Video2, { Video2StorybookProps } from "../Video/Video2";
import Video3, { Video3StorybookProps } from "../Video/Video3";
import { CMSComponentExampleProps } from "./CMSComponentExampleProps";
import Children1, { Children1StorybookProps } from "./Children/Children1";
import Image1, { Image1StorybookProps } from "../Image/Image1";
import Webinar1 from "../Webinar/Webinar1";
import CCWebinarRegistrationModal1, {
  CCWebinarRegistrationModal1StorybookProps,
} from "../CCWebinarRegistrationModal/CCWebinarRegistrationModal1";
import RichTextBlock2, {
  RichTextBlock2StorybookProps,
} from "../RichTextBlock/RichTextBlock2";

export const componentVersionMap: {
  [componentName: string]: {
    [version: number]: {
      // The component truly can define any params it wants to consume. We might be able to
      // use a discriminating union in the future, but honestly, every component already declares
      // a pretty specific type for its props, so we can just rely on that at the component level.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component: (args: any) => JSX.Element | null;
      storybookProps: CMSComponentExampleProps<unknown>;
    };
  };
} = {
  AboutInstallmentPlan: {
    1: {
      component: AboutInstallmentPlan1,
      storybookProps: AboutInstallmentPlan1StorybookProps,
    },
  },
  AccoladesBanner: {
    1: {
      component: AccoladesBanner1,
      storybookProps: AccoladesBanner1StorybookProps,
    },
  },
  Banner: {
    1: {
      component: Banner1,
      storybookProps: Banner1StorybookProps,
    },
    2: {
      component: Banner2,
      storybookProps: Banner2StorybookProps,
    },
    3: {
      component: Banner3,
      storybookProps: Banner3StorybookProps,
    },
  },
  BlackFridayHeader: {
    1: {
      component: BlackFridayHeader1,
      storybookProps: BlackFridayHeader1StorybookProps,
    },
    2: {
      component: BlackFridayHeader2,
      storybookProps: BlackFridayHeader2StorybookProps,
    },
  },
  BrandClientTestimonials: {
    1: {
      component: BrandClientTestimonials1,
      storybookProps: BrandClientTestimonials1StorybookProps,
    },
  },
  BrandClientTestimonialsNoImages: {
    1: {
      component: BrandClientTestimonialsNoImages1,
      storybookProps: BrandClientTestimonialsNoImages1StorybookProps,
    },
  },
  BrandHeader: {
    1: {
      component: BrandHeader1,
      storybookProps: BrandHeader1StorybookProps,
    },
    2: {
      component: BrandHeader2,
      storybookProps: BrandHeader2StorybookProps,
    },
    3: {
      component: BrandHeader3,
      storybookProps: BrandHeader3StorybookProps,
    },
  },
  BrandHeaderBackgroundImage: {
    1: {
      component: BrandHeaderBackgroundImage1,
      storybookProps: BrandHeaderBackgroundImage1StorybookProps,
    },
    2: {
      component: BrandHeaderBackgroundImage2,
      storybookProps: BrandHeaderBackgroundImage2StorybookProps,
    },
    3: {
      component: BrandHeaderBackgroundImage3,
      storybookProps: BrandHeaderBackgroundImage3StorybookProps,
    },
    4: {
      component: BrandHeaderBackgroundImage4,
      storybookProps: BrandHeaderBackgroundImage4StorybookProps,
    },
  },
  BrandHeaderRichTextAndImage: {
    1: {
      component: BrandHeaderRichTextAndImage1,
      storybookProps: BrandHeaderRichTextAndImage1StorybookProps,
    },
    2: {
      component: BrandHeaderRichTextAndImage2,
      storybookProps: BrandHeaderRichTextAndImage2StorybookProps,
    },
  },
  BrandNavigation: {
    1: {
      component: BrandNavigation1,
      storybookProps: BrandNavigation1StorybookProps,
    },
    2: {
      component: BrandNavigation2,
      storybookProps: BrandNavigation2StorybookProps,
    },
    3: {
      component: BrandNavigation3,
      storybookProps: BrandNavigation3StorybookProps,
    },
  },
  CancelWithin30Days: {
    1: {
      component: CancelWithin30Days1,
      storybookProps: CancelWithin30Days1StorybookProps,
    },
  },
  Caption: {
    1: {
      component: Caption1,
      storybookProps: Caption1StorybookProps,
    },
  },
  CardWithHeaderAndBackgroundImage: {
    1: {
      component: CardWithHeaderAndBackgroundImage1,
      storybookProps: CardWithHeaderAndBackgroundImage1StorybookProps,
    },
  },
  CardWithImageTitleAndText: {
    1: {
      component: CardWithImageTitleAndText1,
      storybookProps: CardWithImageTitleAndText1StorybookProps,
    },
    2: {
      component: CardWithImageTitleAndText2,
      storybookProps: CardWithImageTitleAndText2StorybookProps,
    },
    3: {
      component: CardWithImageTitleAndText3,
      storybookProps: CardWithImageTitleAndText3StorybookProps,
    },
    4: {
      component: CardWithImageTitleAndText4,
      storybookProps: CardWithImageTitleAndText4StorybookProps,
    },
  },
  CCThankYouPage: {
    1: {
      component: CCThankYouPage1,
      storybookProps: CCThankYouPage1StorybookProps,
    },
  },
  CCWebinarRegistrationModal: {
    1: {
      component: CCWebinarRegistrationModal1,
      storybookProps: CCWebinarRegistrationModal1StorybookProps,
    },
  },
  Children: {
    1: {
      component: Children1,
      storybookProps: Children1StorybookProps,
    },
  },
  ColorOverride: {
    1: {
      component: () => {
        return null;
      },
      storybookProps: {
        brand: {},
      },
    },
  },
  ContentHighlight: {
    1: {
      component: ContentHighlight1,
      storybookProps: ContentHighlight1StorybookProps,
    },
  },
  CountdownTimer: {
    1: {
      component: CountdownTimer1,
      storybookProps: CountdownTimer1StorybookProps,
    },
    2: {
      component: CountdownTimer2,
      storybookProps: CountdownTimer2StorybookProps,
    },
    3: {
      component: CountdownTimer3,
      storybookProps: CountdownTimer3StorybookProps,
    },
  },
  CTA: {
    1: {
      component: CTA1,
      storybookProps: CTA1StorybookProps,
    },
    2: {
      component: CTA2,
      storybookProps: CTA2StorybookProps,
    },
  },
  CTABanner: {
    1: {
      component: CTABanner1,
      storybookProps: CTABanner1StorybookProps,
    },
  },
  EndorserLogos: {
    1: {
      component: EndorserLogos1,
      storybookProps: EndorserLogos1StorybookProps,
    },
    2: {
      component: EndorserLogos2,
      storybookProps: EndorserLogos2StorybookProps,
    },
    3: {
      component: EndorserLogos3,
      storybookProps: EndorserLogos3StorybookProps,
    },
  },
  EnrollOfferingChart: {
    1: {
      component: EnrollOfferingChart1,
      storybookProps: EnrollOfferingChart1StorybookProps,
    },
  },
  FAQ: {
    1: {
      component: FAQ1,
      storybookProps: FAQ1StorybookProps,
    },
    2: {
      component: FAQ2,
      storybookProps: FAQ2StorybookProps,
    },
    3: {
      component: FAQ3,
      storybookProps: FAQ3StorybookProps,
    },
  },
  Footer: {
    1: {
      component: Footer1,
      storybookProps: Footer1StorybookProps,
    },
    2: {
      component: Footer2,
      storybookProps: Footer2StorybookProps,
    },
  },
  HeaderLeftTextWithPictureAndCTA: {
    1: {
      component: HeaderLeftTextWithPictureAndCTA1,
      storybookProps: HeaderLeftTextWithPictureAndCTA1StorybookProps,
    },
  },
  HeaderWithSideImageAndCTA: {
    1: {
      component: HeaderWithSideImageAndCTA1,
      storybookProps: HeaderWithSideImageAndCTA1StorybookProps,
    },
  },
  HorizontalBullets: {
    1: {
      component: HorizontalBullets1,
      storybookProps: HorizontalBullets1StorybookProps,
    },
  },
  HorizontalRule: {
    1: {
      component: HorizontalRule1,
      storybookProps: HorizontalRule1StorybookProps,
    },
    2: {
      component: HorizontalRule2,
      storybookProps: HorizontalRule2StorybookProps,
    },
  },
  Image: {
    1: {
      component: Image1,
      storybookProps: Image1StorybookProps,
    },
  },
  ImageNextToText: {
    1: {
      component: ImageNextToText1,
      storybookProps: ImageNextToText1StorybookProps,
    },
    2: {
      component: ImageNextToText2,
      storybookProps: ImageNextToText2StorybookProps,
    },
  },
  ImageWithTitleAndText: {
    1: {
      component: ImageWithTitleAndText1,
      storybookProps: ImageWithTitleAndText1StorybookProps,
    },
    2: {
      component: ImageWithTitleAndText2,
      storybookProps: ImageWithTitleAndText2StorybookProps,
    },
    3: {
      component: ImageWithTitleAndText3,
      storybookProps: ImageWithTitleAndText3StorybookProps,
    },
    4: {
      component: ImageWithTitleAndText4,
      storybookProps: ImageWithTitleAndText4StorybookProps,
    },
  },
  ImageWithTitleAndTextAndCTA: {
    1: {
      component: ImageWithTitleAndTextAndCTA1,
      storybookProps: ImageWithTitleAndTextAndCTA1StorybookProps,
    },
  },
  LeftImageRightText: {
    1: {
      component: LeftImageRightText1,
      storybookProps: LeftImageRightText1StorybookProps,
    },
  },
  MarketingNavBar: {
    1: {
      component: MarketingNavBar1,
      storybookProps: MarketingNavBar1StorybookProps,
    },
  },
  NavbarCTA: {
    1: {
      component: NavbarCTA1,
      storybookProps: NavbarCTA1StorybookProps,
    },
  },
  NavbarOnlyLogo: {
    1: {
      component: NavbarOnlyLogo1,
      storybookProps: NavbarCTA1StorybookProps,
    },
  },
  OfferPreview: {
    1: {
      component: OfferPreview1,
      storybookProps: OfferPreview1StorybookProps,
    },
  },
  PageConfiguration: {
    1: {
      component: PageConfiguration1,
      storybookProps: PageConfiguration1StorybookProps,
    },
  },
  Quote: {
    1: {
      component: Quote1,
      storybookProps: Quote1StorybookProps,
    },
  },
  RichTextBlock: {
    1: {
      component: RichTextBlock1,
      storybookProps: RichTextBlock1StorybookProps,
    },
    2: {
      component: RichTextBlock2,
      storybookProps: RichTextBlock2StorybookProps,
    },
  },
  SmallImageWithTitleAndSubtitle: {
    1: {
      component: SmallImageWithTitleAndSubtitle1,
      storybookProps: SmallImageWithTitleAndSubtitle1StorybookProps,
    },
    2: {
      component: SmallImageWithTitleAndSubtitle2,
      storybookProps: SmallImageWithTitleAndSubtitle2StorybookProps,
    },
  },
  Spacer: {
    1: {
      component: Spacer1,
      storybookProps: Spacer1StorybookProps,
    },
    2: {
      component: Spacer2,
      storybookProps: Spacer2StorybookProps,
    },
    3: {
      component: Spacer3,
      storybookProps: Spacer3StorybookProps,
    },
  },
  SplitPurchaseHeader: {
    1: {
      component: SplitPurchaseHeader1,
      storybookProps: SplitPurchaseHeader1StorybookProps,
    },
    2: {
      component: SplitPurchaseHeader2,
      storybookProps: SplitPurchaseHeader2StorybookProps,
    },
  },
  StatsWithImageAndCta: {
    1: {
      component: StatsWithImageAndCTA1,
      storybookProps: StatsWithImageAndCTA1StorybookProps,
    },
  },
  SubscribeToEmails: {
    1: {
      component: SubscribeToEmails1,
      storybookProps: SubscribeToEmails1StorybookProps,
    },
  },
  StickyFooter: {
    1: {
      component: StickyFooter1,
      storybookProps: StickyFooter1StorybookProps,
    },
    2: {
      component: StickyFooter2,
      storybookProps: StickyFooter2StorybookProps,
    },
  },
  Testimonial: {
    1: {
      component: Testimonial1,
      storybookProps: Testimonial1StorybookProps,
    },
    2: {
      component: Testimonial2,
      storybookProps: Testimonial2StorybookProps,
    },
  },
  TextBlockWithTitle: {
    1: {
      component: TextBlockWithTitle1,
      storybookProps: TextBlockWithTitle1StorybookProps,
    },
  },
  Title: {
    1: {
      component: Title1,
      storybookProps: Title1StorybookProps,
    },
    2: {
      component: Title2,
      storybookProps: Title2StorybookProps,
    },
  },
  TitleWithImages: {
    1: {
      component: TitleWithImages1,
      storybookProps: TitleWithImages1StorybookProps,
    },
  },
  TitleWithSmallImagesAndText: {
    1: {
      component: TitleWithSmallImagesAndText1,
      storybookProps: TitleWithSmallImagesAndText1StorybookProps,
    },
    2: {
      component: TitleWithSmallImagesAndText2,
      storybookProps: TitleWithSmallImagesAndText2StorybookProps,
    },
  },
  TitleWithSupportingPoints: {
    1: {
      component: TitleWithSupportingPoints1,
      storybookProps: TitleWithSupportingPoints1StorybookProps,
    },
  },
  Video: {
    1: {
      component: Video1,
      storybookProps: Video1StorybookProps,
    },
    2: {
      component: Video2,
      storybookProps: Video2StorybookProps,
    },
    3: {
      component: Video3,
      storybookProps: Video3StorybookProps,
    },
  },
  Webinar: {
    1: {
      component: Webinar1,
      storybookProps: CCWebinarRegistrationModal1StorybookProps,
    },
  },
  WebinarRegistrationModal: {
    1: {
      component: CCWebinarRegistrationModal1,
      storybookProps: CCWebinarRegistrationModal1StorybookProps,
    },
  },
};
