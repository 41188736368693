import { useClickHandler } from "../cms/PageContext";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import CMSButton from "../SubComponents/CMSButton";
import {
  CMSText,
  HasOnClick,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { TextRender, getRawText } from "../../utils/cms/renderableResources";

const CTA1 = ({
  buttonText,
  component,
}: InjectableComponentProps<CTA1Props>) => {
  const clickHandler = useClickHandler(component, "buttonText");

  return (
    <div data-component="CTA1" className="flex justify-center my-8">
      <CMSButton
        buttonText={<TextRender resource={buttonText} />}
        onClick={clickHandler}
        tracking={{
          expectedResult: "cta button clicked",
          innerText: getRawText(buttonText),
          resourceId: buttonText.id,
          type: "button",
        }}
      />
    </div>
  );
};

type CTA1Props = {
  buttonText: HasOnClick<CMSText>;
};

export const CTA1StorybookProps: CMSComponentExampleProps<CTA1Props> = {
  buttonText: {
    type: "text",
    value: "Click me!",
    metadata: {
      onClick: "consoleLog",
    },
  },
};

export default CTA1;
