import Head from "next/head";
import { useEffect } from "react";
import {
  getImageUrl,
  getRawText,
  ImageRender,
} from "../../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../CMSComponentExampleProps";
import {
  CMSImageResource,
  CMSRGBColor,
  CMSText,
  InjectableComponentProps,
} from "../types/cmsTypes";
import { useColorOverrides } from "./useColorOverrides";
import { placeholderImageUrlWithText } from "stories/helpers";

const PageConfiguration1 = ({
  brandConfig,
  colorOverrides,
  experimentId,
  favicon,
  isDevelopmentMode,
  metaDescription,
  title,
  variantKeyName,
  primaryDisplayImage,
}: InjectableComponentProps<PageConfigurationProps>) => {
  useEffect(() => {
    // wrap in a useEffect so it doesn't try to execute on the server
    if (!isDevelopmentMode) {
      window.cc = window.cc || {};
      window.cc.variant_key_name = getRawText(variantKeyName);
      window.cc.experiment_id = getRawText(experimentId);
    }
  }, [variantKeyName, experimentId, isDevelopmentMode]);

  const [developmentModeColor, productionModeColor] = useColorOverrides(
    colorOverrides,
    brandConfig
  );

  const headContent = (
    <Head>
      <title>{getRawText(title)}</title>
      <link rel="icon" href={getImageUrl(favicon)} />
      {/* https://developers.google.com/search/docs/crawling-indexing/special-tags */}
      {/* <meta name="keywords" content={getRawText(keywords)} /> */}
      <meta name="description" content={getRawText(metaDescription)} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="UTF-8" />
    </Head>
  );

  if (isDevelopmentMode) {
    return (
      <div className="mb-4 p-4">
        {headContent}
        <div>
          <h3 className="text-center my-2">Page Configuration</h3>
        </div>

        <div>ExperimentId: {getRawText(experimentId) || "none set"}</div>
        <div>Variant: {getRawText(variantKeyName) || "none set"}</div>

        {primaryDisplayImage && (
          <div className="w-16 overflow-hidden">
            Primary Image:
            <ImageRender resource={primaryDisplayImage} />
          </div>
        )}

        {developmentModeColor}
      </div>
    );
  }

  return (
    <>
      {headContent}
      {productionModeColor}
    </>
  );
};

type PageConfigurationProps = {
  colorOverrides: CMSRGBColor;
  variantKeyName: CMSText;
  experimentId: CMSText;
  title: CMSText;
  favicon: CMSImageResource;
  keywords: CMSText;
  metaDescription: CMSText;
  primaryDisplayImage: CMSImageResource;
};

export const PageConfiguration1StorybookProps: CMSComponentExampleProps<PageConfigurationProps> =
  {
    colorOverrides: {
      type: "rgb_color",
      value: {
        primaryRgbColor: "255 0 0",
        secondaryRgbColor: "0 255 0",
        tertiaryRgbColor: "0 0 255",
        quaternaryRgbColor: "255 255 0",
      },
    },
    variantKeyName: {
      type: "text",
      value: "test-variant",
    },
    experimentId: {
      type: "text",
      value: "experiment-test-1",
    },
    title: {
      type: "text",
      value: "Page Title",
    },
    favicon: {
      type: "image_resource",
      value: { url: placeholderImageUrlWithText(100, 100, "favicon image") },
    },
    primaryDisplayImage: {
      type: "image_resource",
      value: {
        url: placeholderImageUrlWithText(400, 400, "seo display image"),
      },
    },
    keywords: {
      type: "text",
      value: "keywords, for, seo, purposes",
    },
    metaDescription: {
      type: "text",
      value: "meta description for seo purposes",
    },
  };

export default PageConfiguration1;
