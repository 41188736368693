import { useRouter } from "next/router";
import { getRawText } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import TrunkLink from "../TrunkLink";

const MarketingNavBar1 = ({
  pages,
  brandAbbreviation,
}: InjectableComponentProps<MarketingNavBar1Props>) => {
  const router = useRouter();

  const isCurrent = (page: string) => {
    return router.pathname.includes(page);
  };

  return (
    <div className="space-x-4">
      NAVBAR:
      {(pages?.resources || []).map((page) => {
        const pageAsString = getRawText(page);
        return (
          <TrunkLink
            brandAbbreviation={brandAbbreviation}
            href={pageAsString}
            key={pageAsString}
          >
            <a className={isCurrent(pageAsString) ? "text-blue-300" : ""}>
              {pageAsString}
            </a>
          </TrunkLink>
        );
      })}
    </div>
  );
};

type MarketingNavBar1Props = {
  pages: CMSCollection<CMSText>;
};

export const MarketingNavBar1StorybookProps: CMSComponentExampleProps<MarketingNavBar1Props> =
  {
    pages: {
      type: "text",
      retrieveAll: true,
      values: ["home", "about", "contact"],
    },
  };

export default MarketingNavBar1;
