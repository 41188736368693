import { storybookImageResource } from "stories/helpers/storybookImageResource";
import {
  ImageRender,
  RichTextRender,
} from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSImageTitleAndText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const TitleWithSmallImagesAndText2 = ({
  imageTitleAndText,
}: InjectableComponentProps<TitleWithSmallImagesAndText2Props>) => {
  return (
    <div className="flex flex-col justify-center sm:flex-row gap-4 lg:gap-32 m-auto">
      {imageTitleAndText.resources.map((entry, i) => (
        <div
          key={`imageTitleAndText_${i}`}
          className="flex flex-col w-32 gap-y-4 m-auto sm:m-8"
        >
          <ImageRender
            resource={entry.image}
            imgClassName="order-1 rounded-full h-32 w-32 object-cover"
          />
          <div className="">
            <h3 className="text-center text-xl order-2">
              <RichTextRender resource={entry.title} />
            </h3>
            <div className="text-center order-3">
              <RichTextRender resource={entry.text} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

type TitleWithSmallImagesAndText2Props = {
  imageTitleAndText: CMSCollection<CMSImageTitleAndText>;
};

export const TitleWithSmallImagesAndText2StorybookProps: CMSComponentExampleProps<TitleWithSmallImagesAndText2Props> =
  {
    imageTitleAndText: {
      defaultTags: [{ type: "image_title_and_text" }],
      type: "imageTitleAndText",
      retrieveAll: true,
      values: [
        {
          image: storybookImageResource(120, 120, "headshot"),
          title: "John Smith",
          text: "Owner",
        },
        {
          image: storybookImageResource(120, 120, "headshot"),
          title: "John Smith",
          text: "Owner",
        },
        {
          image: storybookImageResource(120, 120, "headshot"),
          title: "John Smith",
          text: "Owner",
        },
        {
          image: storybookImageResource(120, 120, "headshot"),
          title: "John Smith",
          text: "Owner",
        },
      ],
    },
  };

export default TitleWithSmallImagesAndText2;
