/* eslint-disable @next/next/no-html-link-for-pages */
import {
  CMSImageResource,
  CMSText,
  HasOnClick,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { getRawText, ImageRender } from "../../utils/cms/renderableResources";
import { placeholderImageUrlWithText } from "stories/helpers";
import trackEvent from "../trackEvent";
import CMSButton from "../SubComponents/CMSButton";
import { useClickHandler } from "../cms/PageContext";

const BrandNavigation3 = ({
  component,
  cta,
  logo,
}: InjectableComponentProps<BrandNavigationProps>) => {
  const clickHandler = useClickHandler(component, "cta");

  const trackLinkClick = (): void => {
    trackEvent(
      {
        action: "click",
        meta: {
          destination: "/",
          type: "link",
        },
      },
      "beacon"
    );
  };

  return (
    <>
      <div className="hidden sm:flex xl:flex-row justify-around items-center p-2 bg-white">
        {/* We are using an anchor tag here because navigating to `/` causes an issue with Next/CloudFront */}
        <a
          href="/"
          className="w-48 -mt-16 -mb-24 z-10"
          onClick={trackLinkClick}
        >
          <ImageRender resource={logo} />
        </a>
        <nav className="flex flex-row items-end">
          <CMSButton
            buttonBgClass="bg-tertiary"
            buttonText={getRawText(cta)}
            onClick={clickHandler}
            tracking={{
              expectedResult: "desktop nav cta button clicked",
              innerText: getRawText(cta),
              resourceId: cta.id,
              type: "button",
            }}
          />
        </nav>
      </div>
      <nav
        className="flex sm:hidden flex-row justify-between 
          z-50 w-full h-14 fixed top-0 left-0 side-nav-step 
          bg-white mobile-bottom-border-light p-2"
      >
        {/* We are using an anchor tag here because navigating to `/` causes an issue with Next/CloudFront */}
        <a href="/" className="w-40 -mt-6 -mb-12" onClick={trackLinkClick}>
          <ImageRender resource={logo} />
        </a>
        <div className="flex flex-row">
          <CMSButton
            buttonBgClass="bg-tertiary"
            buttonClass="p-0 px-2"
            buttonText={getRawText(cta)}
            onClick={clickHandler}
            textClass="text-[14px] leading-4"
            tracking={{
              expectedResult: "mobile nav cta button clicked",
              innerText: getRawText(cta),
              resourceId: cta.id,
              type: "button",
            }}
          />
        </div>
      </nav>
      {/* spacer so that content on mobile doesn't get cut off */}
      <div className="h-14 sm:hidden" />
    </>
  );
};

type BrandNavigationProps = {
  logo: CMSImageResource;
  cta: HasOnClick<CMSText>;
};

export const BrandNavigation3StorybookProps: CMSComponentExampleProps<BrandNavigationProps> =
  {
    logo: {
      type: "image_resource",
      value: placeholderImageUrlWithText(200, 200, "logo"),
      defaultTags: [{ type: "brand_logo" }],
    },
    cta: {
      defaultTags: [{ type: "enroll" }],
      type: "text",
      title: "ENROLL IN FREE WEBINAR",
      metadata: {
        onClick: "showModal",
      },
    },
  };

export default BrandNavigation3;
