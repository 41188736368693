import { placeholderImageUrlWithText } from "stories/helpers";
import {
  ImageRender,
  RichTextRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSImageResource,
  CMSRichText,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const ImageNextToText1 = ({
  image,
  title,
  text,
}: InjectableComponentProps<ImageNextToText1Props>) => {
  return (
    <div className="flex flex-col md:flex-row m-auto gap-x-12 justify-center p-4 xl:w-4/5">
      <div className="flex flex-col md:block xl:w-3/5 max-h-[50vh] my-auto">
        <ImageRender
          resource={image}
          imgClassName="object-scale-down max-h-[50vh]"
        />
      </div>
      <div className="flex flex-col gap-y-2 text-center md:text-left md:my-auto md:w-3/4 lg:w-1/2">
        <TextRender
          resource={title}
          className="font-bold text-gray-700 text-3xl pt-4"
        />
        <RichTextRender resource={text} />
      </div>
    </div>
  );
};

type ImageNextToText1Props = {
  image: CMSImageResource;
  title: CMSText;
  text: CMSRichText;
};

export const ImageNextToText1StorybookProps: CMSComponentExampleProps<ImageNextToText1Props> =
  {
    image: {
      defaultTags: [{ type: "value_prop_image" }],
      type: "image_resource",
      value: placeholderImageUrlWithText(100, 80, "logo"),
      metadata: {
        alt: "teacher profile",
      },
    },
    title: {
      defaultTags: [{ type: "section_title" }],
      type: "text",
      value: "Meet your teacher, Scott Houston",
    },
    text: {
      defaultTags: [{ type: "value_prop_content" }],
      type: "richtext",
      value: "lorem ipsum ~300 characters",
    },
  };

export default ImageNextToText1;
