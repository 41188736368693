export default function getCountdownDateToNHoursAfterFirstUserVisit(
  NHours: string = ""
) {
  const now = new Date();

  // check if user has visited before
  let firstVisitDate = localStorage.getItem("firstVistDate");
  if (!firstVisitDate) {
    firstVisitDate = now.toString();
    localStorage.setItem("firstVistDate", now.toString());
  }

  // add NHours to user's first visit date
  const hours = parseInt(NHours);
  const hoursInMilliseconds = hours * 3600 * 1000;
  const offerExpiresAt = new Date(
    Date.parse(firstVisitDate) + hoursInMilliseconds
  );

  // have NHours passed since first visit?
  if (Date.parse(now.toString()) > Date.parse(offerExpiresAt.toString())) {
    // the offer has expired and we can hide the countdown by setting the countdownDate to null
    return null;
  } else {
    // the offer is still valid and the countdown continues
    return offerExpiresAt;
  }
}
