import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSText,
  CMSRichText,
  InjectableComponentProps,
  CMSLink,
} from "../cms/types/cmsTypes";
import {
  TextRender,
  RichTextRender,
} from "../../utils/cms/renderableResources";
import TrunkLink from "../TrunkLink";

const CTA2 = ({
  title,
  brandAbbreviation,
  subtitle,
  cta,
}: InjectableComponentProps<CTA2Props>) => {
  return (
    <div className="flex flex-col justify-center text-center">
      {title && (
        <h2 className="flex justify-center text-3xl font-bold text-primary py-6">
          <TextRender resource={title} />
        </h2>
      )}
      {subtitle && (
        <h3 className="flex justify-center pb-6 text-center">
          <RichTextRender resource={subtitle} />
        </h3>
      )}
      <TrunkLink brandAbbreviation={brandAbbreviation} href={cta.href}>
        <button className="rounded bg-quaternary-darker justify-center text-bg-quaternary hover:bg-quaternary px-3 py-2 mb-4">
          {cta.title}
        </button>
      </TrunkLink>
    </div>
  );
};

type CTA2Props = {
  title: CMSText;
  subtitle: CMSRichText;
  cta: CMSLink;
};

export const CTA2StorybookProps: CMSComponentExampleProps<CTA2Props> = {
  title: {
    type: "text",
    value: "Let's be friends!",
  },
  brand: {
    id: "test-brand",
  },
  subtitle: {
    type: "richtext",
    value: "Join my piano journey in my blog weekly series",
  },
  cta: {
    type: "link",
    title: "Subscribe",
    href: "/subscribe",
  },
};

export default CTA2;
