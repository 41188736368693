import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";

const Spacer2 = () => {
  return <div className="h-8 sm:h-14"></div>;
};

type Spacer2Props = {};

export const Spacer2StorybookProps: CMSComponentExampleProps<Spacer2Props> = {};

export default Spacer2;
