import React from "react";
import TrunkLink from "../TrunkLink";

const ToggleContentLink = ({
  brandAbbreviation,
  label,
  route,
}: {
  brandAbbreviation: string;
  label: string;
  route: string;
}) => {
  const active = location.pathname === route;

  return (
    <TrunkLink href={route} brandAbbreviation={brandAbbreviation}>
      <span
        className={`
        ${active ? "underline bg-brand-primary" : "text-brand-primary"} 
        flex flex-nowrap p-4 font-bold hover:text-brand-tertiary no-underline
        mobile-bottom-border-light`}
      >
        {label}
      </span>
    </TrunkLink>
  );
};

export default ToggleContentLink;
