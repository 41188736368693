import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSQuestionAndAnswer,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  RichTextRender,
  TextRender,
} from "../../utils/cms/renderableResources";

const FAQ3 = ({
  questionAndAnswer,
  sectionTitle,
}: InjectableComponentProps<FAQ3Props>) => {
  return (
    <div className="text-center flex flex-col mx-auto w-full sm:w-2/3">
      <h3 className="text-2xl">
        <TextRender resource={sectionTitle} />
      </h3>

      <div className="space-y-4 mt-16 flex flex-col w-full">
        {questionAndAnswer.resources.map((entry, index) => {
          return (
            <div
              key={index}
              className="text-left p-4 odd:bg-secondary odd:bg-opacity-50 w-full"
            >
              <p className="font-bold my-2 italic max-w-prose m-auto">
                <i>
                  <TextRender resource={entry.question} />
                </i>
              </p>
              <div className="max-w-prose m-auto">
                <RichTextRender resource={entry.answer} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

type FAQ3Props = {
  questionAndAnswer: CMSCollection<CMSQuestionAndAnswer>;
  sectionTitle: CMSText;
};

export const FAQ3StorybookProps: CMSComponentExampleProps<FAQ3Props> = {
  questionAndAnswer: {
    defaultTags: [{ type: "frequently_asked_questions" }],
    retrieveAll: true,
    exampleQuantity: 3,
    type: "questionAndAnswer",
    values: [
      {
        value: {
          question: "What is this?",
          answer: "It is a thing.",
        },
      },
      {
        value: {
          question: "What is this?",
          answer: "It is a thing.",
        },
      },
      {
        value: {
          question: "What is this?",
          answer: "It is a thing.",
        },
      },
    ],
  },
  sectionTitle: {
    defaultTags: [{ type: "section_title" }],
    type: "text",
    value: "FAQs & Giveaway Rules",
  },
};

export default FAQ3;
