import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { CMSImage } from "../CMSImage";
import {
  CMSImageResource,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { placeholderImageUrlWithText } from "stories/helpers";

const Image1 = ({ image }: InjectableComponentProps<Image1Props>) => {
  return (
    <div className="max-w-prose mx-auto" data-attribute="CMSImage1">
      <CMSImage className="" image={image} />
    </div>
  );
};

type Image1Props = {
  image: CMSImageResource;
};

export const Image1StorybookProps: CMSComponentExampleProps<Image1Props> = {
  image: {
    type: "image_resource",
    metadata: {
      value: {
        image: placeholderImageUrlWithText(200, 200, "Example Image"),
      },
    },
    description: "This is the primary focus of the component",
  },
};

export default Image1;
