import { RichTextRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSRichText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const HorizontalBullets1 = ({
  bullets,
}: InjectableComponentProps<HorizontalBullets1Props>) => {
  return (
    <div className="flex justify-center my-4 flex-wrap">
      {bullets?.resources.map((bullet, index) => (
        <span key={`${bullet} ${index}`} className="flex">
          <RichTextRender resource={bullet} element="span" />
          <span
            className={
              index === bullets.resources.length - 1 ? "hidden" : "mx-1 md:mx-2"
            }
          >
            •
          </span>
        </span>
      ))}
    </div>
  );
};

type HorizontalBullets1Props = {
  bullets: CMSCollection<CMSRichText>;
};

export const HorizontalBullets1StorybookProps: CMSComponentExampleProps<HorizontalBullets1Props> =
  {
    bullets: {
      type: "richtext",
      retrieveAll: true,
      values: [
        "Bullet 1",
        "<b>Bullet 2</b>",
        "<i>Bullet 3</i>",
        "Bullet 4",
        "<b>Bullet 5</b>",
        "<i>Bullet 6</i>",
      ],
    },
  };

export default HorizontalBullets1;
