import { VideoRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSVideoResource,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const Video3 = ({
  video,
  brandAbbreviation,
}: InjectableComponentProps<Video3Props>) => {
  return (
    <div className={`${video ? "py-12" : ""}`}>
      <div className="relative flex w-full lg:w-[50vw] m-auto">
        <VideoRender resource={video} brandAbbreviation={brandAbbreviation} />
      </div>
    </div>
  );
};

type Video3Props = {
  video: CMSVideoResource;
};

export const Video3StorybookProps: CMSComponentExampleProps<Video3Props> = {
  video: {
    defaultTags: [{ type: "video" }],
    type: "video_resource",
    value: {
      id: "59a5e568-cb7d-489e-8bdc-8ebdb83ad041",
      s3_path:
        "/nma/resources/59a5e568-cb7d-489e-8bdc-8ebdb83ad041/filename-to-remove",
      metadata: {
        duration: 1112,
        filename: "",
        subtitle: "",
        original_resolution: "1080p",
        available_resolutions: ["1080p", "720p", "540p"],
      },
    },
  },
};
export default Video3;
