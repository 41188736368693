import { placeholderImageUrlWithText } from "stories/helpers";
import { ImageRender, TextRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSImageResource,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import RibbonBanner from "../SubComponents/RibbonBanner";

const BlackFridayHeader2 = ({
  bundleName,
  brand,
  teacherName,
  logo,
}: InjectableComponentProps<BlackFridayHeader2Props>) => {
  return (
    <div className="flex flex-col">
      <RibbonBanner
        title="Black Friday"
        subtitle={brand.name}
        logo={
          <ImageRender
            resource={logo}
            className="absolute bottom-4 right-4 z-30 bg-primary rounded-full w-14 h-14 -m-1"
          />
        }
      />
      <div className="flex flex-col pt-1 gap-y-2 text-center w-full md:w-2/3 xl:w-1/2 mx-auto">
        <TextRender
          className="text-3xl lg:text-4xl font-bold text-primary uppercase pt-2"
          resource={bundleName}
        />
        <h4 className="font-light bg-secondary text-bg-secondary rounded-full w-2/5 mx-auto text-sm lg:text-lg py-1">
          With <TextRender resource={teacherName} />
        </h4>
      </div>
    </div>
  );
};

type BlackFridayHeader2Props = {
  bundleName: CMSText;
  teacherName: CMSText;
  logo: CMSImageResource;
};

export const BlackFridayHeader2StorybookProps: CMSComponentExampleProps<BlackFridayHeader2Props> =
  {
    bundleName: {
      type: "text",
      value: "The black friday bundle",
    },
    brand: {
      name: "Piano in a Flash",
    },
    teacherName: {
      type: "text",
      metadata: {
        value: "Scott Houston",
      },
    },
    logo: {
      type: "image_resource",
      metadata: {
        value: placeholderImageUrlWithText(100, 100, "logo"),
      },
    },
  };

export default BlackFridayHeader2;
