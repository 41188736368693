import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSImageResource,
  InjectableComponentProps,
  CMSText,
  CMSCollection,
} from "../cms/types/cmsTypes";
import { TextRender, ImageRender } from "../../utils/cms/renderableResources";
import { storybookImageResource } from "stories/helpers/storybookImageResource";

const TitleWithImages1 = ({
  title,
  images,
}: InjectableComponentProps<TitleWithImages1Props>) => {
  return (
    <div className="bg-gray-100">
      <h2 className="text-center font-bold text-lg pt-8">
        <TextRender resource={title} />
      </h2>
      <div className="flex flex-col md:flex-row pt-10 pb-20 gap-8 w-2/3 lg:w-1/2 mx-auto">
        {images.resources.map((image) => (
          <ImageRender
            key={JSON.stringify(image)}
            resource={image}
            className="w-2/3 lg:w-1/2 mx-auto"
          />
        ))}
      </div>
    </div>
  );
};

type TitleWithImages1Props = {
  title: CMSText;
  images: CMSCollection<CMSImageResource>;
};

export const TitleWithImages1StorybookProps: CMSComponentExampleProps<TitleWithImages1Props> =
  {
    title: {
      type: "text",
      value: "This is an example title",
    },
    images: {
      type: "image_resource",
      values: [
        {
          image: storybookImageResource(100, 80, "logo"),
        },
        {
          image: storybookImageResource(100, 80, "logo"),
        },
        {
          image: storybookImageResource(100, 80, "logo"),
        },
      ],
    },
  };

export default TitleWithImages1;
