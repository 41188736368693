import { VideoRender, getRawText } from "../../utils/cms/renderableResources";
import CalendarEventButtons from "../SubComponents/CalendarEventButtons";
import ShareToFacebook from "../SubComponents/SocialSharing/ShareToFacebook";
import {
  CMSText,
  CMSVideoResource,
  CMSRichText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  RichTextRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import Requires from "../../internal_components/Requires";
import { useEffect, useState } from "react";
import { weekdayMonthDisplayDateTime } from "../../utils/dates/weekdayMonthDisplay";
import CMSButton from "../SubComponents/CMSButton";
import useDirectToWebinarUrlParams from "../../utils/webinar/useDirectToWebinarUrlParams";
import { convertToHex } from "../../utils/styling/colorManipulation";

const CCThankYouPage1 = ({
  brand,
  textBlock,
  text,
  video,
  fbLink,
  fbHashtag,
  brandAbbreviation,
  brandConfig,
}: InjectableComponentProps<CCThankYouPageProps>) => {
  const [startTime, setStartTime] = useState<Date | null>(null);
  const { directToWebinarUrl } = useDirectToWebinarUrlParams({
    setStartTime,
    brandAbbreviation,
  });
  const [style, setStyle] = useState("");

  useEffect(() => {
    // we use a setState here because hydration has some issues with the style tag and content = "" (It tries to escape
    // the quotes and then client and server are different, leading to issues with hydration)
    setStyle(`
              .rich-text-element a:link {
                text-decoration: underline;
              }
              .rich-text-element li {
                padding-left: 2.8rem;
                position: relative
              }
              .rich-text-element li:first-of-type:before{
                color: ${convertToHex(brandConfig?.primary_rgb_color || "")};
                content: "✓";
                font-size: 1.75rem;
                font-weight: 700;
                left: 5px;
                position:absolute;
                top: -10px
              }
  `);
  }, [brandConfig]);

  const calendarDetails = `<a href=${directToWebinarUrl}>Join the webinar</a>`;

  return (
    <div className="w-full h-full">
      <div className="flex flex-col-reverse md:flex-row gap-4 p-4 xl:w-4/5 self-center mx-auto">
        <div className="flex flex-col md:w-2/3 shadow-lg p-8 bg-white">
          <VideoRender resource={video} brandAbbreviation={brandAbbreviation} />
          <br />
          <style>{style || ""}</style>
          <RichTextRender className="rich-text-element" resource={textBlock} />
        </div>
        <div className="flex flex-col gap-2 sm:pl-8 xs:m-auto">
          <h3 className="font-bold text-2xl underline underline-offset-8">
            Event Details
          </h3>
          <p className="">
            <TextRender
              resource={brand.name + " Webinar"}
              className="font-semibold text-xl"
            />
            <br />
            <TextRender resource={text} />
            <span className="underline">
              {weekdayMonthDisplayDateTime(startTime, "long")}
            </span>
          </p>
          <div className="flex flex-col justify-center py-2">
            <Requires value={directToWebinarUrl}>
              <CMSButton
                buttonText="Go to webinar"
                buttonClass="w-full xs:w-fit shadow-md"
                href={directToWebinarUrl}
                tracking={{
                  destination: directToWebinarUrl,
                  type: "link",
                }}
              />
            </Requires>
          </div>
          <div className="w-full flex flex-col">
            <div className="flex flex-col w-full justify-center gap-y-2 py-2">
              <Requires value="https://">
                <ShareToFacebook
                  url={getRawText(fbLink)}
                  hashtag={getRawText(fbHashtag)}
                  className="w-full xs:w-fit"
                  buttonText="Share to Facebook"
                />
              </Requires>
            </div>
            <Requires value={startTime}>
              <CalendarEventButtons
                startTime={startTime}
                brand={brand.name}
                details={calendarDetails}
              />
            </Requires>
          </div>
        </div>
      </div>
    </div>
  );
};

type CCThankYouPageProps = {
  title: CMSText;
  textBlock: CMSRichText;
  text: CMSText;
  video: CMSVideoResource;
  fbLink: CMSText;
  fbHashtag: CMSText;
};

export const CCThankYouPage1StorybookProps: CMSComponentExampleProps<CCThankYouPageProps> =
  {
    title: {
      defaultTags: [{ type: "header_title" }],
      type: "text",
      value: "Save the Date!",
    },
    textBlock: {
      defaultTags: [{ type: "descriptive_text" }],
      type: "richtext",
      value: `This class is perfect for anyone. <i>Take a look at what you'll be painting below</i> 
      lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in 
      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, 
      sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    },
    text: {
      defaultTags: [{ type: "descriptive_text" }],
      type: "text",
      value: "Your Webinar Start Time is:",
    },
    fbLink: {
      defaultTags: [{ type: "value_text" }],
      type: "text",
      value:
        "https://www.pianoinaflash.com/?utm_source=fb&utm_medium=social_share&utm_campaign=lms_sharing&utm_content=",
    },
    fbHashtag: {
      defaultTags: [{ type: "value_text" }],
      type: "text",
      value: "pianoinaflash",
    },
    video: {
      defaultTags: [{ type: "video" }],
      type: "video_resource",
      value: {
        id: "59a5e568-cb7d-489e-8bdc-8ebdb83ad041",
        s3_path:
          "/nma/resources/59a5e568-cb7d-489e-8bdc-8ebdb83ad041/filename-to-remove",
        metadata: {
          duration: 1112,
          filename: "",
          subtitle: "",
          original_resolution: "1080p",
          available_resolutions: ["1080p", "720p", "540p"],
        },
      },
    },
  };

export default CCThankYouPage1;
