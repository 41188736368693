import { WebinarInteraction } from "@combined-curiosity/collector-js";
import { useEffect, useState } from "react";
import { generateClientSideUUID } from "../../utils/uuid/uuid";
import CountdownTimer from "../SubComponents/CountdownTimer";
import { useTracking } from "../trackEvent";

type PostWebinarRoomProps = {
  waitingRoomImage: string;
  brandAbbreviation: string;
  currentVideoOffset: number;
  viewType: WebinarInteraction["viewType"];
  webinarInstanceId: string;
  email: string;
  leadId: string;
  webinarId: string;
};

const PostWebinarRoom = ({
  waitingRoomImage,
  brandAbbreviation,
  currentVideoOffset,
  webinarInstanceId,
  viewType,
  email,
  leadId,
  webinarId,
}: PostWebinarRoomProps) => {
  const [tenSecondsFromNow, setTenSecondsFromNow] = useState<Date>();
  const { track } = useTracking();

  useEffect(() => {
    const now = new Date();
    now.setSeconds(now.getSeconds() + 10);

    setTenSecondsFromNow(now);
  }, []);

  const utm_medium = "redirect";
  const utm_source = "cc-webinar";
  const utm_campaign = webinarId;
  const utm_content = webinarInstanceId;
  const params = new URLSearchParams({
    utm_content,
    utm_medium,
    utm_source,
    utm_campaign,
  });

  const fireRedirect = () => {
    const uniqueId = generateClientSideUUID();
    if (brandAbbreviation === "piaf") {
      const redirectUrl = `http://www.pianoinaflash.com/webinar-bundle-enroll/?${params}`;
      track(
        {
          action: "webinar_interaction",
          meta: {
            interaction: "redirected_to_webinar_offer",
            currentVideoOffset,
            viewType,
            webinarInstanceId,
            redirectedTo: `${redirectUrl}/?${params}`,
            webinarId,
            anonymousWebinarViewer: false,
            webinarRegistrationId: "",
          },
          leadId,
          email,
          clientSubmissionUniqueId: uniqueId,
        },
        "beacon"
      );
      window.location.href = redirectUrl;
    } else if (brandAbbreviation === "nma") {
      const redirectUrl = `http://lp.nancymedina.com/webinar-bundle/?${params}`;
      track(
        {
          action: "webinar_interaction",
          meta: {
            interaction: "redirected_to_webinar_offer",
            currentVideoOffset,
            viewType,
            webinarInstanceId,
            redirectedTo: redirectUrl,
            webinarId,
            anonymousWebinarViewer: false,
            webinarRegistrationId: "",
          },
          leadId,
          email,
          clientSubmissionUniqueId: uniqueId,
        },
        "beacon"
      );
      window.location.href = redirectUrl;
    }
  };

  return (
    <div className="relative">
      <img src={waitingRoomImage} className="w-full" alt="" />
      <div className="bg-gradient-to-r from-primary to-transparent z-10 h-full w-full absolute top-0"></div>

      <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center text-white z-10">
        <div className="text-white text-5xl">Thanks for watching!</div>
        <div className="text-white text-2xl font-thin mt-6">
          You will be redirected soon
        </div>
        <div className="mt-4">
          {tenSecondsFromNow && (
            <CountdownTimer
              toDate={[tenSecondsFromNow]}
              styleType="condensed"
              setCompleted={fireRedirect}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PostWebinarRoom;
