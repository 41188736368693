import {
  ImageRender,
  RichTextRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSImageResource,
  CMSRichText,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { storybookImageResource } from "stories/helpers/storybookImageResource";

const LeftImageRightText1 = ({
  image,
  title,
  text,
}: InjectableComponentProps<LeftImageRightText1Props>) => {
  return (
    <div className="flex flex-col md:flex-row mx-20 gap-x-12 justify-center my-4">
      <picture className="flex flex-col md:block w-1/3 h-1/2 xl:w-1/4 my-auto">
        <ImageRender resource={image} />
      </picture>
      <div className="flex flex-col gap-y-8 my-2 md:my-auto w-4/5 md:w-1/2 xl:w-1/3">
        <h2 className="flex font-bold text-xl md:text-2xl flex-wrap">
          <TextRender resource={title} />
        </h2>
        <p className="">
          <RichTextRender resource={text} />
        </p>
      </div>
    </div>
  );
};

type LeftImageRightText1Props = {
  image: CMSImageResource;
  title: CMSText;
  text: CMSRichText;
};

export const LeftImageRightText1StorybookProps: CMSComponentExampleProps<LeftImageRightText1Props> =
  {
    image: {
      type: "image_resource",
      value: storybookImageResource(100, 80, "logo"),
    },
    title: {
      type: "text",
      value: "Meet your teacher, Scott Houston",
    },
    text: {
      type: "richtext",
      value: "lorem ipsum ~300 characters",
    },
  };

export default LeftImageRightText1;
