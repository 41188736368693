import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { CMSRichText, InjectableComponentProps } from "../cms/types/cmsTypes";
import { RichTextRender } from "../../utils/cms/renderableResources";

const Banner1 = ({ text }: InjectableComponentProps<Banner1Props>) => {
  return (
    <div className="flex items-center justify-around bg-tertiary p-4">
      <div>
        <RichTextRender resource={text} className="text-2xl" />
      </div>
    </div>
  );
};

type Banner1Props = {
  text: CMSRichText;
};

export const Banner1StorybookProps: CMSComponentExampleProps<Banner1Props> = {
  text: {
    type: "richtext",
    metadata: {
      value: "I am a banner announcement",
    },
  },
};

export default Banner1;
