import { placeholderImageUrlWithText } from "stories/helpers";
import {
  ImageRender,
  RichTextRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSBullets,
  CMSImageResource,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const EnrollOfferingChart1 = ({
  title,
  image,
  section1Title,
  section1Subtitle,
  section1Bullets,
  section2Title,
  section2Subtitle,
  section2Bullets,
  section3Title,
  section3Subtitle,
  section3Bullets,
}: InjectableComponentProps<EnrollOfferChartProps>) => {
  return (
    <div className="flex flex-col items-center">
      <h3 className="text-xl">
        <b>
          <TextRender resource={title} />
        </b>
      </h3>

      <ImageRender resource={image} className="max-w-lg my-6" />

      <div className="flex w-full m-auto justify-center gap-2">
        <div className="w-1/2 lg:ml-64 md:ml-24">
          <div className="bg-primary text-white rounded-md rounded-b-none rounded-r-none text-center text-2xl font-bold p-2">
            <TextRender resource={section1Title} />
          </div>

          <div className="bg-tertiary p-6 mt-2">
            <p className="text-secondary">
              <TextRender resource={section1Subtitle} />
            </p>
            <ul className="space-y-2">
              {section1Bullets.resources.map((bulletPoint) => (
                <li key={JSON.stringify(bulletPoint)}>
                  <RichTextRender resource={bulletPoint} />
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="w-1/2 lg:mr-64 md:mr-24">
          <div className="bg-primary text-white rounded-md rounded-b-none rounded-l-none text-center text-2xl font-bold p-2">
            <TextRender resource={section2Title} />
          </div>
          <div className="bg-tertiary p-6 mt-2">
            <p className="text-secondary">
              <TextRender resource={section2Subtitle} />
            </p>
            <ul className="space-y-2">
              {section2Bullets.resources.map((bulletPoint) => (
                <li key={JSON.stringify(bulletPoint)}>
                  <RichTextRender resource={bulletPoint} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="flex w-full m-auto justify-center gap-2">
        <div className="w-full mt-2 lg:mx-64 md:mx-24">
          <div className="bg-primary text-white text-center text-2xl font-bold p-2">
            <TextRender resource={section3Title} />
          </div>
          <div className="bg-tertiary p-6 mt-2 flex justify-center rounded-b-md">
            <div>
              <p className="text-secondary">
                <TextRender resource={section3Subtitle} />
              </p>
              <ul className="space-y-2">
                {section3Bullets.resources.map((bulletPoint) => (
                  <li key={JSON.stringify(bulletPoint)}>
                    <RichTextRender resource={bulletPoint} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type EnrollOfferChartProps = {
  title: CMSText;
  image: CMSImageResource;
  section1Title: CMSText;
  section1Subtitle: CMSText;
  section1Bullets: CMSBullets;
  section2Title: CMSText;
  section2Subtitle: CMSText;
  section2Bullets: CMSBullets;
  section3Title: CMSText;
  section3Subtitle: CMSText;
  section3Bullets: CMSBullets;
};

export const EnrollOfferingChart1StorybookProps: CMSComponentExampleProps<EnrollOfferChartProps> =
  {
    title: {
      type: "text",
      value: "When you buy, you'll receive",
    },
    image: {
      type: "image_resource",
      value: placeholderImageUrlWithText(
        400,
        400,
        "picture of course materials"
      ),
    },
    section1Title: { type: "text", value: "Section 1 Title" },
    section1Subtitle: { type: "text", value: "Section 1 Subtitle" },
    section1Bullets: {
      type: "richtext",
      values: ["Reason 1", "<b>Reason 2</b>", "<i>Reason 3</i>"],
    },
    section2Title: { type: "text", value: "Section 2 Title" },
    section2Subtitle: { type: "text", value: "Section 2 Subtitle" },
    section2Bullets: {
      type: "richtext",
      values: ["Reason 1", "<b>Reason 2</b>", "<i>Reason 3</i>"],
    },
    section3Title: { type: "text", value: "Section 3 Title" },
    section3Subtitle: { type: "text", value: "Section 3 Subtitle" },
    section3Bullets: {
      type: "richtext",
      values: ["Reason 1", "<b>Reason 2</b>", "<i>Reason 3</i>"],
    },
  };

export default EnrollOfferingChart1;
