import CMSButton from "../SubComponents/CMSButton";

const WelcomeScreen = ({
  onClick,
  waitingRoomImage,
}: {
  onClick: () => void;
  waitingRoomImage: string;
}) => {
  return (
    <div className="relative">
      <img src={waitingRoomImage} className="w-full" />
      <div className="bg-gradient-to-r from-primary to-transparent z-10 h-full w-full absolute top-0"></div>

      <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center text-white z-10">
        <div className="text-white text-5xl ">Welcome!</div>
        <div className="mt-4">
          <CMSButton
            buttonText="Watch Now"
            onClick={onClick}
            tracking={{
              expectedResult: "Entered webinar room",
              innerText: "Watch Now",
              type: "button",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
