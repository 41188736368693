import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSImageTitleAndText,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { TextRender } from "../../utils/cms/renderableResources";

const AccoladesBanner1 = ({
  title,
  accolades,
}: InjectableComponentProps<AccoladesBanner1Props>) => {
  return (
    <div className="flex justify-center bg-primary text-tertiary flex-col">
      <div className="mx-4">
        <h2 className="flex justify-center text-3xl font-bold gap-6 py-8">
          <TextRender resource={title} />
        </h2>
      </div>
      <div className="flex flex-col md:flex-row justify-center">
        {accolades.resources.map((entry) => (
          <>
            <div className="flex justify-center">
              <div className="flex flex-col py-6 px-4">
                <h2 className="flex justify-center font-bold text-2xl">
                  <TextRender resource={entry.title} />
                </h2>
                <p className="center-text">
                  <TextRender resource={entry.text} />
                </p>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

type AccoladesBanner1Props = {
  title: CMSText;
  accolades: CMSCollection<CMSImageTitleAndText>;
};

export const AccoladesBanner1StorybookProps: CMSComponentExampleProps<AccoladesBanner1Props> =
  {
    title: {
      type: "text",
      value: "I've unlocked the secrets to playing piano",
    },
    accolades: {
      type: "imageTitleAndText",
      retrieveAll: true,
      values: [
        {
          title: "12",
          text: "Years refining the piano approach",
        },
        {
          title: "20+",
          text: "years playing piano",
        },
        {
          title: "50,000+",
          text: "online piano students",
        },
      ],
    },
  };

export default AccoladesBanner1;
