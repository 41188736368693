import { placeholderImageUrlWithText } from "stories/helpers";
import { ImageRender, TextRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSImageWithCaption,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const EndorserLogos1 = ({
  images,
}: InjectableComponentProps<EndorserLogos1Props>) => {
  return (
    <div className="flex justify-evenly p-8 bg-secondary flex-row">
      <div className="flex justify-around flex-wrap flex-row gap-16 md:flex-nowrap">
        {images?.resources.map((image, index) => (
          <div
            className="flex flex-col items-center"
            key={index}
            data-name="endorser-logos-image"
          >
            <ImageRender
              className="flex justify-center items-center text-secondary-darker w-[120px] h-[100px] object-contain"
              key={index}
              resource={image.image}
            />
            {image.caption && (
              <TextRender
                className="pt-4 text-bg-secondary"
                resource={image.caption}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

type EndorserLogos1Props = {
  images: CMSCollection<CMSImageWithCaption>;
};

export const EndorserLogos1StorybookProps: CMSComponentExampleProps<EndorserLogos1Props> =
  {
    images: {
      defaultTags: [{ type: "image_with_caption" }],
      type: "image_with_caption",
      retrieveAll: true,
      values: [
        {
          caption: "This is a caption",
          image: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
        {
          caption: "This is a caption",
          image: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
        {
          caption: "This is a caption",
          image: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
        {
          caption: "This is a caption",
          image: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
      ],
    },
  };

export default EndorserLogos1;
