import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSLink,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { TextRender } from "../../utils/cms/renderableResources";
import TrunkLink from "../TrunkLink";

const Footer2 = ({
  brandAbbreviation,
  copyrightText,
  links,
}: InjectableComponentProps<Footer2Props>) => {
  const year = new Date().getFullYear();

  // temporary fix until order is added to pcr
  const orderedLinks = [
    "My Story",
    "Testimonials",
    "Pricing",
    "FAQ",
    "Blog",
    "Joyful Brush Login",
    "E-Course Login",
    "Sign In",
  ];
  const sortedLinks = [];

  const resources = [...links.resources];

  for (const orderedLink of orderedLinks) {
    for (const link of resources) {
      if (link.title === orderedLink) {
        sortedLinks.push(link);
      }
    }
  }

  for (const link of resources) {
    if (orderedLinks.includes(link.title)) {
      continue;
    }
    sortedLinks.push(link);
  }

  return (
    <div className="p-4 bg-primary">
      <div className="flex flex-col sm:flex-row sm:justify-end">
        <div className="flex flex-col sm:flex-row sm:mr-12 lg:mr-24">
          {sortedLinks.map((link, index) => {
            return (
              <TrunkLink
                brandAbbreviation={brandAbbreviation}
                key={index}
                href={link.href}
              >
                <span className="cursor-pointer hover:text-tertiary ml-4 text-bg-primary py-2">
                  {link.title}
                </span>
              </TrunkLink>
            );
          })}
        </div>
      </div>
      <div className="text-xs my-auto text-center pt-10 text-bg-primary">
        <p>
          &copy; {year} <TextRender resource={copyrightText} />
        </p>
      </div>
    </div>
  );
};

type Footer2Props = {
  copyrightText: CMSText;
  links: CMSCollection<CMSLink>;
};

export const Footer2StorybookProps: CMSComponentExampleProps<Footer2Props> = {
  copyrightText: {
    type: "text",
    value: "Brand Inc.",
    defaultTags: [{ type: "copyright_text" }],
  },
  links: {
    type: "link",
    retrieveAll: true,
    defaultTags: [{ type: "brand_link" }],
    values: [
      {
        type: "link",
        title: "My Story",
        href: "My-story",
      },
      {
        type: "link",
        title: "Testimonials",
        href: "testimonials",
      },
      {
        type: "link",
        title: "Pricing",
        href: "pricing",
      },
      {
        type: "link",
        title: "FAQ",
        href: "faq",
      },
      {
        type: "link",
        title: "Blog",
        href: "blog",
      },
      {
        type: "link",
        title: "Privacy Policy",
        href: "privacy",
      },
      {
        type: "link",
        title: "Terms of Service",
        href: "terms",
      },
    ],
  },
};

export default Footer2;
