import { placeholderImageUrlWithText } from "../helpers";

export const storybookImageResource = (
  width: number,
  height: number,
  subjectOfImage: string
) => {
  return {
    type: "image_resource",
    metadata: {
      value: placeholderImageUrlWithText(width, height, subjectOfImage),
    },
  };
};
