import { CMSText } from "../cms/types/cmsTypes";
import { TextRender } from "../../utils/cms/renderableResources";

const CircleWithTitleAndText = ({
  title,
  text,
  position,
}: CircleWithTitleAndTextProps) => {
  const defaultPosition =
    "p-2 -bottom-12 sm:absolute sm:rounded-full sm:h-48 sm:w-48 sm:right-[20vw]";

  return (
    <div
      data-component="CircleWithTitleAndText"
      className={`${
        position || defaultPosition
      } flex flex-col text-center bg-secondary text-bg-secondary`}
    >
      <h2 className="text-xl sm:text-lg font-bold mt-4 mb-1">
        <TextRender resource={title} />
      </h2>
      <p className="sm:text-sm font-light">
        <TextRender resource={text} />
      </p>
    </div>
  );
};

type CircleWithTitleAndTextProps = {
  title: CMSText;
  text: CMSText;
  position?: CMSText;
};

export default CircleWithTitleAndText;
