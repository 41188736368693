import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { CMSRichText, InjectableComponentProps } from "../cms/types/cmsTypes";
import { RichTextRender } from "../../utils/cms/renderableResources";

const RichTextBlock1 = ({
  textBlock,
}: InjectableComponentProps<RichTextBlock1Props>) => {
  return (
    <>
      <style>
        {`          
        .rich-text-block-v1 ul {
          list-style: disc;
          margin-left: 2.5rem;
        }

        .rich-text-block-v1 ol {
          list-style-type: decimal;
          margin-left: 2.5rem;
        }

        .rich-text-block-v1 code {
          background-color: #cbd5e1;
          border-radius: 0.25rem;
          font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
          font-size: 0.875rem;
          color: #e53e3e;
          padding: 0.25rem 0.5rem;
        }

        .rich-text-block-v1 p {
          margin-bottom: 1rem;
          margin-top: 1rem;
        }

        .rich-text-block-v1 h1 {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 1rem;
          margin-top: 2rem;
        }

        .rich-text-block-v1 h2 {
          font-size: 1.75rem;
          font-weight: 700;
          margin-bottom: 1rem;
          margin-top: 1rem;
        }

        .rich-text-block-v1 h3 {
          font-size: 1.5rem;
          font-weight: 700;
          margin-bottom: 1rem;
          margin-top: 1rem;
        }

        `}
      </style>
      <div className="container px-4 mx-auto max-w-prose">
        <RichTextRender resource={textBlock} className="rich-text-block-v1" />
      </div>
    </>
  );
};

type RichTextBlock1Props = {
  textBlock: CMSRichText;
};

export const RichTextBlock1StorybookProps: CMSComponentExampleProps<RichTextBlock1Props> =
  {
    textBlock: {
      type: "richtext",
      value:
        "Here is some additional information that you can read and learn more about our offerings.",
    },
  };

export default RichTextBlock1;
