import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSDisplayOffer,
  CMSImageResource,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { definitions } from "../../types/supabase";
import { ImageRender, TextRender } from "../../utils/cms/renderableResources";
import { storybookImageResource } from "stories/helpers/storybookImageResource";

const OfferPreview1 = ({
  displayOffer,
}: InjectableComponentProps<OfferPreviewProps>) => {
  return (
    <div className="flex flex-row flex-wrap justify-center gap-4 md:gap-8 my-8">
      {displayOffer.primary_products.map(
        (o: definitions["products"] & { image: CMSImageResource }) => (
          <>
            <div className="flex flex-col w-1/4">
              <h2 className="font-bold text-md md:text-3xl pb-2 md:pb-6 text-center">
                <TextRender resource={o.name} />
              </h2>
              <ImageRender resource={o.image} className="" />
            </div>
          </>
        )
      )}
      <div className="w-full">
        <h2 className="text-center text-xs md:text-xl lg:pl-48 lg:pr-48 lg:text-center font-bold">
          Also Includes:
        </h2>
      </div>

      <div className="flex flex-row flex-wrap justify-center gap-8">
        {displayOffer.secondary_products.map((o) => (
          <>
            <div className="flex flex-col justify-center w-2/12">
              <h2 className="font-bold text-sm md:text-lg text-center">
                <TextRender resource={o.name} />
              </h2>
              <ImageRender resource={o.image} className="" />
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

type OfferPreviewProps = {
  displayOffer: CMSDisplayOffer;
};

export const OfferPreview1StorybookProps: CMSComponentExampleProps<OfferPreviewProps> =
  {
    displayOffer: {
      type: "display_offer",
      primary_products: [
        {
          name: "Primary Product #1",
          image: storybookImageResource(400, 400, "Product 1"),
        },
        {
          name: "Primary Product #2",
          image: storybookImageResource(400, 400, "Product 2"),
        },
        {
          name: "Primary Product #3",
          image: storybookImageResource(400, 400, "Product 3"),
        },
        {
          name: "Primary Product #4",
          image: storybookImageResource(400, 400, "Product 4"),
        },
        {
          name: "Primary Product #5",
          image: storybookImageResource(400, 400, "Product 5"),
        },
        {
          name: "Primary Product #6",
          image: storybookImageResource(400, 400, "Product 6"),
        },
      ],
      secondary_products: [
        {
          name: "Secondary Product #1",
          image: storybookImageResource(400, 400, "Product 1"),
        },
        {
          name: "Secondary Product #2",
          image: storybookImageResource(400, 400, "Product 2"),
        },
      ],
    },
  };

export default OfferPreview1;
