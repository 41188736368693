import { storybookImageResource } from "stories/helpers/storybookImageResource";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSImageResource,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { ImageRender } from "../../utils/cms/renderableResources";

const NavbarOnlyLogo1 = ({
  logo,
}: InjectableComponentProps<NavbarOnlyLogo1Props>) => {
  return (
    <div
      data-component="NavbarOnlyLogo1"
      className="flex justify-around max-h-24 p-2 bg-tertiary"
    >
      <ImageRender resource={logo} className="max-h-20 object-contain w-32" />
      <div />
    </div>
  );
};

type NavbarOnlyLogo1Props = {
  logo: CMSImageResource;
};

export const NavbarOnlyLogo1StorybookProps: CMSComponentExampleProps<NavbarOnlyLogo1Props> =
  {
    logo: storybookImageResource(100, 80, "logo"),
  };

export default NavbarOnlyLogo1;
