import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSTestimonial,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { TextRender } from "../../utils/cms/renderableResources";

const Testimonial1 = ({
  testimonial,
  brand,
}: InjectableComponentProps<Testimonial1Props>) => {
  return (
    <div className="my-8">
      <h2 className="flex justify-center text-2xl italic my-3 text-center">
        <TextRender resource={testimonial.quote} />
      </h2>

      <p className="flex justify-center text-sm">
        <TextRender resource={testimonial.name} /> -{" "}
        <TextRender resource={brand.name} /> Student
      </p>
    </div>
  );
};

type Testimonial1Props = {
  testimonial: CMSTestimonial;
};

export const Testimonial1StorybookProps: CMSComponentExampleProps<Testimonial1Props> =
  {
    testimonial: {
      type: "testimonial",
      quote: '"This was pretty pretty pretty good!"',
      name: "Larry David",
    },
  };

export default Testimonial1;
