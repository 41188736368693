import { ImageRender, TextRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSImageTitleAndText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { storybookImageResource } from "stories/helpers/storybookImageResource";

const CardWithImageTitleAndText4 = ({
  imageTitleAndText,
}: InjectableComponentProps<CardWithImageTitleAndText4Props>) => {
  return (
    <div
      data-component="CardWithImageTitleAndText1"
      className="flex flex-wrap lg:flex-nowrap justify-center gap-10 mb-40"
    >
      {imageTitleAndText?.resources.map((entry, index) => {
        return (
          <div
            key={JSON.stringify(entry) + index}
            className="w-3/4 md:w-1/3 lg:w-1/6 mx-auto md:mx-0 pt-8"
          >
            <div className="h-32 w-32 m-auto">
              <ImageRender
                resource={entry.image}
                className="m-auto"
                imgClassName="w-32 h-32 rounded-full object-cover"
              />
            </div>
            <div className="flex flex-col text-center bg-secondary -mt-16 px-4 pt-16 h-full mb-2 flex-1">
              <TextRender
                resource={entry.title}
                className="font-bold mt-4 mb-2 text-black"
              />
              <TextRender resource={entry.text} className="italic" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

type CardWithImageTitleAndText4Props = {
  imageTitleAndText: CMSCollection<CMSImageTitleAndText>;
};

export const CardWithImageTitleAndText4StorybookProps: CMSComponentExampleProps<CardWithImageTitleAndText4Props> =
  {
    imageTitleAndText: {
      type: "imageTitleAndText",
      retrieveAll: true,
      values: [
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "Test title",
          text: "Hello, world.",
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "Test title",
          text: "Hello, world.",
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "Test title",
          text: "Hello, world.",
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "Individual feedback",
          text: `Have a question specifically about your [hobby] lessons or a particular 
          [blank is stumping you? We can help! We have dedicated staff ready and waiting.`,
        },
      ],
    },
  };

export default CardWithImageTitleAndText4;
