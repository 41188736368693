import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";

const Spacer1 = () => {
  return <div className="h-4 sm:h-8"></div>;
};

type Spacer1Props = {};

export const Spacer1StorybookProps: CMSComponentExampleProps<Spacer1Props> = {};

export default Spacer1;
