import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSImageResource,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  ImageRender,
  RichTextRender,
} from "../../utils/cms/renderableResources";

const ImageWithTitleAndText4 = ({
  title,
  image,
  text,
}: InjectableComponentProps<ImageWithTitleAndText4Props>) => {
  return (
    <div
      data-component="ImageWithTitleAndText4"
      className="p-8 flex flex-col gap-y-8 bg-secondary justify-center"
    >
      <h2 className="font-bold text-3xl text-primary text-center">
        <RichTextRender resource={title} />
      </h2>
      <div className="flex flex-col sm:flex-row gap-8 justify-center m-auto w-full xl:w-1/2 sm:text-left sm:text-xl items-center">
        <ImageRender resource={image} />
        <RichTextRender resource={text} />
      </div>
    </div>
  );
};

type ImageWithTitleAndText4Props = {
  title: CMSText;
  text: CMSText;
  image: CMSImageResource;
};

export const ImageWithTitleAndText4StorybookProps: CMSComponentExampleProps<ImageWithTitleAndText4Props> =
  {
    title: {
      defaultTags: [{ type: "header_title" }],
      type: "richtext",
      value: "Win one of my <i>Island Wildflower</i> art prints!",
    },
    text: {
      defaultTags: [{ type: "header_subtitle" }],
      type: "richtext",
      value: `Adorn your walls with one of my favorite pieces, on a 16"x16" matte print. 
    Enter to win simply by attending my webinar to the end! Winner will be drawn weekly, 
    on Friday.<br/>See giveaway terms & conditions.<br/>*Prize can only be shipped to a valid US address`,
    },
    image: {
      defaultTags: [{ type: "header_image" }],
      type: "image_resource",
      value: "https://v.fastcdn.co/u/e8e57313/62288572-0-bio-BB-image-3.jpg",
    },
  };

export default ImageWithTitleAndText4;
