import { placeholderImageUrlWithText } from "stories/helpers";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSImageResource,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { ImageRender, TextRender } from "../../utils/cms/renderableResources";

const BrandHeaderBackgroundImage2 = ({
  image,
  title,
  text,
}: InjectableComponentProps<BrandHeaderBackgroundImage2Props>) => {
  return (
    <div className="relative h-full">
      <ImageRender
        resource={image}
        className="absolute inset-0 object-cover w-full h-full -z-10"
        imgClassName="h-full object-cover brightness-75 object-top"
      />
      <div className="flex flex-col gap-y-8 m-auto w-4/5 md:w-1/2 xl:w-1/3 h-full items-center justify-center pt-16 pb-4 sm:py-8">
        <h1 className="font-bold text-4xl text-white text-center">
          <TextRender resource={title} />
        </h1>
        <TextRender
          resource={text}
          className="flex text-white justify-center mx-auto"
        />
      </div>
    </div>
  );
};

type BrandHeaderBackgroundImage2Props = {
  image: CMSImageResource;
  title: CMSText;
  text: CMSText;
};

export const BrandHeaderBackgroundImage2StorybookProps: CMSComponentExampleProps<BrandHeaderBackgroundImage2Props> =
  {
    image: {
      defaultTags: [{ type: "header_image" }],
      type: "image_resource",
      value: placeholderImageUrlWithText(400, 600, "image of content"),
    },
    title: {
      defaultTags: [{ type: "header_title" }],
      type: "text",
      value: "Buy this thing and you'll get this benefit!",
    },
    text: {
      defaultTags: [{ type: "header_subtitle" }],
      type: "text",
      value: "These are all of the reasons you should buy this thing...",
    },
  };

export default BrandHeaderBackgroundImage2;
