import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSText,
  InjectableComponentProps,
  CMSCollection,
  CMSImageTitleAndText,
} from "../cms/types/cmsTypes";
import { TextRender, ImageRender } from "../../utils/cms/renderableResources";
import { storybookImageResource } from "stories/helpers/storybookImageResource";

const TitleWithSmallImagesAndText1 = ({
  title,
  imagesAndText,
}: InjectableComponentProps<TitleWithSmallImagesAndText1Props>) => {
  return (
    <div className="py-12 bg-gray-50">
      <h2 className="text-center font-bold text-4xl">
        <TextRender resource={title} />
      </h2>

      <div className="flex flex-wrap justify-center mt-12 gap-10 sm:gap-20">
        {imagesAndText.resources.map((entry) => (
          <>
            <div className="flex flex-col w-32">
              <ImageRender resource={entry.image} />
              <p className="text-center mt-2">
                <TextRender resource={entry.text} />
              </p>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

type TitleWithSmallImagesAndText1Props = {
  title: CMSText;
  imagesAndText: CMSCollection<CMSImageTitleAndText>;
};

export const TitleWithSmallImagesAndText1StorybookProps: CMSComponentExampleProps<TitleWithSmallImagesAndText1Props> =
  {
    title: {
      type: "text",
      value: "All in an online environment built for you",
    },
    imagesAndText: {
      type: "imageTitleAndText",
      values: [
        {
          image: storybookImageResource(100, 80, "logo"),
          text: "Learn at your own pace",
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          text: "Learn at your own pace",
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          text: "Learn at your own pace",
        },
      ],
    },
  };

export default TitleWithSmallImagesAndText1;
