import { placeholderImageUrlWithText } from "stories/helpers";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { Parallax, Background } from "react-parallax";
import {
  CMSImageResource,
  CMSTestimonial,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { ImageRender, TextRender } from "../../utils/cms/renderableResources";

const Quote1 = ({
  backgroundImage,
  quote,
}: InjectableComponentProps<Quote1Props>) => {
  return (
    <div>
      <Parallax blur={0} strength={400}>
        <Background className="custom-bg object-cover h-full w-full">
          <ImageRender
            resource={backgroundImage}
            className={"bg-cover w-full h-full z-10"}
          />
        </Background>
        <div className="relative z-10 p-12">
          <h2 className="flex justify-center text-2xl italic my-3 text-center">
            <TextRender resource={quote.quote} />
          </h2>
          <p className="flex justify-center text-sm">
            <TextRender resource={quote.name} />
          </p>
        </div>
      </Parallax>
    </div>
  );
};

type Quote1Props = {
  backgroundImage: CMSImageResource;
  quote: CMSTestimonial;
};

export const Quote1StorybookProps: CMSComponentExampleProps<Quote1Props> = {
  backgroundImage: {
    type: "image_resource",
    value: placeholderImageUrlWithText(1920, 1080, "background image"),
  },
  quote: {
    type: "testimonial",
    quote: '"This was pretty pretty pretty good!"',
    name: "Larry David",
  },
};

export default Quote1;
