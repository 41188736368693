import { WebinarInteraction } from "@combined-curiosity/collector-js";
import CMSButton from "components/SubComponents/CMSButton";
import { useEffect, useState } from "react";
import { generateClientSideUUID } from "utils/uuid/uuid";
import { BuyButtonInteractivePointOfInterest } from "../cms/types/cmsTypes";
import { TextRender } from "utils/cms/renderableResources";
import CountdownTimer from "components/SubComponents/CountdownTimer";
import { KnownBrand } from "@/types/index";
import ensureTrailingSlash from "app/admin/lib/cctmify/src/utils/ensureTrailingSlash";

type SideBarBuyButtonProps = {
  currentTime: number;
  trackWebinarEvent: (
    meta: WebinarInteraction,
    uniqueId: string,
    transport?: "beacon" | "xhr" | undefined
  ) => void;
  config: BuyButtonInteractivePointOfInterest;
  webinarInstanceId: string;
  webinarId: string;
  viewType: WebinarInteraction["viewType"];
  title: string;
  startsAt?: Date;
  duration: string;
  setChatHidden: Function;
  brandAbbreviation: KnownBrand["abbreviation"];
  isVisible: boolean;
};

const SideBarBuyButton = ({
  currentTime,
  trackWebinarEvent,
  viewType,
  webinarInstanceId,
  config,
  webinarId,
  title,
  startsAt,
  duration,
  setChatHidden,
  brandAbbreviation,
  isVisible,
}: SideBarBuyButtonProps) => {
  const [originalStartsAt] = useState(startsAt);
  const [trackedBuyButtonSeen, setTrackedBuyButtonSeen] = useState(false);
  const handleClick = () => {
    const uniqueId = generateClientSideUUID();
    trackWebinarEvent(
      {
        interaction: "clicked_webinar_offer",
        currentVideoOffset: currentTime,
        viewType,
        webinarInstanceId,
        webinarId,
        anonymousWebinarViewer: false,
      },
      uniqueId
    );
    const url = config.metadata.url;
    const utm_medium = "webinar-buy-button";
    const utm_source = "cc-webinar";
    const utm_content = webinarInstanceId;
    const utm_campaign = webinarId;
    const params = new URLSearchParams({
      utm_medium,
      utm_source,
      utm_content,
      utm_campaign,
    });
    window.open(`${ensureTrailingSlash(url)}?${params}`, "_blank");
  };

  const [end, setEnd] = useState<Date | undefined>();

  useEffect(() => {
    if (!originalStartsAt) {
      return;
    }
    const start = new Date(originalStartsAt);
    const end = new Date(start.getTime() + parseInt(duration) * 1000);

    setEnd(end);
  }, [originalStartsAt, duration]);

  useEffect(() => {
    if (currentTime > config?.starts_at && isVisible) {
      if (!trackedBuyButtonSeen) {
        const uniqueId = generateClientSideUUID();
        trackWebinarEvent(
          {
            interaction: "content_seen",
            breakpointSeen: "buy_button",
            viewType,
            webinarInstanceId,
            webinarId,
            anonymousWebinarViewer: false,
          },
          uniqueId
        );
        setTrackedBuyButtonSeen(true);
      }
    }
  }, [
    currentTime,
    config.starts_at,
    trackWebinarEvent,
    trackedBuyButtonSeen,
    viewType,
    webinarId,
    webinarInstanceId,
    isVisible,
  ]);

  useEffect(() => {
    const popUpBoxElement = document.getElementById("popUpBox");
    if (popUpBoxElement) {
      popUpBoxElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  if (!end) {
    return null;
  }

  if (currentTime > config?.starts_at) {
    if (!trackedBuyButtonSeen) {
      setChatHidden(false);
    }

    // TODO - Make this Dynamic based on Brand
    const selectUrlByBrand = (
      brandAbbreviation: KnownBrand["abbreviation"]
    ) => {
      switch (brandAbbreviation) {
        case "nma":
          return "https://www.hobbyresources.com/nma/resources/fa035aa7-f107-493f-b24a-0e0f52de7b05/image%20(2).png";
        case "piaf":
          return "https://www.hobbyresources.com/piaf/resources/034d8ca1-6a71-4e34-ac22-7f6c0ce29274/image%20(3).png";
        default:
          return "";
      }
    };

    return (
      <div
        className="flex flex-col w-full bg-tertiary p-4 align-right rounded-md gap-y-4"
        id="popUpBox"
      >
        <CMSButton
          buttonClass="shadow-lg order-1 lg:order-4 font-black w-2/3 mx-auto"
          buttonBgClass="bg-primary text-bg-primary shadow-lg"
          buttonText="ENROLL NOW!"
          onClick={handleClick}
          tracking={{
            expectedResult: "navigate to post-webinar purchase page",
            innerText: "ENROLL NOW!",
            type: "button",
          }}
        />
        <TextRender
          resource={title}
          className="md:text-lg text-md bg-white text-center order-2 rounded-full font-bold px-8 flex-center mx-auto"
        />
        <div className="order-3 font-bold text-red-600">
          <CountdownTimer toDate={[end]} short styleType="popup" />
        </div>
        <img
          src={selectUrlByBrand(brandAbbreviation)}
          className="w-1/2 mx-auto"
        />
      </div>
    );
  }
  return null;
};

export default SideBarBuyButton;
