import { useClickHandler } from "../cms/PageContext";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import getCountdownDateToNHoursAfterFirstUserVisit from "../../utils/countdowns/getCountdownDateToNHoursAfterFirstUserVisit";
import CountdownTimer from "../SubComponents/CountdownTimer";
import CMSButton from "../SubComponents/CMSButton";
import { CMSText, InjectableComponentProps } from "../cms/types/cmsTypes";
import { getRawText, TextRender } from "../../utils/cms/renderableResources";
import OnlyOnClient from "../OnlyOnClient/OnlyOnClient";

// countdown if user has cookies ex: countdown for sale that lasts 24 hrs after initial visit
const CountdownTimer2 = ({
  countdownLabel,
  countdownLabelOnceCompleted,
  countdownToNHoursAfterFirstUserVisit,
  ctaText,
  component,
}: InjectableComponentProps<CountdownTimer2Props>) => {
  const clickHandler = useClickHandler(component, "ctaText");

  const getToDate = () => {
    const date = getCountdownDateToNHoursAfterFirstUserVisit(
      getRawText(countdownToNHoursAfterFirstUserVisit)
    );
    return date ? [date] : null;
  };
  return (
    <div
      className={`bg-tertiary flex flex-col gap-6 md:flex-row justify-center bg-grey-800 p-8 text-3xl text-grey`}
    >
      <p className="flex justify-center my-auto text-black text-2xl md:text-3xl md:w-1/3">
        <TextRender resource={countdownLabel} />
      </p>
      <OnlyOnClient>
        <CountdownTimer
          countdownLabelOnceCompleted={getRawText(countdownLabelOnceCompleted)}
          toDate={getToDate()}
        />
      </OnlyOnClient>
      <CMSButton
        buttonClass="min-w-fit"
        buttonText={getRawText(ctaText)}
        onClick={clickHandler}
        tracking={{
          expectedResult: "countdown cta button clicked",
          innerText: getRawText(ctaText),
          resourceId: ctaText.id,
          type: "button",
        }}
      />
    </div>
  );
};

type CountdownTimer2Props = {
  countdownLabel: CMSText;
  countdownLabelOnceCompleted: CMSText;
  countdownToNHoursAfterFirstUserVisit: CMSText;
  ctaText: CMSText;
};

export const CountdownTimer2StorybookProps: CMSComponentExampleProps<CountdownTimer2Props> =
  {
    countdownLabel: {
      type: "text",
      value: "Countdown to the thing!",
    },
    countdownLabelOnceCompleted: {
      type: "text",
      value: "The thing is over!",
    },
    countdownToNHoursAfterFirstUserVisit: {
      type: "text",
      value: "2",
    },
    ctaText: {
      type: "text",
      value: "Click me!",
    },
    component: {
      required_data: {
        ctaText: {
          metadata: {
            onClick: "consoleLog",
          },
        },
      },
    },
  };

export default CountdownTimer2;
