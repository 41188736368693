import { placeholderImageUrlWithText } from "stories/helpers";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import EmailSubscriptionInputAndButton from "../SubComponents/EmailSubscriptionInputAndButton";
import {
  CMSImageResource,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { ImageRender, TextRender } from "../../utils/cms/renderableResources";

const BrandHeader1 = ({
  apiUrl,
  brand,
  clientSideId,
  ctaDescriptiveText,
  image,
  title,
  text,
}: InjectableComponentProps<BrandHeader1Props>) => {
  return (
    <div
      data-component="HeaderWithSideImageAndCTA1"
      className="flex flex-row mx-auto md:mx-20 gap-x-20 justify-center"
    >
      <div className="flex flex-col gap-y-8 my-auto w-4/5 md:w-1/2 xl:w-1/3">
        <h1 className="font-bold text-4xl text-primary">
          <TextRender resource={title} />
        </h1>
        <ImageRender resource={image} className=" md:hidden w-full" />
        <TextRender resource={text} className="flex justify-center order-3" />
        <div className="flex flex-col bg-primary justify-center pt-4 w-full order-4 rounded-md shadow-2xl">
          <div className="self-center mb-4 px-4">
            <TextRender
              resource={ctaDescriptiveText}
              className="text-white font-bold"
            />
          </div>
          <EmailSubscriptionInputAndButton
            apiUrl={apiUrl}
            brandId={brand?.id}
            clientSideId={clientSideId}
          />
        </div>
      </div>
      <div className="flex flex-col hidden md:block w-1/3 md:w-2/3 xl:w-1/4 my-auto">
        <ImageRender resource={image} className="" />
      </div>
    </div>
  );
};

type BrandHeader1Props = {
  image: CMSImageResource;
  title: CMSText;
  text: CMSText;
  ctaDescriptiveText: CMSText;
};

export const BrandHeader1StorybookProps: CMSComponentExampleProps<BrandHeader1Props> =
  {
    image: {
      defaultTags: [{ type: "header_image" }],
      type: "image_resource",
      value: placeholderImageUrlWithText(400, 600, "image of content"),
    },
    title: {
      defaultTags: [{ type: "header_title" }],
      type: "text",
      value: "Buy this thing and you'll get this benefit!",
    },
    text: {
      defaultTags: [{ type: "header_subtitle" }],
      type: "text",
      value: "These are all of the reasons you should buy this thing...",
    },
    ctaDescriptiveText: {
      defaultTags: [{ type: "descriptive_text" }],
      type: "text",
      value: "Please sign up for our intro course.",
    },
  };

export default BrandHeader1;
