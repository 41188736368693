import { AiTwotoneCalendar } from "react-icons/ai";
import trackEvent from "../trackEvent";

const CalendarButton = ({ text, url }: { text: string; url: string }) => {
  const handleClick = async (url: string) => {
    trackEvent({
      action: "click",
      meta: {
        destination: url,
        type: "link",
      },
    });

    window.open(url);
  };

  return (
    <button
      onClick={() => handleClick(url)}
      className="w-full text-white font-semibold rounded bg-blue-500 hover:bg-blue-700 p-2"
    >
      <span className="whitespace-nowrap flex flex-row w-fit items-center">
        <AiTwotoneCalendar className="mr-3" />
        {text}
      </span>
    </button>
  );
};

export default CalendarButton;
