import { storybookImageResource } from "stories/helpers/storybookImageResource";
import { ImageRender, TextRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSImageTitleAndText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const SmallImageWithTitleAndSubtitle1 = ({
  imageTitleAndText,
}: InjectableComponentProps<SmallImageWithTitleAndSubtitle1Props>) => {
  return (
    <div
      data-component="SmallImageWithTitleAndSubtitle1"
      className="flex justify-center w-full"
    >
      <div className="flex flex-col md:flex-row justify-around flex-wrap gap-12 lg:flex-nowrap md:gap-2">
        {imageTitleAndText.resources.map((entry, index) => {
          return (
            <div
              key={JSON.stringify(entry) + index}
              className="flex flex-col justify-center items-center md:scale-100 scale-[1.25] md:mx-12"
            >
              <TextRender resource={entry.title} className="font-bold" />
              <ImageRender
                resource={entry.image}
                imgClassName="w-24 h-24 object-cover rounded-full"
              />
              <TextRender resource={entry.name} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

type SmallImageWithTitleAndSubtitle1Props = {
  imageTitleAndText: CMSCollection<CMSImageTitleAndText>;
};

export const SmallImageWithTitleAndSubtitle1StorybookProps: CMSComponentExampleProps<SmallImageWithTitleAndSubtitle1Props> =
  {
    imageTitleAndText: {
      type: "imageTitleAndText",
      values: [
        {
          image: storybookImageResource(120, 120, "headshot"),
          title: "John Smith",
          text: "Owner",
        },
        {
          image: storybookImageResource(120, 120, "headshot"),
          title: "John Smith",
          text: "Owner",
        },
      ],
    },
  };

export default SmallImageWithTitleAndSubtitle1;
