import { placeholderImageUrlWithText } from "stories/helpers";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSImageResource,
  CMSText,
  HasOnClick,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  getRawText,
  ImageRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import CMSButton from "../SubComponents/CMSButton";
import { useClickHandler } from "../cms/PageContext";

const BrandHeaderBackgroundImage4 = ({
  component,
  image,
  title,
  text,
  ctaButtonText,
}: InjectableComponentProps<BrandHeaderBackgroundImage4Props>) => {
  const clickHandler = useClickHandler(component, "ctaButtonText");

  return (
    <div className="relative h-full px-2 sm:px-0">
      <div className="bg-primary absolute h-full w-full opacity-20 -z-10" />
      <ImageRender
        resource={image}
        className="absolute inset-0 object-cover w-full h-full -z-20"
        imgClassName="h-full object-cover brightness-50 object-top"
      />
      <div className="w-full h-full flex flex-col gap-y-8 m-auto w-4/5 md:w-1/2 xl:w-1/3 h-full justify-center pt-16 pb-4 sm:py-8 sm:pt-16 px-2">
        <h1 className="font-bold text-4xl text-white text-center pt-8">
          <TextRender resource={title} />
        </h1>
        <TextRender
          resource={text}
          className="flex text-white justify-center text-xl mx-auto text-center order-3 sm:order-2"
        />
        <div className="pb-16 pt-8 order-3">
          <div className="flex flex-col justify-center">
            <CMSButton
              onClick={clickHandler}
              buttonText={getRawText(ctaButtonText)}
              buttonClass="rounded-full py-2 px-6 mx-2"
              buttonBgClass="bg-primary rounded-lg cursor-pointer"
              tracking={{
                expectedResult: "header cta button clicked",
                innerText: getRawText(ctaButtonText),
                resourceId: ctaButtonText.id,
                type: "button",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

type BrandHeaderBackgroundImage4Props = {
  image: CMSImageResource;
  title: CMSText;
  text: CMSText;
  ctaButtonText: HasOnClick<CMSText>;
};

export const BrandHeaderBackgroundImage4StorybookProps: CMSComponentExampleProps<BrandHeaderBackgroundImage4Props> =
  {
    image: {
      defaultTags: [{ type: "header_image" }],
      type: "image_resource",
      value: placeholderImageUrlWithText(400, 600, "image of content"),
    },
    title: {
      defaultTags: [{ type: "header_title" }],
      type: "text",
      value: "Buy this thing and you'll get this benefit!",
    },
    text: {
      defaultTags: [{ type: "header_subtitle" }],
      type: "text",
      value: "These are all of the reasons you should buy this thing...",
    },
    ctaButtonText: {
      type: "text",
      value: "Register now",
      metadata: {
        onClick: "consoleLog",
      },
    },
    component: {
      required_data: {
        ctaButtonText: {
          metadata: {
            onClick: "consoleLog",
          },
        },
      },
    },
  };

export default BrandHeaderBackgroundImage4;
