import { WebinarInteraction } from "@combined-curiosity/collector-js";

type WebinarState = {
  startsAt?: Date;
  beforeStartTime?: boolean;
  leadId?: string;
  webinarRegistrationId?: string;
  webinarInstanceId?: string;
  currentTimeInSeconds: number;
  videoDuration?: number;
  videoCompleted?: boolean;
  isReplay?: boolean;
  viewType: WebinarInteraction["viewType"];
  pastWelcomeScreen: boolean;
};

type WEBINAR_STARTS_AT = {
  type: "WEBINAR_STARTS_AT";
  payload: { value: Date };
};
type SET_LEAD_ID = { type: "SET_LEAD_ID"; payload: { value: string } };
type SET_WEBINAR_REGISTRATION_ID = {
  type: "SET_WEBINAR_REGISTRATION_ID";
  payload: { value: string };
};
type SET_WEBINAR_INSTANCE_ID = {
  type: "SET_WEBINAR_INSTANCE_ID";
  payload: { value: string };
};
type COMPUTE_BEFORE_START_TIME = {
  type: "COMPUTE_BEFORE_START_TIME";
  payload: {};
};
type SET_CURRENT_TIME = {
  type: "SET_CURRENT_TIME";
  payload: { value: number };
};
type SET_VIDEO_DURATION = {
  type: "SET_VIDEO_DURATION";
  payload: { value: number };
};
type SET_WEBINAR_REPLAY = {
  type: "SET_WEBINAR_REPLAY";
  payload: { value: boolean };
};
type SET_PAST_WELCOME_SCREEN = {
  type: "SET_PAST_WELCOME_SCREEN";
  payload: { value: boolean };
};

type WebinarAction =
  | SET_WEBINAR_REPLAY
  | WEBINAR_STARTS_AT
  | COMPUTE_BEFORE_START_TIME
  | SET_LEAD_ID
  | SET_WEBINAR_REGISTRATION_ID
  | SET_WEBINAR_INSTANCE_ID
  | SET_CURRENT_TIME
  | SET_VIDEO_DURATION
  | SET_PAST_WELCOME_SCREEN;

export const webinarReducer = (
  state: WebinarState,
  action: WebinarAction
): WebinarState => {
  switch (action.type) {
    case "WEBINAR_STARTS_AT":
      return {
        ...state,
        startsAt: action.payload.value,
        beforeStartTime: action.payload.value > new Date(),
      };
    case "SET_WEBINAR_REPLAY":
      return {
        ...state,
        isReplay: action.payload.value,
        viewType: action.payload.value ? "replay" : "live",
      };
    case "COMPUTE_BEFORE_START_TIME":
      return {
        ...state,
        beforeStartTime: state.startsAt && state.startsAt > new Date(),
      };
    case "SET_LEAD_ID":
      return {
        ...state,
        leadId: action.payload.value,
      };
    case "SET_WEBINAR_REGISTRATION_ID":
      return {
        ...state,
        webinarRegistrationId: action.payload.value,
      };
    case "SET_WEBINAR_INSTANCE_ID":
      return {
        ...state,
        webinarInstanceId: action.payload.value,
      };
    case "SET_CURRENT_TIME":
      return {
        ...state,
        currentTimeInSeconds: action.payload.value,
        videoCompleted: action.payload.value === state.videoDuration,
      };
    case "SET_VIDEO_DURATION":
      return {
        ...state,
        videoDuration: action.payload.value,
        videoCompleted: action.payload.value === state.currentTimeInSeconds,
      };
    case "SET_PAST_WELCOME_SCREEN":
      return {
        ...state,
        pastWelcomeScreen: action.payload.value,
      };
  }
};
