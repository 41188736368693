import { WebinarInteraction } from "@combined-curiosity/collector-js";
import { MessageWithReactionsAndWebinarRegistrations } from "../../../types";
import { RealtimeMessagesReturn } from "./useRealtimeMessages";

type MessageReactionsType = {
  message: MessageWithReactionsAndWebinarRegistrations;
  currentLeadId?: string;
  toggleReaction: RealtimeMessagesReturn["toggleReaction"];
  allowReaction?: boolean;
  viewType: WebinarInteraction["viewType"];
  onAdd: () => void;
};

export const MessageReactions = ({
  message,
  currentLeadId,
  toggleReaction,
  allowReaction = true,
  viewType,
  onAdd,
}: MessageReactionsType) => {
  const reactions: { [reaction: string]: number } = {};

  message.message_reactions.forEach((r) => {
    if (reactions[r.reaction]) {
      reactions[r.reaction] += 1;
    } else {
      reactions[r.reaction] = 1;
    }
  });

  return (
    <div data-component="MessageReactions" className="">
      <div className="flex justify-end">
        {Object.keys(reactions).map((r) => {
          const reaction = r;
          const count = reactions[reaction];

          return (
            <div key={r} className="relative">
              <div
                className={
                  `z-10 bg-slate-50-100 rounded px-1` +
                  (allowReaction
                    ? " hover:cursor-pointer hover:bg-slate-100"
                    : "")
                }
                onClick={() =>
                  allowReaction &&
                  toggleReaction(message, r, currentLeadId || "", viewType)
                }
              >
                {r}
                <span className="text-sm text-slate-600 pl-1">
                  {count > 1 ? count : ""}
                </span>
              </div>
            </div>
          );
        })}

        <div
          className={
            `z-10 rounded px-1 text-slate-600` +
            (allowReaction ? " hover:cursor-pointer hover:bg-slate-100" : "")
          }
        >
          <svg
            onClick={onAdd}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
