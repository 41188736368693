import {
  ImageRender,
  TextRender,
  RichTextRender,
} from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSImageTitleAndText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { storybookImageResource } from "stories/helpers/storybookImageResource";

const ImageWithTitleAndText2 = ({
  imagesWithTitleAndText,
}: InjectableComponentProps<ImageWithTitleAndText2Props>) => {
  return (
    <div className="flex flex-col flex-wrap md:flex-row gap-1 ">
      {imagesWithTitleAndText?.resources.map((entry) => (
        <>
          <div
            key={JSON.stringify(entry)}
            className="flex flex-col w-full md:w-1/3 m-auto"
          >
            <picture className="">
              <ImageRender resource={entry.image} />
            </picture>
            <h2 className="font-bold text-md pt-4 pb-2">
              <TextRender resource={entry.title} />
            </h2>
            <RichTextRender resource={entry.text} className="text-sm pb-10" />
          </div>
        </>
      ))}
    </div>
  );
};

type ImageWithTitleAndText2Props = {
  imagesWithTitleAndText: CMSCollection<CMSImageTitleAndText>;
};

export const ImageWithTitleAndText2StorybookProps: CMSComponentExampleProps<ImageWithTitleAndText2Props> =
  {
    imagesWithTitleAndText: {
      type: "imageTitleAndText", // if this doesn't support richtext for the text portion, we need to switch types or create a new one
      retrieveAll: true,
      values: [
        {
          image: {
            image: storybookImageResource(100, 80, "logo"),
          },
          title: "Video lessons you can watch as many times as you want",
          text: "Start right away get all your lessons immediately. <b>There are no live or zoom classes<b>",
        },
        {
          image: {
            image: storybookImageResource(100, 80, "logo"),
          },
          title: "Video lessons you can watch as many times as you want",
          text: "Start right away get all your lessons immediately. <b>There are no live or zoom classes<b>",
        },
        {
          image: {
            image: storybookImageResource(100, 80, "logo"),
          },
          title: "Video lessons you can watch as many times as you want",
          text: "Start right away get all your lessons immediately. <b>There are no live or zoom classes<b>",
        },
        {
          image: {
            image: storybookImageResource(100, 80, "logo"),
          },
          title: "Video lessons you can watch as many times as you want",
          text: "Start right away get all your lessons immediately. <b>There are no live or zoom classes<b>",
        },
      ],
    },
  };

export default ImageWithTitleAndText2;
