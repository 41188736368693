import {
  WebinarInteraction,
  WebinarInteractionTypes,
} from "@combined-curiosity/collector-js";
import { useCallback, useEffect, useState } from "react";
import {
  definitions,
  MessageWithReactionsAndWebinarRegistrations,
  WithReplies,
} from "@/types/index";
import xmsSupabase, { clientSideSupabase } from "@/utils/xmsSupabase";
import { useTracking } from "../../trackEvent";

type UseRealtimeMessagesProps = {
  roomType: "webinar_instance";
  roomId?: string;
  roomIds?: Array<string>;
  preRecordedMessageIdsToFetch?: Array<definitions["messages"]["id"]>;
  webinarInstanceId?: string;
  webinarId?: string;
};

export type RealtimeMessagesReturn = {
  messages: Array<WithReplies<MessageWithReactionsAndWebinarRegistrations>>;
  postMessage: (
    message: string,
    opts: object,
    roomId: string,
    viewType: WebinarInteraction["viewType"]
  ) => void;
  toggleReaction: (
    message: MessageWithReactionsAndWebinarRegistrations,
    reaction: string,
    currentLeadId: string,
    viewType: WebinarInteraction["viewType"]
  ) => void;
};

export const useRealtimeMessages = ({
  roomType,
  roomId,
  roomIds,
  preRecordedMessageIdsToFetch,
  webinarInstanceId,
  webinarId,
}: UseRealtimeMessagesProps): RealtimeMessagesReturn => {
  const [messages, setMessages] = useState<
    Array<WithReplies<MessageWithReactionsAndWebinarRegistrations>>
  >([]);
  const [messagesWithReplies, setMessagesWithReplies] = useState<
    Array<WithReplies<MessageWithReactionsAndWebinarRegistrations>>
  >([]);
  const [preRecordedMessages, setPreRecordedMessages] = useState<
    Array<WithReplies<MessageWithReactionsAndWebinarRegistrations>>
  >([]);
  const [usePolling, setUsePolling] = useState(false); // usePolling is a fallback to use HTTP polling if websockets fail
  const { trackWebinarEvent } = useTracking();

  useEffect(() => {
    (async () => {
      if (!preRecordedMessageIdsToFetch) {
        return;
      }

      const preRecordedMessages =
        xmsSupabase.getData<MessageWithReactionsAndWebinarRegistrations>(
          await clientSideSupabase.supabase
            .from("messages")
            .select(
              `
        *,
        webinar_registrations!messages_webinar_registration_id_fkey (*),
        message_reactions (*)
        `
            )
            .in("id", preRecordedMessageIdsToFetch)
            .order("video_offset_seconds")
        );

      setPreRecordedMessages(preRecordedMessages);
      fetchMessages();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preRecordedMessageIdsToFetch]);

  const toggleReaction: RealtimeMessagesReturn["toggleReaction"] = async (
    message,
    reaction,
    currentLeadId,
    viewType
  ) => {
    const reactionExists = message.message_reactions.find(
      (r) => r.reaction === reaction && r.lead_id === currentLeadId
    );
    if (webinarInstanceId && webinarId) {
      trackWebinarEvent(
        {
          interaction: WebinarInteractionTypes.reacted_to_message.name,
          message: message.message,
          viewType,
          webinarInstanceId,
          reaction,
          webinarId,
          anonymousWebinarViewer: false,
        },
        message.id
      );
    }
    if (reactionExists) {
      await clientSideSupabase.supabase
        .from("message_reactions")
        .delete()
        .match({ id: reactionExists.id });
    } else {
      await clientSideSupabase.supabase.from("message_reactions").insert({
        message_id: message.id,
        reaction,
        lead_id: currentLeadId,
      });
    }

    fetchMessages();
  };

  const fetchMessages = useCallback(async () => {
    if (!roomId && !roomIds) {
      return;
    }

    const fetchedMessages =
      xmsSupabase.getData<MessageWithReactionsAndWebinarRegistrations>(
        await clientSideSupabase.supabase
          .from("messages")
          .select(
            `
        *,
        webinar_registrations!messages_webinar_registration_id_fkey (*),
        message_reactions (*)
        `
          )
          .in("room_id", roomIds || [roomId])
          .order("video_offset_seconds")
      );

    const nonMutedMessages = fetchedMessages.filter(
      (m) => !m.webinar_registrations?.is_muted
    );
    const sortedMessages = [nonMutedMessages, preRecordedMessages]
      .flat()
      .sort((a, b) => a.video_offset_seconds - b.video_offset_seconds);

    // dedupe messages (sometimes get double in usePolling mode due to reactions)
    const uniqueMessageIds = [...new Set(sortedMessages.map((m) => m.id))];
    const uniqueMessages: {
      [messageId: string]: MessageWithReactionsAndWebinarRegistrations;
    } = {};
    for (const uniqueMessageId of uniqueMessageIds) {
      const message = sortedMessages.find((m) => m.id === uniqueMessageId);
      if (!message) {
        continue;
      }

      uniqueMessages[uniqueMessageId] = message;
    }

    setMessages(
      Object.values(uniqueMessages).sort(
        (a, b) => a.video_offset_seconds - b.video_offset_seconds
      )
    );
  }, [roomId, roomIds, preRecordedMessages]);

  useEffect(() => {
    // fetch messages on first load
    fetchMessages();
  }, [fetchMessages]);

  useEffect(() => {
    const messagesWithParentId = messages.filter((m) => m.parent_message_id);
    const messagesWithoutParentId = messages.filter(
      (m) => !m.parent_message_id
    );

    messagesWithParentId.forEach((childMessages) => {
      const parentMessage = messagesWithoutParentId.find(
        (parentMessage) => parentMessage.id === childMessages.parent_message_id
      );
      if (!parentMessage) {
        return;
      }
      parentMessage.replies = parentMessage.replies || [];
      parentMessage.replies.push(childMessages);

      // dedupe replies
      const uniqueMessageIds = [
        ...new Set(parentMessage.replies.map((m) => m.id)),
      ];
      const uniqueMessages: {
        [messageId: string]: MessageWithReactionsAndWebinarRegistrations;
      } = {};
      for (const uniqueMessageId of uniqueMessageIds) {
        const message = parentMessage.replies.find(
          (m) => m.id === uniqueMessageId
        );
        if (!message) {
          continue;
        }

        uniqueMessages[uniqueMessageId] = message;
      }

      // this is a little gross and represents an issue with the data model or our processing, but we're rolling with it for now
      parentMessage.replies = Object.values(uniqueMessages).sort(
        (a, b) => a.video_offset_seconds - b.video_offset_seconds
      );
    });

    setMessagesWithReplies(messagesWithoutParentId);
  }, [messages]);

  useEffect(() => {
    if (usePolling) {
      const interval = setInterval(() => {
        fetchMessages();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [usePolling, fetchMessages]);

  const updateSingleMessage = useCallback(async (id: string) => {
    const message =
      xmsSupabase.ensureOne<MessageWithReactionsAndWebinarRegistrations>(
        await clientSideSupabase.supabase
          .from("messages")
          .select(
            `
      *,
      webinar_registrations!messages_webinar_registration_id_fkey (*),
      message_reactions (*)
      `
          )
          .eq("id", id)
      );

    setMessages((messages) => {
      const existingMessage = messages.find((m) => m.id === id);
      if (existingMessage) {
        return messages.map((m) => (m.id === id ? message : m));
      }
      return [...messages, message];
    });
  }, []);

  useEffect(() => {
    if (!roomId && !roomIds) {
      return;
    }

    clientSideSupabase.supabase
      .channel("changes")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "messages",
        },
        (payload) => {
          if (
            payload.new.room_id === roomId ||
            roomIds?.includes(payload.new.room_id)
          ) {
            updateSingleMessage(payload.new.id);
          }
        }
      )
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "webinar_registrations",
        },
        (_payload) => {
          fetchMessages();
        }
      )
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "message_reactions",
        },
        (payload) => {
          if (payload.new.message_id) {
            setMessages((m) =>
              m.map((message) => {
                if (message.id === payload.new.message_id) {
                  return {
                    ...message,
                    message_reactions: [
                      ...message.message_reactions,
                      payload.new as definitions["message_reactions"],
                    ],
                  };
                }
                return message;
              })
            );
          }
        }
      )
      .subscribe((status) => {
        if (status === "CLOSED") {
          return;
        }

        if (status === "SUBSCRIBED") {
          console.log("subscribed to changes channel");
          setUsePolling(false);
        } else {
          console.log("changes channel not working, falling back to polling");
          setUsePolling(true);
        }
      });

    return () => {
      clientSideSupabase.supabase.channel("changes").unsubscribe();
    };
  }, [roomId, roomIds, updateSingleMessage, fetchMessages]);

  const postMessage: RealtimeMessagesReturn["postMessage"] = async (
    message,
    opts,
    roomId,
    viewType
  ) => {
    const { message: createdMessage } = await fetch(
      "/api/webinar/sendMessage/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message,
          roomId,
          roomType,
          webinarInstanceId,
          opts,
        }),
      }
    ).then((res) => res.json());

    if (webinarInstanceId && webinarId) {
      trackWebinarEvent(
        {
          interaction: WebinarInteractionTypes.comment.name,
          viewType,
          webinarInstanceId,
          message,
          webinarId,
          anonymousWebinarViewer: false,
        },
        createdMessage.id
      );
    }

    if (usePolling) {
      fetchMessages();
    }
  };

  return { messages: messagesWithReplies, postMessage, toggleReaction };
};
