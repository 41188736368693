import { placeholderImageUrlWithText } from "stories/helpers";
import {
  getRawText,
  ImageRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSImageResource,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const Footer1 = ({
  logo,
  copyrightText,
  privacyPolicyUrl,
}: InjectableComponentProps<Footer1Props>) => {
  return (
    <div className="flex flex-row justify-around items-center p-4 bg-gray-100">
      <ImageRender resource={logo} imgClassName="w-24 object-contain" />
      <div className="flex flex-col md:flex-row text-xs flex-no-wrap my-auto text-center">
        <p>
          &copy; <TextRender resource={copyrightText} />
        </p>
        <a href={getRawText(privacyPolicyUrl)} className="hover:underline ml-4">
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

type Footer1Props = {
  logo: CMSImageResource;
  copyrightText: CMSText;
  privacyPolicyUrl: CMSText;
};

export const Footer1StorybookProps: CMSComponentExampleProps<Footer1Props> = {
  logo: {
    type: "image_resource",
    value: placeholderImageUrlWithText(200, 200, "logo"),
  },
  copyrightText: {
    type: "text",
    value: "Copyright Combined Curiosity",
  },
  privacyPolicyUrl: {
    type: "text",
    value: "https://combinedcuriosity.com/privacy",
  },
};

export default Footer1;
