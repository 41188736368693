import { placeholderImageUrlWithText } from "stories/helpers";
import { ImageRender, TextRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSImageResource,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const EndorserLogos2 = ({
  images,
  stickyNoteTitle,
  stickyNoteText,
}: InjectableComponentProps<EndorserLogos2Props>) => {
  return (
    <div>
      <div className="flex flex-col lg:hidden bg-secondary text-bg-secondary p-4 shadow">
        <h2 className="flex justify-center text-xl text-center">
          {" "}
          <TextRender resource={stickyNoteTitle} />{" "}
        </h2>
        <p className="flex justify-center mx-4 text-center">
          <TextRender resource={stickyNoteText} />
        </p>
      </div>

      <div
        data-component="EndorserLogos1"
        className="flex flex-col justify-evenly p-8 bg-tertiary relative lg:mt-0"
      >
        <div
          className={`hidden lg:flex absolute flex-col rounded-full h-48 w-48 text-bg-tertiary justify-start 
          bg-tertiary m-auto -top-44 md:-top-44 right-[50%] translate-x-1/2  lg:right-[33vw] mt-16`}
        >
          <h2 className="flex justify-center text-xl text-center mt-8">
            {" "}
            <TextRender resource={stickyNoteTitle} />
          </h2>
          <p className="flex justify-center text-sm md:text-xs mx-4 text-center">
            <TextRender resource={stickyNoteText} />
          </p>
        </div>

        <div className="flex flex-row flex-wrap justify-center">
          <div className="flex justify-around flex-wrap flex-row gap-16 md:flex-nowrap z-20">
            {images?.resources.map((image) => (
              <ImageRender
                className="flex justify-center items-center opacity-80 hover:opacity-100 w-[120px] h-[100px] object-contain"
                key={JSON.stringify(image)}
                resource={image}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

type EndorserLogos2Props = {
  images: CMSCollection<CMSImageResource>;
  stickyNoteTitle: CMSText;
  stickyNoteText: CMSText;
};

export const EndorserLogos2StorybookProps: CMSComponentExampleProps<EndorserLogos2Props> =
  {
    images: {
      type: "image_resource",
      retrieveAll: true,
      values: [
        {
          value: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
        {
          value: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
        {
          value: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
        {
          value: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
      ],
    },
    stickyNoteTitle: {
      type: "text",
      value: "Note:",
    },
    stickNoteText: {
      type: "text",
      value: "this is a sticky note, wow, what a note.",
    },
  };

export default EndorserLogos2;
