import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSRichText,
  InjectableComponentProps,
} from "../../components/cms/types/cmsTypes";
import { RichTextRender } from "../../utils/cms/renderableResources";

const AboutInstallmentPlan1 = ({
  title,
  text,
}: InjectableComponentProps<AboutInstallmentPlan1Props>) => {
  return (
    <div className="bg-tertiary w-1/2 m-auto rounded-xl p-8">
      <RichTextRender
        resource={title}
        className="text-center text-tertiary-text"
      />
      <RichTextRender resource={text} className="text-center mt-8" />
    </div>
  );
};

type AboutInstallmentPlan1Props = {
  title: CMSRichText;
  text: CMSRichText;
};

export const AboutInstallmentPlan1StorybookProps: CMSComponentExampleProps<AboutInstallmentPlan1Props> =
  {
    title: {
      type: "richtext",
      metadata: {
        value: "About my installment plan",
      },
    },
    text: {
      type: "richtext",
      metadata: {
        value: `I understand not everyone can pay all at once. So we offer a payment plan.
        <br><br><b>NOTE<b> This is not a subscription that can be stopped at any time.`,
      },
    },
  };

export default AboutInstallmentPlan1;
