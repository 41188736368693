import React from "react";
import { AiFillFacebook } from "react-icons/ai";
import { FacebookShareButton } from "react-share";
import trackEvent from "../../trackEvent";

/**
 * Prop example (since subcomponents don't have Storybooks): 
 // Example in the LMS
  <ShareToFacebook 
    className="self-center md:self-start"
    url={`${brandConfig.brandHomepageLink()}?utm_source=fb&utm_medium=social_share&utm_campaign=lms_sharing&utm_content=${utm_content}`} 
    hashtag={brandConfig.brandName?.replace(/\s/g, '')} 
  />
  // Hardcoded example
  <ShareToFacebook 
    url={`https://www.pianoinaflash.com/?utm_source=fb&utm_medium=social_share&utm_campaign=lms_sharing&utm_content=`} 
    hashtag="pianoinaflash" 
  />
 */
export default function ShareToFacebook({
  className,
  url,
  hashtag,
  buttonText = "Share",
}: {
  className?: string;
  url: string;
  hashtag: string;
  buttonText?: string;
}) {
  const handleClick = () => {
    trackEvent({
      action: "click",
      meta: {
        destination: url,
        type: "link",
      },
    });
  };

  return (
    <span
      className={`text-white font-bold rounded bg-blue-500 hover:bg-blue-700 p-2 w-fit ${className}`}
      onClick={handleClick}
    >
      <FacebookShareButton url={url} hashtag={`#${hashtag}`}>
        <span className="whitespace-nowrap flex flex-row w-fit items-center">
          <AiFillFacebook className="mr-3" />
          {buttonText}
        </span>
      </FacebookShareButton>
    </span>
  );
}
