import { RichTextRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { CMSRichText, InjectableComponentProps } from "../cms/types/cmsTypes";

const Banner2 = ({ text }: InjectableComponentProps<Banner2Props>) => {
  return (
    <div className="flex items-center justify-around bg-secondary p-4">
      <RichTextRender resource={text} className="text-bg-secondary" />
    </div>
  );
};

type Banner2Props = {
  text: CMSRichText;
};

export const Banner2StorybookProps: CMSComponentExampleProps<Banner2Props> = {
  text: {
    type: "richtext",
    metadata: {
      value: "I am a banner announcement",
    },
  },
};

export default Banner2;
