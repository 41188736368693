const generateClientSideUUID = () => {
  if (
    typeof window !== "undefined" &&
    typeof window.crypto?.randomUUID === "function"
  ) {
    return window.crypto.randomUUID();
  }

  return `${Math.random().toString(36).substring(2)}.${Date.now()}`;
};

export { generateClientSideUUID };
