import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { CMSCollection } from "../cms/types/cmsTypes";
import {
  CMSImageTitleAndText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { TextRender, ImageRender } from "../../utils/cms/renderableResources";
import { storybookImageResource } from "stories/helpers/storybookImageResource";

const SmallImageWithTitleAndSubtitle2 = ({
  imageTitleAndText,
}: InjectableComponentProps<SmallImageWithTitleAndSubtitle2Props>) => {
  return (
    <div
      data-component="SmallImageWithTitleAndSubtitle2"
      className="flex justify-center max-w-6xl m-auto my-20"
    >
      <div className="flex justify-around flex-wrap gap-2 lg:flex-nowrap sm:flex-row md:gap-2">
        {imageTitleAndText?.resources.map((entry) => {
          return (
            <div
              key={JSON.stringify(entry)}
              className="flex flex-col justify-center items-center scale-75 lg:scale-100 md:mx-12"
            >
              <ImageRender
                resource={entry.image}
                className="mb-2"
                imgClassName="w-24 h-24 object-contain"
              />
              <p className="font-bold mb-2 text-2xl lg:text-xl">
                <TextRender resource={entry.title} />
              </p>
              <p className="text-lg">
                <TextRender resource={entry.text} />
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

type SmallImageWithTitleAndSubtitle2Props = {
  imageTitleAndText: CMSCollection<CMSImageTitleAndText>;
};

export const SmallImageWithTitleAndSubtitle2StorybookProps: CMSComponentExampleProps<SmallImageWithTitleAndSubtitle2Props> =
  {
    imageTitleAndText: {
      type: "imageTitleAndText",
      retrieveAll: true,
      values: [
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "John Smith",
          text: "Owner",
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "John Smith",
          text: "Owner",
        },
      ],
    },
  };

export default SmallImageWithTitleAndSubtitle2;
