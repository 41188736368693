import CMSButton from "./CMSButton";
import Cookies from "js-cookie";
import InlineStatusBanner from "./InlineStatusBanner";
import LeadBody from "../../utils/types/LeadBody";
import trackEvent from "../trackEvent";
import { ChangeEvent, useState } from "react";
import Requires from "../../internal_components/Requires";

type Props = {
  brandId: string;
  clientSideId: string;
  apiUrl: string;
};

const EmailSubscriptionInputAndButton = ({
  brandId,
  clientSideId,
  apiUrl,
}: Props) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubscribe = async (e?: ChangeEvent<HTMLInputElement>) => {
    e?.preventDefault();
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Invalid Email");
      return;
    }
    setLoading(true);

    const cookies = Cookies && Cookies.get();
    const userAgent = navigator.userAgent;

    const leadBody: LeadBody = {
      brandId,
      clientSideId,
      cookies: cookies,
      email: email,
      type: "email_subscription",
      transactionUrl: window.location.href,
      userAgent: userAgent,
    };

    trackEvent({
      action: "newsletter_signup",
      clientSubmissionUniqueId: clientSideId,
      email: email,
      meta: {
        // TODO: work with the marketing team here on how this is used + implement event
        // forwarding to drip using signupworkflowname to direct which email cadence
        // the user goes down. we shouldn't open this up to be optional
        signupWorkflowName: "unknown",
      },
    });

    const resp = await fetch(`${apiUrl}/leads/createOrUpdateLead/`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(leadBody),
    });

    if (resp.status >= 400) {
      const apiResponse = await resp.json();

      if (apiResponse["status"]["did_you_mean"]) {
        setSuccess("");
        setError(
          `Oops! That doesn't look like a valid email address. Did you mean ${apiResponse["status"]["did_you_mean"]}?`
        );
      } else {
        setSuccess("");
        setError("Invalid Email");
      }
    } else {
      setEmail("");
      setError("");
      setSuccess("Email subscription successful!");
    }
    setLoading(false);
  };

  return (
    <div className="pb-4">
      <Requires value={!success}>
        <form className="flex justify-center gap-4 pb-4 lg:gap-8 align-center flex-col lg:flex-row items-center m-auto">
          <input
            disabled={loading}
            className="flex align-center border-2 border-gray-200 w-58 h-10 rounded-sm placeholder-gray-500 pl-2"
            placeholder="Email"
            value={email}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
          <CMSButton
            buttonBgClass="bg-primary rounded-lg cursor-pointer"
            buttonClass="rounded-full py-2 px-6"
            buttonText="Subscribe"
            disabled={loading}
            onClick={handleSubscribe}
            tracking={{
              expectedResult: "newsletter_signup",
              innerText: "Subscribe",
              type: "button",
            }}
          />
        </form>
      </Requires>

      <div>
        {success && <InlineStatusBanner status="success" message={success} />}
        {error && <InlineStatusBanner status="error" message={error} />}
      </div>
    </div>
  );
};

export default EmailSubscriptionInputAndButton;
