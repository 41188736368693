import {
  RichTextRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { useClickHandler } from "../cms/PageContext";
import {
  CMSCollection,
  CMSImageResource,
  CMSOffer,
  CMSRichText,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const SplitPurchaseHeader2 = ({
  title,
  subtitle,
  bullets,
  singlePaymentOffer,
  installmentsOffer,
  component,
}: InjectableComponentProps<SplitPurchaseHeaderProps>) => {
  const singlePaymentOfferClickHandler = useClickHandler(
    component,
    "singlePaymentOffer"
  );
  const installmentsOfferClickHandler = useClickHandler(
    component,
    "installmentsOffer"
  );

  const priceBoxStyle =
    "relative rounded-3xl border-secondary border-4 flex flex-col items-center p-4 rounded-md w-full";

  const singlePaymentDisplayPriceDollars =
    parseInt(
      singlePaymentOffer.offer?.packages.display_price_in_cents?.toString() ||
        "0"
    ) / 100;
  const singlePaymentPriceDollars =
    parseInt(singlePaymentOffer.offer?.price_in_cents?.toString() || "0") / 100;
  const installmentsPaymentDisplayPriceDollars =
    parseInt(
      installmentsOffer.offer.packages.display_price_in_cents?.toString() || "0"
    ) / 100;
  const installmentsPaymentPriceDollars =
    parseInt(installmentsOffer.offer.price_in_cents.toString() || "0") / 100;
  const numberOfInstallments = parseInt(
    installmentsOffer.offer.number_of_installments_allowed?.toString() || "0"
  );

  const singlePaymentSavings =
    singlePaymentDisplayPriceDollars - singlePaymentPriceDollars;
  const installmentSavings =
    installmentsPaymentDisplayPriceDollars - installmentsPaymentPriceDollars;

  return (
    <div className="flex flex-col justify-center items-center text-center mx-auto md:w-5/6 xl:w-3/5 my-24">
      <div className="border-secondary border-4 rounded-3xl flex flex-col items-center gap-y-8 p-6 mb-8">
        <h2 className="text-3xl font-bold bg-white w-full sm:w-2/3 lg:w-1/2 -mt-14 h-14 flex justify-center items-center">
          <TextRender resource={title} />
        </h2>
        <h3 className="md:w-2/3">
          <TextRender resource={subtitle} />
        </h3>

        <ul className="leading-8">
          {bullets.resources.map((bullet, index) => (
            <li key={index}>
              <RichTextRender resource={bullet} />
            </li>
          ))}
        </ul>
      </div>

      <div className="flex flex-col sm:flex-row justify-around items-center w-full sm:gap-x-4">
        <div className={priceBoxStyle}>
          <p>
            <TextRender resource={singlePaymentOffer.paymentPrompt} />
          </p>
          <h6 className="font-bold text-primary">
            was: <s>${singlePaymentDisplayPriceDollars}</s>
          </h6>
          <h2 className="text-5xl font-bold p-4 text-red-500">
            ${singlePaymentPriceDollars}
          </h2>
          <span className="absolute left-0 top-0 h-full flex -mt-4 lg:mt-0 lg:items-center -ml-4">
            <p className="h-8 bg-red-500 text-white flex items-center p-2 uppercase">
              Save ${singlePaymentSavings}
            </p>
          </span>
          <button
            className="rounded bg-tertiary hover:bg-tertiary-darker text-white text-xl p-4 font-bold uppercase"
            onClick={singlePaymentOfferClickHandler}
          >
            <TextRender resource={singlePaymentOffer.ctaText} />
          </button>
        </div>
        <p>OR</p>
        <div className={`border-primary ${priceBoxStyle}`}>
          <div>
            <TextRender resource={installmentsOffer.paymentPrompt} />
          </div>
          <h2 className="text-5xl font-bold p-4 text-red-500">
            ${installmentsPaymentPriceDollars / numberOfInstallments}/mo
          </h2>
          <span className="absolute right-0 top-0 h-full flex -mt-4 lg:mt-0 lg:items-center -mr-4">
            <p className="h-8 bg-red-500 text-white flex items-center p-2 uppercase">
              Save ${installmentSavings}
            </p>
          </span>
          <h6 className="font-bold uppercase pb-2">no credit check</h6>
          <button
            className="rounded bg-primary hover:bg-primary-darker text-white text-xl p-4 font-bold uppercase"
            onClick={installmentsOfferClickHandler}
          >
            <TextRender resource={installmentsOffer.ctaText} />
          </button>
        </div>
      </div>
    </div>
  );
};

type SplitPurchaseHeaderProps = {
  backgroundImage: CMSImageResource;
  logo: CMSImageResource;
  title: CMSText;
  subtitle: CMSText;
  bullets: CMSCollection<CMSRichText>;
  singlePaymentOffer: CMSOffer;
  installmentsOffer: CMSOffer;
};

export const SplitPurchaseHeader2StorybookProps: CMSComponentExampleProps<SplitPurchaseHeaderProps> =
  {
    title: {
      type: "text",
      value: "Your New [hobby] Journey Begins Here",
    },
    subtitle: {
      type: "text",
      value:
        "Get the inspiration and instruction you need from a caring teacher who caters to all experience levels! Your class comes with",
    },
    bullets: {
      retrieveAll: true,
      type: "richtext",
      values: [
        "Some example bullets",
        "something they get",
        "something they get",
        "something they get",
      ],
    },
    singlePaymentOfferPaymentPrompt: {
      type: "text",
      value: "one easy payment of",
    },
    singlePaymentOfferCtaText: {
      type: "text",
      value: "Get discount",
    },
    singlePaymentOffer: {
      type: "offer",
      offer: {
        price_in_cents: "49900",
        packages: {
          display_price_in_cents: "89900",
        },
      },
      ctaText: "claim bonus & enroll",
    },
    installmentsOfferPaymentPrompt: {
      type: "text",
      value: "10 monthly payments of",
    },
    installmentsOffer: {
      type: "offer",
      offer: {
        number_of_installments_allowed: "10",
        price_in_cents: "79000",
        packages: {
          display_price_in_cents: "89900",
        },
      },
      ctaText: "claim bonus & enroll",
    },
    component: {
      required_data: {
        singlePaymentOffer: {
          metadata: {
            onClick: "consoleLog",
          },
        },
        installmentsOffer: {
          metadata: {
            onClick: "consoleLog",
          },
        },
      },
    },
  };

export default SplitPurchaseHeader2;
