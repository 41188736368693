import { placeholderImageUrlWithText } from "stories/helpers";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { useClickHandler } from "../cms/PageContext";
import {
  CMSImageResource,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { ImageRender, TextRender } from "../../utils/cms/renderableResources";

const ImageWithTitleAndTextAndCTA1 = ({
  title,
  image,
  text,
  ctaText,
  component,
}: InjectableComponentProps<ImageWithTitleAndTextAndCTA1Props>) => {
  const clickHandler = useClickHandler(component, "ctaText");

  return (
    <div className="flex flex-row px-20 gap-x-20 justify-center bg-gray-600 p-12">
      <div className="flex flex-col hidden md:block w-1/3 xl:w-1/4 my-auto">
        <ImageRender resource={image} className="" />
      </div>
      <div className="flex flex-col gap-y-8 my-auto w-4/5 md:w-1/2 xl:w-1/3">
        <h2 className="font-bold text-4xl text-white">
          <TextRender resource={title} />
        </h2>
        <ImageRender
          resource={image}
          className="inline md:hidden w-4/5 mx-auto"
        />
        <p className="text-white">
          <TextRender resource={text} />
        </p>
        <button
          className="font-bold text-lg text-white bg-gray-800 hover:bg-primary p-6 rounded"
          onClick={clickHandler}
        >
          <TextRender resource={ctaText} />
        </button>
      </div>
    </div>
  );
};

type ImageWithTitleAndTextAndCTA1Props = {
  title: CMSText;
  text: CMSText;
  image: CMSImageResource;
  ctaText: CMSText;
};

export const ImageWithTitleAndTextAndCTA1StorybookProps: CMSComponentExampleProps<ImageWithTitleAndTextAndCTA1Props> =
  {
    title: {
      type: "text",
      value: "This is an example title",
    },
    text: {
      type: "text",
      value: "This is some exmaple text.",
    },
    image: {
      type: "image_resource",
      value: placeholderImageUrlWithText(400, 600, "example image"),
    },
    ctaText: {
      type: "text",
      value: "Click me!",
    },
    component: {
      required_data: {
        ctaText: {
          metadata: {
            onClick: "consoleLog",
          },
        },
      },
    },
  };

export default ImageWithTitleAndTextAndCTA1;
