import { storybookImageResource } from "stories/helpers/storybookImageResource";
import {
  getRawText,
  ImageRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { useClickHandler } from "../cms/PageContext";
import {
  CMSImageResource,
  CMSRichText,
  HasOnClick,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import CMSButton from "../SubComponents/CMSButton";

const NavbarCTA1 = ({
  logo,
  ctaText,
  component,
}: InjectableComponentProps<NavbarCTA1Props>) => {
  const clickHandler = useClickHandler(component, "ctaText");

  return (
    <div
      data-component="NavbarCTA1"
      className="flex justify-around max-h-24 p-2 bg-tertiary items-center"
    >
      <ImageRender resource={logo} className="max-h-20 object-contain w-32" />

      <CMSButton
        buttonText={<TextRender resource={ctaText} />}
        onClick={clickHandler}
        tracking={{
          expectedResult: "nav cta button clicked",
          innerText: getRawText(ctaText),
          resourceId: ctaText.id,
          type: "button",
        }}
      />
    </div>
  );
};

type NavbarCTA1Props = {
  logo: CMSImageResource;
  ctaText: HasOnClick<CMSRichText>;
};

export const NavbarCTA1StorybookProps: CMSComponentExampleProps<NavbarCTA1Props> =
  {
    logo: storybookImageResource(100, 80, "logo"),
    ctaText: {
      type: "text",
      metadata: {
        value: "Click here!",
        onClick: "consoleLog",
      },
    },
  };

export default NavbarCTA1;
