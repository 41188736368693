import { useState } from "react";
import { useClickHandler } from "../cms/PageContext";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import CountdownTimer from "../SubComponents/CountdownTimer";
import CMSButton from "../SubComponents/CMSButton";
import {
  CMSSchedule,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  getRawSchedule,
  getRawText,
  TextRender,
} from "../../utils/cms/renderableResources";
import calculateSchedule, {
  ScheduleType,
} from "../../utils/schedule/calculateSchedule";
import OnlyOnClient from "../OnlyOnClient/OnlyOnClient";

// count down to a specific date and time (product releasedAt or next webinar date)
const CountdownTimer1 = ({
  countdownLabel,
  countdownStartDate,
  countdownToDate,
  ctaText,
  component,
}: InjectableComponentProps<CountdownTimer1Props>) => {
  const clickHandler = useClickHandler(component, "ctaText");
  const [timerHasStarted, setTimerHasStarted] = useState(false);
  const [completed, setCompleted] = useState(false);

  const [formState] = useState({
    timezone: "EST",
  });

  if (completed) {
    return null;
  }

  return (
    <div
      className={`bg-tertiary flex flex-col gap-6 md:flex-row justify-center bg-grey-800 -mx-8 
      py-2 md:mx-0 md:p-8 text-3xl text-grey ${
        timerHasStarted ? "block" : "hidden"
      }`}
    >
      <p className="flex justify-center my-auto text-black text-2xl md:text-3xl md:w-1/3">
        <TextRender resource={countdownLabel} />
      </p>
      <OnlyOnClient>
        <CountdownTimer
          countdownStartDate={calculateSchedule(
            new Date(),
            getRawSchedule(countdownStartDate),
            formState.timezone
          )}
          setTimerHasStarted={setTimerHasStarted}
          toDate={calculateSchedule(
            new Date(),
            getRawSchedule(countdownToDate),
            formState.timezone
          )}
          completed={completed}
          setCompleted={setCompleted}
        />
      </OnlyOnClient>
      <CMSButton
        buttonClass="min-w-fit"
        buttonText={<TextRender resource={ctaText} />}
        onClick={clickHandler}
        tracking={{
          expectedResult: "countdown cta button clicked",
          innerText: getRawText(ctaText),
          resourceId: ctaText.id,
          type: "button",
        }}
      />
    </div>
  );
};

type CountdownTimer1Props = {
  countdownLabel: CMSText;
  countdownLabelOnceCompleted: CMSText;
  countdownStartDate: CMSSchedule;
  countdownToDate: CMSSchedule;
  ctaText: CMSText;
};

export const CountdownTimer1StorybookProps: CMSComponentExampleProps<CountdownTimer1Props> =
  {
    countdownLabel: {
      type: "text",
      value: "Countdown to the thing!",
    },
    countdownLabelOnceCompleted: {
      type: "text",
      value: "The thing is over!",
    },
    countdownStartDate: {
      type: "schedule",
      value: {
        singleDateTime: new Date(),
        options: {
          totalDatesToDisplay: 1,
          inTimezone: "EST",
          mode: "single",
        },
      } as ScheduleType,
    },
    countdownToDate: {
      type: "schedule",
      value: {
        singleDateTime: new Date("2025-01-01 00:00:00"),
        options: {
          totalDatesToDisplay: 1,
          inTimezone: "EST",
          mode: "single",
        },
      } as ScheduleType,
    },
    ctaText: {
      type: "text",
      value: "Click me!",
    },
    component: {
      required_data: {
        ctaText: {
          metadata: {
            onClick: "consoleLog",
          },
        },
      },
    },
    // TODO - future implementation idea
    // countdownToNextWebinarWithId: {
    //   value: '123'
    // }
  };

export default CountdownTimer1;
