import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSText,
  InjectableComponentProps,
  CMSCollection,
  CMSImageTitleAndText,
} from "../cms/types/cmsTypes";
import { TextRender, ImageRender } from "../../utils/cms/renderableResources";
import { storybookImageResource } from "stories/helpers/storybookImageResource";

const ContentHighlight1 = ({
  title,
  imagesAndText,
}: InjectableComponentProps<ContentHighlight1Props>) => {
  return (
    <div className="flex flex-col m-auto gap-x-8 items-center">
      <h2 className="text-primary text-center pl-4 sm:pl-16 font-bold text-4xl">
        <TextRender resource={title} />
      </h2>

      <div className="flex flex-wrap justify-center sm:justify-start mt-12 gap-10 sm:gap-x-4 sm:gap-y-24">
        {imagesAndText?.resources.map((entry) => (
          <>
            <div className="flex flex-col justify-center mx-auto sm:flex-row w-full sm:w-3/4 lg:w-1/3 max-w-lg">
              <ImageRender resource={entry.image} className="sm:w-2/3 pr-4" />
              <div className="flex flex-col">
                <p className="text-xl font-bold text-gray-700 whitespace-normal mt-2 w-full -mr-16">
                  <TextRender resource={entry.title} />
                </p>
                <p className="whitespace-normal mt-2 w-full -mr-16">
                  <TextRender resource={entry.text} />
                </p>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

type ContentHighlight1Props = {
  title: CMSText;
  imagesAndText: CMSCollection<CMSImageTitleAndText>;
};

export const ContentHighlight1StorybookProps: CMSComponentExampleProps<ContentHighlight1Props> =
  {
    title: {
      defaultTags: [{ type: "section_title" }],
      type: "text",
      value: "All in an online environment built for you",
    },
    imagesAndText: {
      defaultTags: [{ type: "highlighted_content" }],
      retrieveAll: true,
      type: "imageTitleAndText",
      values: [
        {
          image: storybookImageResource(100, 80, "logo"),
          text: "Be ready for all that is our blog. It is so impactful.",
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          text: `Learn at your own pace lorem ipsum dolor sit amet, consectetur adipiscing elit.lorem ipsum 
          dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        },
      ],
    },
  };

export default ContentHighlight1;
