import { useState } from "react";
import { storybookImageResource } from "stories/helpers/storybookImageResource";
import {
  ImageRender,
  RichTextRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { useClickHandler } from "../cms/PageContext";
import {
  CMSCollection,
  CMSImageResource,
  CMSOffer,
  CMSRichText,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const SplitPurchaseHeader1 = ({
  backgroundImage,
  logo,
  title,
  subtitle,
  bullets,
  singlePaymentOffer,
  installmentsOffer,
  component,
}: InjectableComponentProps<SplitPurchaseHeaderProps>) => {
  const [name, setName] = useState("");
  const singlePaymentOfferClickHandler = useClickHandler(
    component,
    "singlePaymentOffer"
  );
  const installmentsOfferClickHandler = useClickHandler(
    component,
    "installmentsOffer"
  );

  return (
    <div className="flex items-center justify-around p-4 relative overflow-hidden">
      <ImageRender
        resource={backgroundImage}
        className="w-screen h-screen absolute -z-10"
        imgClassName="w-screen h-screen absolute -z-10"
      />

      <div className="bg-white opacity-95 flex flex-col items-center p-4 w-[600px] h-full">
        <ImageRender
          resource={logo}
          className="scale-50 sm:scale-100 -mb-10 md:mb-0"
          imgClassName="w-36"
        />

        <input onChange={(e) => setName(e.target.value)} value={name} />

        <h2 className="text-4xl mt-2 text-center">
          <TextRender resource={title} />
        </h2>
        <h3 className="mt-2">
          <TextRender resource={subtitle} />
        </h3>
        <hr className="w-4/5 my-2" />
        <ul className="my-4">
          {bullets.resources.map((bullet, index) => (
            <li key={index}>
              <RichTextRender resource={bullet} />
            </li>
          ))}
        </ul>
        <hr className="w-4/5 my-2" />

        <div className="flex justify-around w-full gap-6 flex-wrap md:flex-nowrap scale-75 md:scale-100 -mt-12 sm:mt-0">
          <div className="bg-tertiary flex flex-col items-center p-4 md:w-2/3 h-1/4 rounded-md w-full">
            <div>
              <TextRender resource={singlePaymentOffer.paymentPrompt} />
            </div>
            <div className="flex items-center">
              <span className="line-through">
                <TextRender resource={singlePaymentOffer.msrpPrice} />
              </span>
              <span className="text-5xl font-bold pb-2">
                <TextRender resource={singlePaymentOffer.offerPrice} />
              </span>
            </div>
            <button
              className="rounded bg-secondary text-white text-xl p-4"
              onClick={singlePaymentOfferClickHandler}
            >
              <TextRender resource={singlePaymentOffer.ctaText} />
            </button>
          </div>

          <div className="bg-tertiary flex flex-col items-center p-4 w-full md:w-2/3 h-1/3 rounded-md flex-shrink">
            <div>
              <TextRender resource={installmentsOffer.paymentPrompt} />
            </div>
            <div className="flex items-center">
              <span className="line-through">
                <TextRender resource={installmentsOffer.msrpPrice} />
              </span>
              <span className="text-5xl font-bold pb-2">
                <TextRender resource={installmentsOffer.offerPrice} />
              </span>
            </div>
            <button
              className="rounded bg-secondary text-white text-xl p-4"
              onClick={installmentsOfferClickHandler}
            >
              <TextRender resource={installmentsOffer.ctaText} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

type SplitPurchaseHeaderProps = {
  backgroundImage: CMSImageResource;
  logo: CMSImageResource;
  title: CMSText;
  subtitle: CMSText;
  bullets: CMSCollection<CMSRichText>;
  singlePaymentOffer: CMSOffer;
  installmentsOffer: CMSOffer;
};

export const SplitPurchaseHeader1StorybookProps: CMSComponentExampleProps<SplitPurchaseHeaderProps> =
  {
    backgroundImage: storybookImageResource(1900, 1200, "backgroundImage"),
    logo: storybookImageResource(200, 200, "logo"),
    title: {
      type: "text",
      value: "Example Title",
    },
    subtitle: {
      type: "text",
      value: "Example subtitle",
    },
    bullets: {
      retrieveAll: true,
      type: "richtext",
      values: ["Some example bullets", "<b>Hello!</b>"],
    },
    singlePaymentOffer: {
      type: "offer",
      paymentPrompt: "one easy payment",
      msrpPrice: "$899",
      offerPrice: "$499",
      ctaText: "Enroll today!",
    },
    installmentsOffer: {
      type: "offer",
      paymentPrompt: "10 payments",
      msrpPrice: "$899",
      offerPrice: "$79/mo",
      ctaText: "Enroll today!",
    },
    component: {
      required_data: {
        singlePaymentOffer: {
          metadata: {
            onClick: "consoleLog",
          },
        },
        installmentsOffer: {
          metadata: {
            onClick: "consoleLog",
          },
        },
      },
    },
  };

export default SplitPurchaseHeader1;
