/* eslint-disable @next/next/no-img-element */
import { placeholderImageUrlWithText } from "stories/helpers";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSStatsWithImageAndCTA,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  getRawText,
  ImageRender,
  TextRender,
} from "../../utils/cms/renderableResources";

const StatsWithImageAndCTA1 = ({
  info,
}: InjectableComponentProps<StatsWithImageAndCTA1Props>) => {
  function printStatLabel(stat: keyof CMSStatsWithImageAndCTA["stats"]) {
    switch (stat) {
      case "difficultyLevel":
        return "Difficulty Level";
      case "lessonCount":
        return "Number of Lessons";
      case "courseCount":
        return "Number of Courses";
      case "physicalKitNumber":
        return "Physical Kit Number";
      case "traditionalPrice":
        return "Price of traditional lessons";
      case "savingsVsMsrp":
        return "Your savings vs. MSRP";
      default:
        return stat;
    }
  }

  return (
    <div className="flex flex-col sm:flex-row justify-center sm:gap-10 mx-8">
      <div className="mx-auto lg:mx-0 w-1/3 lg:w-1/4 flex flex-col justify-center">
        <ImageRender resource={info.image} className="w-full" />
        <button
          onClick={() => window.open(getRawText(info.ctaLink))}
          className="rounded text-white text-xl font-semibold bg-gray-500 hover:bg-gray-600 p-4 m-4"
        >
          <TextRender resource={info.ctaText} />
        </button>
      </div>
      <div className="mx-auto lg:mx-0 w-2/3 md:w-1/2 flex flex-col justify-center">
        <h2 className="font-light text-3xl">
          <TextRender resource={info.title} />
        </h2>
        <hr className="my-2" />
        <p className="font-light">
          <TextRender resource={info.description} />
        </p>
        <hr className="my-2" />
        <div className="flex flex-col gap-2 text-sm">
          {Object.entries(info.stats).map((s, index) => (
            <div key={`stat ${index}`} className="flex flex-row">
              <div className="flex flex-col w-1/2 font-semibold">
                <TextRender
                  resource={printStatLabel(
                    s[0] as keyof CMSStatsWithImageAndCTA["stats"]
                  )}
                />
              </div>
              <div className="flex flex-col w-1/2">
                <TextRender resource={s[1]} />
              </div>
            </div>
          ))}
        </div>
        <div className="bg-gray-100 text-gray-700 text-sm p-4 pl-6 mt-2">
          <h4 className="font-semibold">
            <TextRender resource={info.bonusLabel} />
          </h4>
          <ul className="mt-2 ml-4 list-disc font-light">
            {getRawText(info.bonusList)
              .split(", ")
              .map((b: string, i: number) => (
                <li key={`also included ${i}`}>
                  <TextRender resource={b} />
                </li>
              ))}
          </ul>
        </div>
        <h3 className="text-gray-500 text-lg font-bold mt-2">
          <TextRender resource={info.lastBonusNote} />
        </h3>
      </div>
    </div>
  );
};

type StatsWithImageAndCTA1Props = {
  info: CMSStatsWithImageAndCTA;
};

export const StatsWithImageAndCTA1StorybookProps: CMSComponentExampleProps<StatsWithImageAndCTA1Props> =
  {
    info: {
      type: "stats_with_image_and_cta",
      image: placeholderImageUrlWithText(600, 800, "Image"),
      ctaText: "ENROLL TODAY",
      ctaLink: "https://www.youtube.com/watch?v=RN0zF_Uh0nY",
      title: "The Black Friday Bundle",
      description: "[description about the bundle]",
      stats: {
        difficultyLevel: "Beginner - Advanced",
        lessonCount: "XXX",
        courseCount: "XXX",
        physicalKitNumber: "9",
        traditionalPrice: "$X,XXX",
        savingsVsMsrp: "$400",
      },
      bonusLabel: "Also included in your enrollment:",
      bonusList:
        "[bonus name] ($XXX value), 30 Day Money Back Guarantee, 24/7 Access to learning environment, Tech and piano support",
      lastBonusNote: "Free shipping in the US ($35 international)",
    },
  };

export default StatsWithImageAndCTA1;
