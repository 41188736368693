import { useEffect, useRef, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";

type CountdownTimerType = {
  color?: string;
  short?: boolean;
  styleType?: string;
  countdownLabelOnceCompleted?: string;
  toDate: Date[] | null; // when it is counting down to
  countdownStartDate?: Date[] | null; // for countdowns that start at a particular time
  setTimerHasStarted?: Function; // parent components can use this to show/hide the countdown if it hasn't started yet
  completed?: Boolean;
  setCompleted?: Function;
};

const CountdownTimer = ({
  color = "primary",
  short = false,
  countdownLabelOnceCompleted,
  countdownStartDate,
  styleType,
  toDate,
  setTimerHasStarted,
  completed,
  setCompleted,
}: CountdownTimerType) => {
  const [now] = useState(new Date());
  const [startDate, setStartDate] = useState<Date>(new Date());
  const countdown = useRef<any>(null);

  useEffect(() => {
    setStartDate(countdownStartDate?.[0] || new Date());
  }, [countdownStartDate]);

  const singleToDate = toDate?.[0];

  // check if countdown should be started once the page is loaded and its ref is defined
  useEffect(() => {
    if (!singleToDate) {
      return;
    }
    if (now.getTime() > new Date(singleToDate).getTime()) {
      setCompleted && setCompleted(true);
    }

    if (now.getTime() < startDate.getTime()) {
      // if startDate hasn't passed, delay start until then
      const milliseconds = startDate.getTime() - now.getTime();

      setTimeout(() => {
        countdown?.current?.start();
        setTimerHasStarted && setTimerHasStarted(true);
      }, milliseconds);
    } else {
      // start the countdown if there is no startDate or if it has already passed
      countdown?.current?.start();
      setTimerHasStarted && setTimerHasStarted(true);
    }
  }, [
    countdown,
    now,
    startDate,
    setTimerHasStarted,
    toDate,
    setCompleted,
    singleToDate,
  ]);

  // change "condensed" bool prop to "styleType" string

  let styles = { timeStyle: "", bgBox: "", timeLbl: "", dividerStyle: "" };

  switch (styleType) {
    case "condensed":
      styles = {
        timeStyle: "flex flex-col text-2xl text-center",
        bgBox: "w-16 rounded-md",
        timeLbl: "text-xs",
        dividerStyle: " pb-5 text-xl font-bold",
      };
      break;
    case "popup":
      styles = {
        timeStyle: "flex flex-col md:text-4xl text-lg text-center",
        bgBox: "md:w-16 w-8 rounded-md",
        timeLbl: "text-xs",
        dividerStyle: "md:pb-2 pb-1 md:text-4xl text-lg font-bold",
      };
      break;
    default:
      styles = {
        timeStyle: `flex flex-col text-2xl md:text-4xl text-bg-${color} text-center w-1/4`,
        bgBox: `bg-${color} m-1 p-1 md:p-2 rounded-md`,
        timeLbl: `text-xs md:text-xl font-bold text-${color}`,
        dividerStyle: "pb-4 md:pb-8",
      };
      break;
  }

  const { timeStyle, bgBox, timeLbl, dividerStyle } = styles;

  const renderer = ({ days = 0, hours = 0, minutes = 0, seconds = 0 }) => {
    const format = (label = "", count = 0) => (
      <div className={timeStyle}>
        <p className={bgBox} suppressHydrationWarning={true}>
          {zeroPad(count)}
        </p>
        <p className={timeLbl}>{label}</p>
      </div>
    );
    const divider = <p className={dividerStyle}>:</p>;

    if (short) {
      return (
        <div className="flex flex-row w-full justify-center items-center">
          {format("", minutes)}
          {divider}
          {format("", seconds)}
        </div>
      );
    }

    return (
      <div className="flex flex-row w-full justify-center items-center">
        {format("Days", days)}
        {divider}
        {format("Hours", hours)}
        {divider}
        {format("Minutes", minutes)}
        {divider}
        {format("Seconds", seconds)}
      </div>
    );
  };

  // if there is no date to count down to
  if (!singleToDate || !toDate) {
    return null;
  }

  if (completed) {
    if (countdownLabelOnceCompleted) {
      return (
        <div className="flex flex-row justify-center bg-blue-100 p-4">
          <h2 className="font-bold text-xl">{countdownLabelOnceCompleted}</h2>
        </div>
      );
    }
    return null;
  }

  return (
    <Countdown
      ref={countdown}
      date={new Date(singleToDate)}
      onComplete={() => setCompleted && setCompleted(true)}
      renderer={renderer}
      autoStart={false}
    />
  );
};

export default CountdownTimer;
