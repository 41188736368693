import { Dispatch, SetStateAction, useEffect, useState } from "react";

type useDirectToWebinarUrlParamsProps = {
  setStartTime: Dispatch<SetStateAction<Date | null>>;
  brandAbbreviation: string;
};

const useDirectToWebinarUrlParams = ({
  setStartTime,
  brandAbbreviation,
}: useDirectToWebinarUrlParamsProps) => {
  const [directToWebinarUrl, setDirectToWebinarUrl] = useState<string>("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const startTimeUrl = urlParams.get("startTime");
    const webinarPageId = urlParams.get("webinarPageId");
    const leadId = urlParams.get("leadId");
    const webinarRegistrationId = urlParams.get("webinarRegistrationId");
    const webinarInstanceId = urlParams.get("webinarInstanceId");
    const isTest = urlParams.get("test");

    if (!startTimeUrl) {
      return;
    }

    setStartTime(new Date(startTimeUrl));

    const params = new URLSearchParams({
      startTime: startTimeUrl,
      leadId: leadId || "",
      webinarRegistrationId: webinarRegistrationId || "",
      webinarInstanceId: webinarInstanceId || "",
    });

    if (isTest === "true") {
      params.append("test", "true");
    }

    let urlPathPrefix = "";
    if (
      ["lvh", "prd-xms-api", "stg-xms-api"].includes(
        window.location.host.split(".")[0]
      ) ||
      window.location.host.includes("localhost")
    ) {
      urlPathPrefix = `/marketing/${brandAbbreviation}`;
    }

    setDirectToWebinarUrl(
      window.location.protocol +
        "//" +
        window.location.host +
        `${urlPathPrefix}/webinar/${webinarPageId}/?${params.toString()}`
    );
  }, [brandAbbreviation, setStartTime]);

  return { directToWebinarUrl };
};

export default useDirectToWebinarUrlParams;
