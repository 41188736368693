import { CMSComponentExampleProps } from "../CMSComponentExampleProps";
import { InjectableComponentProps } from "../types/cmsTypes";

const Children1 = ({
  isDevelopmentMode,
  children,
}: InjectableComponentProps<Children1Props>) => {
  if (isDevelopmentMode) {
    return (
      <div>
        This will be replaced with specific content (list of blog posts, a
        predefined blog entry, etc.)
      </div>
    );
  }

  return children;
};

export default Children1;

type Children1Props = {
  children: JSX.Element;
};

export const Children1StorybookProps: CMSComponentExampleProps<Children1Props> =
  {
    children: {
      type: "children",
      metadata: {},
    },
  };
