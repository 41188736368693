const RibbonBanner = ({
  logo,
  title,
  subtitle,
}: {
  logo?: JSX.Element;
  title?: string;
  subtitle?: string;
}) => {
  const topRibbonFold = (
    <div
      className="bg-primary-darker absolute -left-2 top-7 h-6 w-4 z-0"
      style={{ transform: "translateX(-12%) translateY(-5%) rotate(70deg)" }}
    />
  );

  const bottomRibbonFold = (
    <div
      className="bg-primary-darker absolute right-1 -bottom-2 h-6 w-4 -z-10"
      style={{ transform: "translateX(-12%) translateY(5%) rotate(70deg)" }}
    />
  );

  const iconCloudBackground = (
    <svg
      className="z-20 w-20 h-20 fill-current text-white"
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.611 28.963C1.998 28.808 1.26 26.542 1.26 25.014 1.26 23.758 1.687 22.002 3.843 21.331 1.944 19.879 2.137 18.011 2.618 16.821 3.189 15.404 4.623 13.542 8.018 14.744 5.506 12.054 6.595 9.908 7.683 8.82 8.756 7.746 10.783 6.6 13.417 8.976 13.592 8.847 13.769 8.724 13.95 8.602 12.768 5.299 14.539 3.765 15.928 3.175 17.343 2.575 19.693 2.273 21.244 5.587 21.303 5.576 21.361 5.565 21.422 5.554 21.596 1.99 23.846 1.26 25.366 1.26 26.923 1.26 29.246 1.915 29.322 5.704 29.539 5.752 29.756 5.804 29.971 5.861 31.799 2.797 34.129 3.21 35.471 3.924 36.614 4.531 38.009 5.785 37.468 8.13 39.765 6.948 41.406 8.035 42.259 9.053 43.194 10.166 44.119 12.158 41.917 14.445 45.478 13.483 46.864 15.455 47.316 16.927 47.687 18.149 47.79 19.992 45.826 21.267 48.207 21.882 48.74 23.715 48.74 25.014 48.74 26.557 48.094 28.855 44.389 28.966 44.359 29.116 44.326 29.264 44.293 29.412 47.371 30.913 47.2 33.219 46.64 34.605 46.057 36.047 44.582 37.952 41.05 36.61 43.394 39.234 42.325 41.324 41.256 42.393 40.369 43.28 38.831 44.217 36.84 43.177 36.971 45.315 35.604 46.405 34.482 46.882 33.105 47.466 30.843 47.768 29.294 44.731 29.015 48.048 26.845 48.74 25.366 48.74 23.834 48.74 21.559 48.105 21.417 44.473 21.263 44.446 21.111 44.416 20.959 44.384 19.461 47.607 17.106 47.441 15.699 46.872 14.258 46.288 12.353 44.813 13.692 41.284 11.069 43.626 8.98 42.558 7.912 41.488 7.024 40.602 6.089 39.064 7.127 37.073 4.989 37.205 3.898 35.836 3.422 34.714 2.83 33.317 2.526 31.005 5.717 29.454 5.68 29.291 5.644 29.128 5.611 28.963Z" />
    </svg>
  );

  return (
    <div
      data-component="RibbonBanner1"
      className="relative flex flex-col pt-4 mx-auto w-5/6 md:w-2/3 xl:w-1/2 z-20 text-white italic uppercase"
    >
      <div className="w-full bg-secondary pt-5 z-10" />
      {topRibbonFold}
      <div className="bg-primary relative -mx-4 p-2 shadow-xl">
        <h3 className="text-bg-primary text-center font-extrabold text-4xl lg:text-5xl xl:text-6xl tracking-[0.2em]">
          {title}
        </h3>
        {bottomRibbonFold}
      </div>

      <div className="w-full bg-secondary">
        <p className="text-bg-primary pl-4 py-2 text-2xl lg:text-3xl xl:text-4xl tracking-[0.1em] z-20">
          {subtitle}
        </p>
      </div>

      {logo && (
        <div className="absolute -bottom-6 -right-3">
          {logo}
          {iconCloudBackground}
        </div>
      )}
    </div>
  );
};

export default RibbonBanner;
