import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { CMSRichText, InjectableComponentProps } from "../cms/types/cmsTypes";
import { TextRender } from "../../utils/cms/renderableResources";

const CancelWithin30Days1 = ({
  text,
}: InjectableComponentProps<CancelWithin30DaysProps>) => {
  return (
    <div className="my-16">
      <TextRender resource={text} />
    </div>
  );
};

type CancelWithin30DaysProps = {
  text: CMSRichText;
};

export const CancelWithin30Days1StorybookProps: CMSComponentExampleProps<CancelWithin30DaysProps> =
  {
    text: {
      type: "richtext",
      metadata: {
        value: "I am a CancelWithin30Days component version 1",
      },
    },
  };

export default CancelWithin30Days1;
