import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSImageTitleAndText,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { TextRender } from "../../utils/cms/renderableResources";

const TitleWithSupportingPoints1 = ({
  title,
  titleAndSupportingText,
}: InjectableComponentProps<TitleWithSupportingPoints1Props>) => {
  return (
    <div className="bg-gray-100 px-2">
      <h2 className="text-center text-2xl sm:text-4xl font-bold py-12">
        <TextRender resource={title} />
      </h2>
      <div className="space-y-12 sm:space-y-24 pb-12">
        {titleAndSupportingText.resources.map((entry, index) => (
          <div
            key={JSON.stringify(entry) + index}
            className="max-w-prose m-auto"
          >
            <h2 className="text-center text-xl italic mb-2 font-bold text-gray-700">
              <TextRender resource={entry.title} />
            </h2>
            <p className="text-center">
              <TextRender resource={entry.supportingText} />
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

type TitleWithSupportingPoints1Props = {
  title: CMSText;
  titleAndSupportingText: CMSCollection<CMSImageTitleAndText>;
};

export const TitleWithSupportingPoints1StorybookProps: CMSComponentExampleProps<TitleWithSupportingPoints1Props> =
  {
    title: {
      value: "What makes Scott's classes so special?",
      type: "text",
    },
    titleAndSupportingText: {
      values: [
        {
          title: "An example title",
          supportingText: "An example supporting text",
        },
        {
          title: "An example title",
          supportingText: "An example supporting text",
        },
      ],
      type: "title_and_supporting_text",
    },
  };

export default TitleWithSupportingPoints1;
