import { placeholderImageUrlWithText } from "stories/helpers";
import { useClickHandler } from "../cms/PageContext";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import CMSButton from "../SubComponents/CMSButton";
import {
  CMSImageResource,
  CMSText,
  HasOnClick,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  ImageRender,
  TextRender,
  getRawText,
} from "../../utils/cms/renderableResources";

const CTABanner1 = ({
  component,
  backgroundImage,
  text,
  title,
  ctaButtonText,
}: InjectableComponentProps<CTABanner1Props>) => {
  const clickHandler = useClickHandler(component, "ctaButtonText");

  return (
    <div data-component="CTABanner1" className="flex items-center relative">
      <div className="absolute z-0 w-full h-[30rem] object-contain overflow-hidden">
        <ImageRender
          resource={backgroundImage}
          imgClassName="w-full h-full object-cover"
        />
        <div className="w-full h-full bg-primary opacity-80 absolute top-0 left-0"></div>
      </div>
      <div className="flex m-auto z-10 relative max-w-prose">
        <div className="flex flex-col justify-around h-96 text-bg-primary items-center">
          <h3 className="text-2xl text-center">
            <TextRender resource={title} />
          </h3>
          <p className="text-center">
            <TextRender resource={text} />
          </p>

          <CMSButton
            buttonText={<TextRender resource={ctaButtonText} />}
            onClick={clickHandler}
            tracking={{
              expectedResult: "banner cta button clicked",
              innerText: getRawText(ctaButtonText),
              resourceId: ctaButtonText.id,
              type: "button",
            }}
          />
        </div>
      </div>
    </div>
  );
};

type CTABanner1Props = {
  backgroundImage: CMSImageResource;
  text: CMSText;
  title: CMSText;
  ctaButtonText: HasOnClick<CMSText>;
};

export const CTABanner1StorybookProps: CMSComponentExampleProps<CTABanner1Props> =
  {
    backgroundImage: {
      type: "image_resource",
      value: placeholderImageUrlWithText(1900, 1200, "backgroundImage"),
    },
    title: {
      type: "text",
      value: "Sign up today!",
    },
    text: {
      type: "text",
      value: "You really need to start chasing your dreams",
    },
    ctaButtonText: {
      type: "text",
      value: "Click me!",
    },
  };

export default CTABanner1;
