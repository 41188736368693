import { ButtonClick, LinkClick } from "@combined-curiosity/collector-js";
import trackEvent from "../trackEvent";

type CMSButtonBaseProps = {
  buttonBgClass?: string;
  buttonClass?: string;
  buttonText: string | JSX.Element;
  disabled?: boolean;
  textClass?: string;
  tracking: ButtonClick | LinkClick;
  testId?: string;
};

interface CMSButtonOnClick extends CMSButtonBaseProps {
  href?: never;
  onClick: (event?: any) => void;
  tracking: ButtonClick;
}

interface CMSButtonHref extends CMSButtonBaseProps {
  href: string;
  onClick?: never;
  tracking: LinkClick;
}

type CMSButtonProps = CMSButtonOnClick | CMSButtonHref;

const CMSButton = ({
  buttonBgClass,
  buttonClass,
  buttonText,
  disabled,
  href,
  onClick,
  textClass,
  tracking,
  testId,
}: CMSButtonProps) => {
  const WrappingComponent = href ? "a" : "button";

  const handleClick = () => {
    if (tracking?.type === "link") {
      trackEvent({
        action: "click",
        meta: {
          elementId: tracking?.elementId,
          destination: tracking?.destination || "",
          resourceId: tracking?.resourceId,
          type: tracking?.type,
        },
      });
    } else if (tracking?.type === "button") {
      trackEvent({
        action: "click",
        meta: {
          elementId: tracking?.elementId,
          expectedResult: tracking?.expectedResult,
          innerText: tracking?.innerText,
          resourceId: tracking?.resourceId,
          type: tracking?.type,
        },
      });

      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <WrappingComponent
      data-sub-component="CMSButton"
      className={`relative md:p-4 p-2 rounded group ${buttonClass}`}
      disabled={disabled}
      href={href}
      onClick={handleClick}
      data-testid={testId}
    >
      <span
        className={`bg-quaternary group-hover:brightness-90 absolute top-0 left-0 w-full h-full rounded 
          ${
            disabled
              ? "brightness-75 group-hover:brightness-75 cursor-not-allowed"
              : ""
          } ${buttonBgClass}`}
      />
      <span
        className={`text-bg-quaternary brightness-100 uppercase font-bold text-xl flex justify-center 
          items-center ${disabled && "cursor-not-allowed"} ${textClass}`}
      >
        {buttonText}
      </span>
    </WrappingComponent>
  );
};

export default CMSButton;
