import { storybookImageResource } from "stories/helpers/storybookImageResource";
import { ImageRender, TextRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSImageTitleAndText,
  InjectableComponentProps,
  CMSText,
} from "../cms/types/cmsTypes";

const BrandClientTestimonials1 = ({
  imageTitleAndText,
  title,
}: InjectableComponentProps<BrandClientTestimonials1Props>) => {
  return (
    <div className="flex flex-col m-auto gap-x-12 items-center text-center">
      <h2>
        <TextRender
          resource={title}
          className="text-primary text-4xl font-bold"
        />
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-3 justify-items-center">
        {imageTitleAndText?.resources.map((entry) => {
          return (
            <div key={`${entry.title} index`} className="mx-auto pt-8">
              <div className="w-32 h-32 m-auto">
                <ImageRender
                  resource={entry.image}
                  imgClassName="rounded-full m-auto object-cover"
                />
              </div>
              <div className="flex flex-col text-center -mt-16 px-4 pt-16 h-full max-w-xs">
                <TextRender
                  resource={entry.title}
                  className="font-bold mt-4 mb-2 text-gray-700"
                />
                <TextRender resource={entry.text} className="italic" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

type BrandClientTestimonials1Props = {
  imageTitleAndText: CMSCollection<CMSImageTitleAndText>;
  title: CMSText;
};

export const BrandClientTestimonials1StorybookProps: CMSComponentExampleProps<BrandClientTestimonials1Props> =
  {
    title: {
      type: "text",
      value: "Hear What Our Customers Say",
      defaultTags: [{ type: "section_title" }],
    },
    imageTitleAndText: {
      defaultTags: [{ type: "testimonial" }],
      type: "imageTitleAndText",
      retrieveAll: true,
      values: [
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "Test title",
          text: `You will start to build a fine art language that permeates all the lessons. 
            Nancy is always sweeping up prior info, reinforcing and building new info in a humorous, 
            poetic and dependable style. She has powerful consistency!`,
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "Test title",
          text: `I'm hooked on these lessons with Nancy. I find her use of color to make every 
            painting come alive fascinating. I'm learning a lot and can see myself improving after every lesson.`,
        },
        {
          image: storybookImageResource(100, 80, "logo"),
          title: "Test title",
          text: `Until Nancy, I was basically a failure to launch, always studying but rarely painting, 
            so basically a dreamer. Thanks to Nancy, I paint now. Vivid color is something totally new to me, 
            little did I understand at this phase of my life how much I needed it. The colors permeate my soul`,
        },
      ],
    },
  };

export default BrandClientTestimonials1;
