import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { Parallax, Background } from "react-parallax";
import { CMSImage } from "../CMSImage";
import {
  CMSImageResource,
  CMSTestimonial,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { storybookImageResource } from "stories/helpers/storybookImageResource";

const Testimonial2 = ({
  backgroundImage,
  testimonial,
  brand,
}: InjectableComponentProps<Testimonial2Props>) => {
  return (
    <div>
      <Parallax blur={0} strength={200} className="">
        <Background className="custom-bg object-cover h-full w-full">
          <CMSImage
            image={backgroundImage}
            className="bg-cover w-full h-full z-10 m-auto"
          />
        </Background>
        <div className="z-10 md:p-12 my-16">
          <h2 className="flex justify-center text-sm md:text-2xl italic my-3 text-center">
            {" "}
            {testimonial.quote}{" "}
          </h2>
          <p className="flex justify-center text-sm">
            {testimonial.name} - {brand.name} Student
          </p>
        </div>
      </Parallax>
    </div>
  );
};

type Testimonial2Props = {
  testimonial: CMSTestimonial;
  backgroundImage: CMSImageResource;
};

export const Testimonial2StorybookProps: CMSComponentExampleProps<Testimonial2Props> =
  {
    backgroundImage: storybookImageResource(1920, 1080, "background image"),
    testimonial: {
      type: "testimonial",
      quote: '"This was pretty pretty pretty good!"',
      name: "Larry David",
    },
    brand: {
      name: "brand",
    },
  };

export default Testimonial2;
