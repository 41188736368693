import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSText,
  CMSRichText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  TextRender,
  RichTextRender,
} from "../../utils/cms/renderableResources";
import EmailSubscriptionInputAndButton from "../SubComponents/EmailSubscriptionInputAndButton";

const SubscribeToEmails1 = ({
  title,
  subtitle,
  brand,
  clientSideId,
  apiUrl,
}: InjectableComponentProps<SubscribeToEmails1Props>) => {
  return (
    <div className="flex flex-col bg-gray-400 justify-center text-center">
      <h2 className="flex justify-center text-3xl font-bold text-white py-6">
        <TextRender resource={title} />
      </h2>
      <h2 className="flex justify-center text-white pb-6 text-center">
        <RichTextRender resource={subtitle} />
      </h2>
      <EmailSubscriptionInputAndButton
        brandId={brand?.id}
        clientSideId={clientSideId}
        apiUrl={apiUrl}
      />
    </div>
  );
};

type SubscribeToEmails1Props = {
  title: CMSText;
  subtitle: CMSRichText;
};

export const SubscribeToEmails1StorybookProps: CMSComponentExampleProps<SubscribeToEmails1Props> =
  {
    title: {
      type: "text",
      value: "Let's be friends!",
    },
    brand: {
      id: "test-brand",
    },
    subtitle: {
      type: "richtext",
      value: "Join my piano journey in my blog weekly series",
    },
  };

export default SubscribeToEmails1;
