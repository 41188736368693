import { placeholderImageUrlWithText } from "stories/helpers";
import { ImageRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSCollection,
  CMSImageResource,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";

const EndorserLogos3 = ({
  images,
}: InjectableComponentProps<EndorserLogos3Props>) => {
  return (
    <div className="flex flex-row sm:flex-nowrap justify-evenly gap-16 p-8 bg-secondary">
      {images?.resources.map((image) => (
        <ImageRender
          key={JSON.stringify(image)}
          resource={image}
          className="flex justify-center items-center opacity-80 hover:opacity-100 w-[120px] h-[100px] object-contain"
        />
      ))}
    </div>
  );
};

type EndorserLogos3Props = {
  images: CMSCollection<CMSImageResource>;
};

export const EndorserLogos3StorybookProps: CMSComponentExampleProps<EndorserLogos3Props> =
  {
    images: {
      defaultTags: [{ type: "image_resource" }],
      type: "image_resource",
      retrieveAll: true,
      values: [
        {
          value: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
        {
          value: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
        {
          value: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
        {
          value: placeholderImageUrlWithText(200, 200, "endorser logo"),
        },
      ],
    },
  };

export default EndorserLogos3;
