import { useEffect, useState } from "react";
import CalendarButton from "./CalendarButton";

const CalendarEventButton = ({
  brand = "Piano in a Flash",
  details,
  startTime,
}: {
  brand: string;
  details: string;
  startTime: Date | null;
}) => {
  const [start, setStart] = useState<undefined | Date>();
  const [startUTC, setStartUTC] = useState<undefined | string>();
  const [end, setEnd] = useState<undefined | Date>();
  const [endUTC, setEndUTC] = useState<undefined | string>();

  useEffect(() => {
    if (startTime) {
      setStart(new Date(startTime));

      // regex removes special characters from ISO string to make it compatible with Google and Outlook
      setStartUTC(new Date(startTime).toISOString().replace(/-|:|\.\d+/g, ""));
      setEnd(new Date(startTime.getTime() + 120 * 60 * 1000)); // 2 hours later
      setEndUTC(
        new Date(startTime.getTime() + 120 * 60 * 1000)
          .toISOString()
          .replace(/-|:|\.\d+/g, "")
      );
    }
  }, [startTime]);

  if (!start || !end || !startUTC || !endUTC) {
    return null;
  }

  const title = `${brand} Webinar`;

  const googleParams = new URLSearchParams({
    action: "TEMPLATE",
    details,
    text: title,
    dates: `${startUTC}/${endUTC}`,
    ctz: "America/New_York",
    utm_source: "cc-webinar",
    utm_medium: "calendar",
    utm_campaign: "thank-you",
    utm_content: "webinar-calendar-invite",
  });
  const googleLink = `https://calendar.google.com/calendar/render?${googleParams}`;
  const outlookParams = new URLSearchParams({
    path: "/calendar/action/compose",
    rru: "addevent",
    startdt: startUTC,
    enddt: endUTC,
    body: details,
    subject: title,
    utm_source: "cc-webinar",
    utm_medium: "calendar",
    utm_campaign: "thank-you",
    utm_content: "webinar-calendar-invite",
  });
  const outlookLink = `https://outlook.live.com/calendar/0/deeplink/compose?${outlookParams}`;

  return (
    <div className="flex flex-col justify-center gap-y-2">
      <CalendarButton text="Add to Google Calendar" url={googleLink} />
      <CalendarButton url={outlookLink} text="Add to Outlook Calendar" />
    </div>
  );
};

export default CalendarEventButton;
