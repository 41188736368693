/* eslint-disable @next/next/no-html-link-for-pages */
import { AiOutlineMenu } from "react-icons/ai";
import {
  CMSCollection,
  CMSImageResource,
  CMSLink,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { ImageRender } from "../../utils/cms/renderableResources";
import { placeholderImageUrlWithText } from "stories/helpers";
import ToggleContent from "../cms/ToggleContent";
import ToggleContentLink from "../cms/ToggleContentLink";
import TrunkLink from "../TrunkLink";
import trackEvent from "../trackEvent";

const BrandNavigation1 = ({
  brandAbbreviation,
  cta,
  logo,
  pages,
}: InjectableComponentProps<BrandNavigationProps>) => {
  const trackLinkClick = (): void => {
    trackEvent(
      {
        action: "click",
        meta: {
          destination: "/",
          type: "link",
        },
      },
      "beacon"
    );
  };

  // temporary fix until order is added to pcr
  const orderedPages = [
    "My Story",
    "Testimonials",
    "Pricing",
    "FAQ",
    "Blog",
    "Joyful Brush Login",
    "E-Course Login",
    "Sign In",
  ];
  const sortedPages = [];

  const resources = pages?.resources ? [...pages.resources] : [];

  for (const orderedPage of orderedPages) {
    for (const page of resources) {
      if (page.title === orderedPage) {
        sortedPages.push(page);
      }
    }
  }

  for (const page of resources) {
    if (orderedPages.includes(page.title)) {
      continue;
    }
    sortedPages.push(page);
  }

  const dropDown = (
    <div className="w-full bg-white fixed left-0 md:rounded shadow z-50 border-opacity-50 border-0 border-b-4 border-solid border-brand-primary">
      <hr className="-ml-4 my-0 mt-2" />
      {sortedPages.map((page) => {
        return (
          <div key={`navOptions_${page.title}`} className="h-14 flex flex-col">
            <hr className="-ml-4 my-0" />
            <ToggleContentLink
              brandAbbreviation={brandAbbreviation}
              label={page.title}
              route={page.href}
            />
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <div className="hidden xl:flex xl:flex-row justify-around items-center p-2 bg-white">
        {/* We are using an anchor tag here because navigating to `/` causes an issue with Next/CloudFront */}
        <a href="/" className="w-48" onClick={trackLinkClick}>
          <ImageRender resource={logo} imgClassName="object-contain" />
        </a>
        <nav className="flex flex-row items-end">
          {sortedPages.map((page, index, { length }) => {
            if (index === length - 1) {
              return (
                <div key={index} className="flex space-x-4 py-2">
                  <TrunkLink
                    brandAbbreviation={brandAbbreviation}
                    href={page.href}
                  >
                    <span className="cursor-pointer hover:underline ml-4 text-brand-primary py-2">
                      {page.title}
                    </span>
                  </TrunkLink>
                </div>
              );
            } else {
              return (
                <div key={index} className="flex space-x-4 py-2">
                  <TrunkLink
                    brandAbbreviation={brandAbbreviation}
                    href={page.href}
                  >
                    <span className="cursor-pointer hover:underline ml-4 text-brand-primary">
                      {page.title}
                    </span>
                  </TrunkLink>
                  <p className="text-brand-primary">|</p>
                </div>
              );
            }
          })}
          <TrunkLink brandAbbreviation={brandAbbreviation} href={cta.href}>
            <button className="rounded bg-quaternary-darker text-bg-quaternary hover:bg-quaternary px-3 py-2 ml-8">
              {cta.title}
            </button>
          </TrunkLink>
        </nav>
      </div>
      <nav
        className="flex xl:hidden flex-row justify-between 
          z-50 w-full h-14 fixed top-0 left-0 side-nav-step 
          bg-white mobile-bottom-border-light p-2"
      >
        {/* We are using an anchor tag here because navigating to `/` causes an issue with Next/CloudFront */}
        <a href="/" className="w-48 flex items-start" onClick={trackLinkClick}>
          <ImageRender resource={logo} imgClassName="h-10 object-contain" />
        </a>
        <div className="flex flex-row">
          <TrunkLink brandAbbreviation={brandAbbreviation} href={cta.href}>
            <button className="rounded bg-quaternary-darker text-bg-quaternary hover:bg-quaternary h-full px-3">
              {cta.title}
            </button>
          </TrunkLink>
          <ToggleContent
            toggle={
              <AiOutlineMenu className="text-2xl p-2 h-12 w-12 -m-1 ml-2" />
            }
            content={dropDown}
          />
        </div>
      </nav>
      {/* spacer so that content on mobile doesn't get cut off */}
      <div className="h-14 xl:hidden" />
    </>
  );
};

type BrandNavigationProps = {
  logo: CMSImageResource;
  pages?: CMSCollection<CMSLink>;
  cta: CMSLink;
};

export const BrandNavigation1StorybookProps: CMSComponentExampleProps<BrandNavigationProps> =
  {
    logo: {
      type: "image_resource",
      value: placeholderImageUrlWithText(200, 200, "logo"),
      defaultTags: [{ type: "brand_logo" }],
    },
    pages: {
      type: "link",
      retrieveAll: true,
      defaultTags: [{ type: "brand_link" }],
      values: [
        {
          type: "link",
          title: "My Story",
          href: "my-story",
        },
        {
          type: "link",
          title: "Testimonials",
          href: "testimonials",
        },
        {
          type: "link",
          title: "Pricing",
          href: "pricing",
        },
        {
          type: "link",
          title: "FAQ",
          href: "faq",
        },
        {
          type: "link",
          title: "Blog",
          href: "blog",
        },
      ],
    },
    cta: {
      defaultTags: [{ type: "brand_link" }],
      type: "link",
      title: "Buy Now",
      href: "enroll",
    },
  };

export default BrandNavigation1;
