import { clientSideSupabase } from "../../utils/xmsSupabase";
import CMSButton from "./CMSButton";
import Cookies from "js-cookie";
import InlineStatusBanner from "./InlineStatusBanner";
import LeadBody from "../../utils/types/LeadBody";
import React, { ChangeEvent, FormEvent, useState } from "react";
import trackEvent from "../trackEvent";
import { definitions } from "../../types/supabase";
import Requires from "../../internal_components/Requires";
import { FaSpinner } from "react-icons/fa";
import ensureTrailingSlash from "app/admin/lib/cctmify/src/utils/ensureTrailingSlash";

type Props = {
  brandId: string;
  clientSideId: string;
  apiUrl: string;
};

// TODO: bring up to models dir for usage across client-side and server-side
const IntroCourseSignUp = ({ brandId, clientSideId, apiUrl }: Props) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");

  const handleEnrollment = async (e: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Invalid Email");
      return;
    }
    setLoading(true);

    const cookies = Cookies && Cookies.get();
    const userAgent = navigator.userAgent;

    const data = clientSideSupabase.getData<
      definitions["brand_configurations"] & { brands: definitions["brands"] }
    >(
      await clientSideSupabase.supabase
        .from("brand_configurations")
        .select("*, brands(*)")
        .eq("brand_id", brandId)
    );

    const introductoryCourseOfferId = data[0].intro_course_offer_id;
    if (!introductoryCourseOfferId) {
      throw new Error(`No introductory course offer found`);
    }

    const leadBody: LeadBody = {
      brandId,
      clientSideId,
      cookies,
      email: email.toLowerCase(),
      type: "intro_course",
      transactionUrl: window.location.href,
      userAgent: userAgent,
      offerIds: [introductoryCourseOfferId],
    };

    const resp = await fetch(`${apiUrl}/leads/createOrUpdateLead/`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(leadBody),
    });
    const apiResponse = await resp.json();

    trackEvent({
      action: "free_course_signup",
      meta: {
        offers: [{ offer_id: introductoryCourseOfferId }],
      },
      email: email.toLowerCase(),
      leadId: apiResponse?.lead?.id,
      clientSubmissionUniqueId: clientSideId,
    });

    if (resp.status >= 400) {
      if (apiResponse["status"]["did_you_mean"]) {
        setSuccess(false);
        setError(
          `Oops! That doesn't look like a valid email address. Did you mean ${apiResponse["status"]["did_you_mean"]}?`
        );
      } else {
        setSuccess(false);
        setError("Invalid Email");
      }
    } else {
      location.href =
        ensureTrailingSlash(data[0].brands?.learning_domain || "") +
        "enroll?email=" +
        encodeURIComponent(email);
    }
    setLoading(false);
  };

  return (
    <div className="pb-4">
      <Requires value={!success}>
        <form
          className="flex justify-center gap-4 lg:gap-8 pb-4 align-center flex-col lg:flex-row items-center m-auto"
          onSubmit={handleEnrollment}
        >
          {loading ? (
            <FaSpinner className="inline mr-3 text-4xl text-white animate-spin" />
          ) : (
            <>
              <input
                className="flex align-center border-2 border-gray-200 w-58 h-9 rounded-sm placeholder-gray-500 pl-2"
                placeholder="Email"
                data-testid="free-course-signup-email-input"
                value={email}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
              />
              <CMSButton
                buttonBgClass="bg-primary rounded-lg cursor-pointer"
                buttonClass="rounded-full py-2 px-6"
                buttonText="Sign up for free!"
                testId="free-course-signup-button"
                onClick={() => {}}
                tracking={{
                  expectedResult: "free_course_signup",
                  innerText: "Sign up for free!",
                  type: "button",
                }}
              />
            </>
          )}
        </form>
      </Requires>
      <Requires value={success}>
        <div className="flex px-4 pb-2 gap-4 flex-col">
          <p
            className="text-white font-bold m-auto text-sm text-center"
            data-testid="free-course-success-message"
          >
            Congratulations! You are enrolled in our free introductory course.
            Please click the button to create your account!
          </p>
          <CMSButton
            buttonBgClass="bg-primary rounded-lg cursor-pointer"
            buttonClass="rounded-full py-2 text-md text-center"
            buttonText="Get Started"
            testId="free-course-signup-redirect-button"
            href={redirectUrl}
            tracking={{
              destination: redirectUrl,
              type: "link",
            }}
          />
        </div>
      </Requires>

      <div>
        {error && <InlineStatusBanner status="error" message={error} />}
      </div>
    </div>
  );
};

export default IntroCourseSignUp;
