import Requires from "../../internal_components/Requires";
import { placeholderImageUrlWithText } from "stories/helpers";
import {
  getRawSchedule,
  getRawText,
  ImageRender,
  RichTextRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import {
  CMSImageResource,
  CMSSchedule,
  CMSText,
  HasOnClick,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { useClickHandler } from "../cms/PageContext";
import CircleWithTitleAndText from "../SubComponents/CircleWithTitleAndText";
import CountdownTimer from "../SubComponents/CountdownTimer";
import calculateSchedule, {
  ScheduleType,
} from "../../utils/schedule/calculateSchedule";
import OnlyOnClient from "../OnlyOnClient/OnlyOnClient";
import { useState } from "react";

const BrandHeaderRichTextAndImage2 = ({
  component,
  ctaButtonText,
  image,
  title,
  text,
  circleTitle,
  circleText,
  countdownTitle,
  countdownLabelOnceCompleted,
  countdownToRecurringDateTime,
}: InjectableComponentProps<BrandHeaderRichTextAndImage2Props>) => {
  const clickHandler = useClickHandler(component, "ctaButtonText");

  const [formState] = useState({
    timezone: "EST",
  });

  return (
    <div className="relative overflow-hidden">
      <div
        data-component="BrandHeaderRichTextAndImage2"
        className="flex flex-col sm:flex-row relative justify-between bg-primary-darker text-white"
      >
        <div className="flex flex-col gap-y-8 sm:w-2/3 xl:w-1/2 p-4 sm:p-12 ml-auto">
          <h1 className="font-bold text-4xl">
            <TextRender resource={title} />
          </h1>
          <style>
            {`.rich-text-element ul {
                list-style: disc;
                margin-left: 2.5rem;
            }`}
          </style>
          <RichTextRender resource={text} className="rich-text-element" />
          <Requires value={ctaButtonText}>
            <button
              className="px-6 py-2 rounded text-bg-tertiary bg-tertiary hover:brightness-90 text-xl font-semibold"
              onClick={clickHandler}
            >
              {getRawText(ctaButtonText)}
            </button>
          </Requires>
          <TextRender
            resource={countdownTitle}
            className="text-lg font-bold pl-1 -mb-6"
          />
          <OnlyOnClient>
            <CountdownTimer
              countdownLabelOnceCompleted={getRawText(
                countdownLabelOnceCompleted
              )}
              toDate={calculateSchedule(
                new Date(),
                getRawSchedule(countdownToRecurringDateTime),
                formState.timezone
              )}
            />
          </OnlyOnClient>
        </div>
        <div className="hidden sm:flex flex-col w-1/3">
          <div className="absolute h-full z-10 w-1/6 bg-gradient-to-r from-primary-darker to-transparent" />
          <ImageRender
            resource={image}
            className="absolute h-full w-1/2"
            imgClassName=" h-full object-cover"
          />
        </div>
      </div>
      <div className="relative z-10">
        <CircleWithTitleAndText title={circleTitle} text={circleText} />
      </div>
    </div>
  );
};

type BrandHeaderRichTextAndImage2Props = {
  image: CMSImageResource;
  title: CMSText;
  text: CMSText;
  ctaButtonText: HasOnClick<CMSText>;
  ctaDescriptiveText: CMSText;
  circleTitle: CMSText;
  circleText: CMSText;
  countdownTitle: CMSText;
  countdownLabelOnceCompleted: CMSText;
  countdownToRecurringDateTime: CMSSchedule;
};

export const BrandHeaderRichTextAndImage2StorybookProps: CMSComponentExampleProps<BrandHeaderRichTextAndImage2Props> =
  {
    image: {
      defaultTags: [{ type: "header_image" }],
      type: "image_resource",
      value: placeholderImageUrlWithText(400, 600, "image of content"),
    },
    title: {
      defaultTags: [{ type: "header_title" }],
      type: "text",
      value: "Watch my webinar today and you could win a free 88-key keyboard!",
    },
    text: {
      defaultTags: [{ type: "header_subtitle" }],
      type: "richtext",
      value: `I've taught taught tens of thousands of adults how to play non-classical piano through my unique method. 
        I'll tell you exactly how it could work for you, too in this free webinar.<br/><br/>
        Watch until the end, and you'll be entered to win a <b>free 88-key electronic keyboard</b>. One winner will be chosen every Friday!<br/><br/>
        What you will learn:<br/>
        - How traditional methods overcomplicate the learning process<br/>
        - Why any adult, no matter their background, can learn<br/>
        - How my "3 Secrets" can get you playing in days, not months`,
    },
    circleTitle: {
      type: "text",
      value: "Note",
    },
    circleText: {
      type: "text",
      value: "You don't need to be in front of a canvas to watch!",
    },
    ctaButtonText: {
      type: "text",
      value: "ENROLL IN FREE WEBINAR",
      metadata: {
        onClick: "consoleLog",
      },
    },
    countdownTitle: {
      type: "text",
      value: "Registration closes in:",
    },
    countdownLabelOnceCompleted: {
      defaultTags: [{ type: "countdown_label" }],
      type: "text",
      value: "Webinar is live!",
    },
    countdownToRecurringDateTime: {
      defaultTags: [{ type: "countdown_date" }],
      type: "schedule",
      value: {
        recurringSchedule: {
          weekly: {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [
              { start: "15:00", status: "countdown end" },
              { start: "15:01", status: "countdown start" },
            ],
            saturday: [],
            sunday: [],
          },
        },
        options: {
          totalDatesToDisplay: 1,
          inTimezone: "EST",
          mode: "countdown",
        },
      } as ScheduleType,
    },
    component: {
      required_data: {
        ctaButtonText: {
          metadata: {
            onClick: "consoleLog",
          },
        },
      },
    },
  };

export default BrandHeaderRichTextAndImage2;
