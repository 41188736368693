import { useState } from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import Requires from "../../internal_components/Requires";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSRichText,
  CMSSchedule,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  getRawSchedule,
  getRawText,
  RichTextRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import CountdownTimer from "../SubComponents/CountdownTimer";
import calculateSchedule, {
  ScheduleType,
} from "../../utils/schedule/calculateSchedule";
import CMSButton from "../SubComponents/CMSButton";
import useDirectToWebinarUrlParams from "../../utils/webinar/useDirectToWebinarUrlParams";
import OnlyOnClient from "../OnlyOnClient/OnlyOnClient";

const StickyFooter1 = ({
  text,
  countdownLabelOnceCompleted,
  countdownToRecurringDateTime,
  mobileTitle,
  ctaButtonText,
  brand,
}: InjectableComponentProps<StickyFooter1Props>) => {
  const [collapsed, setCollapsed] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const { directToWebinarUrl } = useDirectToWebinarUrlParams({
    setStartTime,
    brandAbbreviation: brand.abbreviation,
  });
  const [formState] = useState({
    timezone: "EST",
  });

  function Collapse() {
    return (
      <button
        onClick={() => setCollapsed(!collapsed)}
        className="absolute -top-9 right-0 p-2 bg-secondary-darker hover:bg-secondary text-xl"
      >
        {collapsed ? <AiFillCaretUp /> : <AiFillCaretDown />}
      </button>
    );
  }

  if (!text || !ctaButtonText) {
    return null;
  }

  return (
    <div
      className={`flex w-full text-sm items-center bg-secondary fixed z-50 bottom-0 ${
        collapsed ? "p-1" : "p-4"
      }`}
    >
      <Collapse />
      <Requires value={!collapsed}>
        <div className="flex flex-col sm:flex-row gap-5 justify-between sm:text-left w-full py-4 2xl:mx-[10vw] sm:px-12">
          <div className="sm:flex hidden">
            <Requires value={startTime || countdownToRecurringDateTime}>
              <RichTextRender resource={text} className="my-auto text-3xl" />
            </Requires>
            {/* Once we have a page to view dates we will link it to the button */}
            {/* <Requires value={underlinedLinkText}>
              <button
                className="underline whitespace-nowrap hover:bg-secondary-darker"
                onClick={clickHandler}
              >
                <TextRender resource={underlinedLinkText} />
              </button>
            </Requires> */}
          </div>
          <div className="flex flex-col items-center sm:flex-row gap-5 sm:gap-12 w-full sm:w-fit">
            <TextRender
              resource={mobileTitle}
              className="flex text-2xl sm:hidden font-semibold "
            />
            <OnlyOnClient>
              <Requires value={startTime || countdownToRecurringDateTime}>
                <CountdownTimer
                  countdownLabelOnceCompleted={getRawText(
                    countdownLabelOnceCompleted
                  )}
                  toDate={
                    startTime
                      ? [startTime]
                      : calculateSchedule(
                          new Date(),
                          getRawSchedule(countdownToRecurringDateTime),
                          formState.timezone
                        )
                  }
                />
              </Requires>
            </OnlyOnClient>
            <div className="flex flex-col w-full">
              <CMSButton
                buttonText={getRawText(ctaButtonText)}
                buttonClass="px-8 py-2 w-full rounded bg-quaternary hover:brightness-90 text-white text-xl font-semibold"
                href={directToWebinarUrl}
                tracking={{
                  destination: directToWebinarUrl,
                  resourceId: ctaButtonText.id,
                  type: "link",
                }}
              />
              {/* Once we have a page to view dates we will link it to the button */}
              {/* <Requires value={underlinedLinkText}>
                <button
                  className="block sm:hidden px-6 py-3 w-full underline whitespace-nowrap hover:bg-secondary-darker -mb-4"
                  onClick={clickHandler}
                >
                  <TextRender resource={underlinedLinkText} />
                </button>
              </Requires> */}
            </div>
          </div>
        </div>
      </Requires>
    </div>
  );
};

type StickyFooter1Props = {
  text: CMSRichText;
  countdownLabelOnceCompleted: CMSText;
  countdownToRecurringDateTime: CMSSchedule;
  mobileTitle: CMSText;
  ctaButtonText: CMSText;
  underlinedLinkText: CMSText;
};

export const StickyFooter1StorybookProps: CMSComponentExampleProps<StickyFooter1Props> =
  {
    text: {
      type: "richtext",
      metadata: {
        value: `Next Webinar:<br/><b>Tuesday, February 28 2023, 1:30 PM</b><br/>Eastern Time (US and Canada)`,
      },
    },
    countdownLabelOnceCompleted: {
      defaultTags: [{ type: "countdown_label" }],
      type: "text",
      value: "Webinar is live!",
    },
    countdownToRecurringDateTime: {
      defaultTags: [{ type: "countdown_date" }],
      type: "schedule",
      value: {
        recurringSchedule: {
          weekly: {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [
              { start: "15:00", status: "countdown end" },
              { start: "15:01", status: "countdown start" },
            ],
            saturday: [],
            sunday: [],
          },
        },
        options: {
          totalDatesToDisplay: 1,
          inTimezone: "EST",
          mode: "countdown",
        },
      } as ScheduleType,
    },
    mobileTitle: {
      type: "text",
      value: "Next Webinar In:",
    },
    ctaButtonText: {
      type: "text",
      value: "Register",
    },
    underlinedLinkText: {
      type: "text",
      value: "View More Dates",
    },
    component: {
      required_data: {
        ctaButtonText: {
          metadata: {
            onClick: "consoleLog",
          },
        },
      },
    },
  };

export default StickyFooter1;
