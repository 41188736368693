import { AiOutlineDoubleRight } from "react-icons/ai";
import { Parallax, Background } from "react-parallax";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { useState } from "react";
import RibbonBanner from "../SubComponents/RibbonBanner";
import {
  getRawSchedule,
  getRawText,
  ImageRender,
  RichTextRender,
  TextRender,
} from "../../utils/cms/renderableResources";
import {
  CMSImageResource,
  CMSRichText,
  CMSSchedule,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import CountdownTimer from "../SubComponents/CountdownTimer";
import calculateSchedule, {
  ScheduleType,
} from "../../utils/schedule/calculateSchedule";
import { placeholderImageUrlWithText } from "stories/helpers";

const BlackFridayHeader1 = ({
  backgroundImage,
  bundleName,
  brand,
  teacherName,
  slogan,
  logo,
  countdownLabelOnceCompleted,
  countdownToRecurringDateTime,
}: InjectableComponentProps<BlackFridayHeader1Props>) => {
  const hrStyle = "h-0.5 mx-auto w-5/6 bg-secondary";

  function scrollDown(e: any) {
    e.preventDefault();
    scrollBy({
      top: window.innerHeight / 2,
      behavior: "smooth",
    });
  }

  const [formState] = useState({
    timezone: "EST",
  });

  return (
    <Parallax blur={0} strength={400}>
      <Background className="custom-bg object-cover h-full w-full">
        <ImageRender
          resource={backgroundImage}
          className="bg-cover h-full w-full z-10"
        />
      </Background>
      <div className="bg-gray-100 flex flex-col p-4 m-8">
        <RibbonBanner
          title="Black Friday"
          subtitle={brand.name}
          logo={
            <ImageRender
              resource={logo}
              className="absolute bottom-4 right-4 z-30 bg-primary rounded-full w-14 h-14 -m-1"
            />
          }
        />
        <div className="flex flex-col gap-y-2 p-4 text-center w-full md:w-2/3 xl:w-1/2 mx-auto">
          <hr className={hrStyle} />
          <TextRender
            className="text-3xl lg:text-4xl font-bold text-primary uppercase pt-2"
            resource={bundleName}
          />
          <h4 className="font-thin bg-white rounded-full w-2/3 mx-auto lg:text-lg py-1">
            With <TextRender resource={teacherName} />
          </h4>
          <RichTextRender
            className="font-light text-lg lg:text-2xl py-4"
            resource={slogan}
          />
          <p className="lg:text-lg font-bold italic">You have until:</p>
          <hr className={hrStyle} />
          <CountdownTimer
            countdownLabelOnceCompleted={getRawText(
              countdownLabelOnceCompleted
            )}
            toDate={calculateSchedule(
              new Date(),
              getRawSchedule(countdownToRecurringDateTime),
              formState.timezone
            )}
          />
          <hr className={hrStyle} />
        </div>
      </div>
      <div className="cursor-pointer" onClick={scrollDown}>
        <h4 className="lg:text-lg font-light text-center uppercase text-white">
          Scroll for details
        </h4>
        <AiOutlineDoubleRight
          className="text-white mx-auto h-12 w-12 mb-10"
          style={{ transform: "rotate(90deg)" }}
        />
      </div>
    </Parallax>
  );
};

type BlackFridayHeader1Props = {
  backgroundImage: CMSImageResource;
  bundleName: CMSText;
  teacherName: CMSText;
  slogan: CMSRichText;
  logo: CMSImageResource;
  countdownLabelOnceCompleted: CMSText;
  countdownToRecurringDateTime: CMSSchedule;
};

export const BlackFridayHeader1StorybookProps: CMSComponentExampleProps<BlackFridayHeader1Props> =
  {
    brand: {
      name: "Piano in a Flash",
    },
    backgroundImage: {
      type: "image_resource",
      value: placeholderImageUrlWithText(1900, 1200, "background image"),
    },
    bundleName: {
      type: "text",
      value: "The black friday bundle",
    },
    teacherName: {
      type: "text",
      value: "Scott Houston",
    },
    slogan: {
      type: "richtext",
      value: "You may have locks, but we've got keys!",
    },
    countdownLabelOnceCompleted: {
      defaultTags: [{ type: "countdown_label" }],
      type: "text",
      value: "Webinar is live!",
    },
    countdownToRecurringDateTime: {
      defaultTags: [{ type: "countdown_date" }],
      type: "schedule",
      value: {
        recurringSchedule: {
          weekly: {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [
              { start: "15:00", status: "countdown end" },
              { start: "15:01", status: "countdown start" },
            ],
            saturday: [],
            sunday: [],
          },
        },
        options: {
          totalDatesToDisplay: 1,
          inTimezone: "EST",
          mode: "countdown",
        },
      } as ScheduleType,
    },
    logo: {
      type: "image_resource",
      value: placeholderImageUrlWithText(100, 100, "logo"),
    },
  };

export default BlackFridayHeader1;
