import { FormEvent, useState } from "react";
import { definitions } from "../../../types";
import ChatMessage from "./ChatMessage";
import { useRealtimeMessages } from "./useRealtimeMessages";
import { BsSend } from "react-icons/bs";
import Requires from "../../../internal_components/Requires";
import { WebinarInteraction } from "@combined-curiosity/collector-js";
import useChatNewMessagesAlert from "./useChatNewMessagesAlert";

type WebinarChatProps = {
  leadId?: string;
  webinarRegistrationId?: string;
  webinarInstanceId: string;
  webinarId: string;
  userCurrentTime?: number;
  messagesToFetch: Array<definitions["messages"]["id"]>;
  allowSend?: boolean;
  viewType: WebinarInteraction["viewType"];
  children?: React.ReactNode;
};

const WebinarChat = ({
  leadId,
  webinarInstanceId,
  webinarId,
  userCurrentTime,
  webinarRegistrationId,
  messagesToFetch,
  allowSend = true,
  viewType,
  children,
}: WebinarChatProps) => {
  const [currentMessage, setCurrentMessage] = useState<string>("");

  const { messages, postMessage, toggleReaction } = useRealtimeMessages({
    roomType: "webinar_instance",
    roomId: webinarInstanceId,
    preRecordedMessageIdsToFetch: messagesToFetch,
    webinarInstanceId,
  });

  // sort the messages so that all messages appear in the
  // order intended according to their video_offset_seconds
  const sortedMessages = messages.sort((a, b) => {
    if (a.video_offset_seconds < b.video_offset_seconds) {
      return -1;
    }
    if (a.video_offset_seconds > b.video_offset_seconds) {
      return 1;
    }
    return 0;
  });

  const messagesUserShouldSee = sortedMessages.filter((message) => {
    if (message.pre_recorded) {
      return userCurrentTime && message.video_offset_seconds <= userCurrentTime;
    }

    if (message.visibility === "public") {
      return true;
    }

    // user sent the message
    return message.webinar_registration_id === webinarRegistrationId;
  });

  const sendMessage = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const message = currentMessage.trim();

    if (message && webinarInstanceId) {
      postMessage(
        message,
        {
          lead_id: leadId,
          video_offset_seconds: userCurrentTime,
          webinar_registration_id: webinarRegistrationId,
        },
        webinarInstanceId,
        viewType
      );
      setCurrentMessage("");
    }
  };

  const { atBottomOfMessages, hasUnseenMessages, scrollToBottomOfMessages } =
    useChatNewMessagesAlert({
      messageCount: messagesUserShouldSee.length,
      messages: messagesUserShouldSee,
      options: {
        scrollableContainerSelector: '[data-name="messages-container"]',
        individualMessageSelector: "[data-component='ChatMessage']",
      },
    });

  return (
    <div className="flex flex-col flex-auto h-[90vh]">
      <div className="flex flex-col flex-1 mb-4 text-sm lg:text-md">
        <h1>Messages</h1>
        <h2 className="text-gray-500 mr-28 lg:mr-0">
          {allowSend
            ? "Have a question? Just ask!"
            : "These are copies of the messages from the original viewing of this webinar"}
        </h2>
      </div>

      {children}

      <div
        className="h-full overflow-auto relative gap-3 scroll-smooth"
        data-name="messages-container"
      >
        {messagesUserShouldSee.map((message) => (
          <ChatMessage
            viewType={viewType}
            allowReaction={allowSend}
            toggleReaction={toggleReaction}
            key={message.id}
            message={message}
            leadId={leadId}
            webinarInstanceId={webinarInstanceId}
            webinarId={webinarId}
          />
        ))}
      </div>

      <Requires value={allowSend}>
        <div className="fixed bottom-0 bg-white flex flex-col flex-1 w-full">
          <Requires value={!atBottomOfMessages()}>
            <Requires value={hasUnseenMessages}>
              <div className="absolute -top-10 w-full flex justify-center">
                <button
                  className="text-sm text-yellow-800 bg-yellow-300 hover:bg-yellow-400 cursor-pointer px-4 mx-2 rounded-full flex items-center"
                  onClick={scrollToBottomOfMessages}
                >
                  Jump to latest
                  <span className="ml-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </Requires>
          </Requires>

          <hr className="mb-2" />
          <form
            onSubmit={sendMessage}
            className="flex flex-row w-full gap-2 bg-white"
          >
            <input
              minLength={1}
              maxLength={500}
              type="text"
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              className={`
                w-full rounded py-1.5 pl-2 px-2 text-gray-900 border border-1 border-inset border-gray-300
                placeholder:text-gray-400 focus:border focus:border-2 focus:border-inset focus:border-indigo-600
              `}
              placeholder="Message"
            />
            <button className="bg-primary py-1 px-3 rounded">
              <BsSend color="white" />
            </button>
          </form>
          <span className="text-gray-400 text-xs pl-1 mt-1 mb-2 whitespace-nowrap">
            {" "}
            Characters Remaining: {500 - currentMessage.length}
          </span>
        </div>
      </Requires>
    </div>
  );
};

export default WebinarChat;
