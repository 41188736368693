import { RichTextRender } from "../../utils/cms/renderableResources";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import { CMSRichText, InjectableComponentProps } from "../cms/types/cmsTypes";

const Banner3 = ({ text }: InjectableComponentProps<Banner3Props>) => {
  return (
    <div className="flex items-center justify-around bg-primary p-3">
      <RichTextRender resource={text} className="text-bg-primary" />
    </div>
  );
};

type Banner3Props = {
  text: CMSRichText;
};

export const Banner3StorybookProps: CMSComponentExampleProps<Banner3Props> = {
  text: {
    defaultTags: [{ type: "banner_text" }],
    type: "richtext",
    metadata: {
      value: `Remember, the webinar is totally <b>free</b> to watch! Plus, if you watch until the end, you'll be entered into a keyboard drawing!`,
    },
  },
};

export default Banner3;
